<template>
  <div :class="client.indexOf('ios') === -1 && client.indexOf('Android') === -1? 'copyright-nomargin':'copyright'">
    <div class="copyright_box">
      <div class="options">
        <div
          class="option_list"
          @click="$router.push('/gameList')"
        >
          <span>JOGOS INTERNOS</span>
        </div>
        <div class="option_list">
          <span @click="openNext === 1 ? openNext = 0 : openNext = 1">SLOTS</span>
        </div>
        <div
          v-show="openNext === 1"
          class="next-level"
        >
          <div class="bg">
            <span @click="$router.push('/gameList/slots')">Pragmatic Play</span>
            <span @click="$router.push('/gameList/pgGame')">Pocket Games</span>
          </div>
        </div>
        <div
          class="option_list"
          @click="$router.replace('/gameList/ppl')"
        >
          <span>LIVE CASINO</span>
        </div>
        <div class="option_list">
          <span @click="openNext === 2 ? openNext = 0 : openNext = 2">ABOUT REIS JOGO</span>
        </div>
        <div
          v-show="openNext === 2"
          class="next-level"
        >
          <div class="bg">
            <span @click="$router.push('/privacyPolicy')">Política de Privacidade</span>
            <span @click="$router.push('/termsConditions')">Termos e Condições</span>
          </div>
        </div>
        <div class="option_list">
          <span @click="openNext === 3 ? openNext = 0 : openNext = 3">VIP CENTER</span>
        </div>
        <div
          v-show="openNext === 3"
          class="next-level"
        >
          <div class="bg">
            <!--            <span @click="$router.push('/cashback')">CashBack</span>-->
            <span @click="$router.push('/vip')">My VIP</span>
          </div>
        </div>
        <div
          class="option_list"
          @click="$router.push('/bonus')"
        >
          <span>BONUS</span>
        </div>
        <div
          class="option_list"
          @click="$router.push('/kyc')"
        >
          <span>KYC POLICY</span>
        </div>
        <div
          class="option_list"
          @click="isShowFAQ = true"
        >
          <span>FAQ</span>
        </div>
      </div>
      <div class="details">
        <img
          src="../../public/image/home/pic_1.png"
          alt=""
          class="pic1"
        >
        <img
          src="../../public/image/home/pic_2.png"
          alt=""
          class="pic2"
        >
        <img
          src="../../public/image/home/pic_3.png"
          alt=""
          class="pic3"
        >
      </div>
      <h6 style="font-size: 26px;">LICENÇA</h6>
      <p class="license_txt">
        rico13.com é operada pela PopBet LTD, número de registro da empresa 10127,
        com endereço registrado em Zuikertuintjeweg Z/N (Zuikertuin Tower) Curaçao e é
        licenciada e autorizada pelo governo de Curaçao. RICO13 opera sob a Master
        License of Gaming Services Provider, N.V. Número da Licença: GLH-OCCHKTW0701041288
        O ganho final não é garantido.
      </p>
      <div class="details_txt">
        Este site oferece uma experiência de jogo com riscos.<br />
        Para ser um usuário do nosso site,você deve ter mais de 18 anos.<br />
        Não nos responsabilizamos pela violação das leis locais relacionadas a jogos.<br />
        Jogue de forma responsável e divirta-se no Reisjogo.
      </div>
      <div class="copyright_txt">Copyright © 2019-2023 Reisjogo. All rights reserved.</div>
    </div>

    <div
      class="rechargePopMask"
      v-if="isShowFAQ"
    >
      <FAQ
        :showState="isShowFAQ"
        @closeFAQ="() => isShowFAQ = false"
      />
    </div>
  </div>
</template>
<script>

import FAQ from "@/components/FAQ.vue";
import { mapState } from "vuex";
export default {
  components: { FAQ },
  data() {
    return {
      openNext: 0,
      isShowFAQ: false
    }
  },
    computed:{
    ...mapState(['client'])
  },
    created(){
    
  },
}
</script>
<style lang="less" scoped>
.copyright {
  background-color: #12212e;
  padding: 35px 0 0 0;
  margin-top: 70px;
  margin-bottom: 116px;
}
.copyright-nomargin {
  background-color: #12212e;
  padding: 35px 0 0 0;
  margin-bottom: 116px;
}

.copyright_box {
  width: 90%;
  margin: 0 auto;
  color: #fff;

  .options {
    border-bottom: 2px solid #a1c5f3;
  }

  .option_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    color: #a1c5f3;
    font-weight: bold;

    img {
      width: 48px;
      height: 25px;
    }

    span {
      font-size: 20px;
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 40px;

    img.pic1 {
      width: 63px;
      height: 63px;
    }

    img.pic2 {
      width: 103px;
      height: 47px;
    }

    img.pic3 {
      width: 222px;
      height: 73px;
    }
  }

  .details_txt {
    font-size: 26px;
    color: #97b9e5;
    line-height: 40px;
    border-bottom: 2px solid #a1c5f3;
    padding-bottom: 30px;
    padding-top: 20px;
  }

  .license_txt {
    font-size: 26px;
    color: #97b9e5;
    line-height: 40px;
    border-bottom: 2px solid #a1c5f3;
    padding-bottom: 30px;
    text-align: justify;
    padding-top: 20px;
  }

  .copyright_txt {
    font-size: 24px;
    font-weight: 400;
    color: #fefefe;
    padding: 40px 0 40px 0;
    text-indent: 5px;
  }

  .next-level {
    display: flex;
    flex-direction: column;

    .bg {
      width: 100%;
      padding: 20px 30px;
      box-sizing: border-box;
      background: rgb(33, 54, 72);
      border-radius: 20px;
    }

    span {
      color: #a1c5f3;
      padding: 20px 0;
      font-size: 28px;
      display: block;
    }
  }
}

.rechargePopMask {
  width: 100%;
  height: calc(100vh - 60px);
  background: rgba(2, 2, 2, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999 !important;
  color: #fff;
  margin-top: 16%;
}
</style>