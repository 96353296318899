var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "app", staticClass: "app-box", attrs: { id: "app" } },
    [
      _c("HeadBar"),
      _c(
        "div",
        { staticClass: "fixed-height" },
        [
          _c(
            "keep-alive",
            { attrs: { include: ["home", "vip"] } },
            [
              _c("router-view", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLoading,
                    expression: "!isLoading",
                  },
                ],
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "loading",
            },
            [_vm._m(0)]
          ),
          _c("Copyright"),
        ],
        1
      ),
      _c("TabBar"),
      _c("Toast"),
      _c("SlotsTiger"),
      _c("WheelReward"),
      _c("RedBag"),
      _c("GamePrompt"),
      _c("NotAllowGamingTips"),
      _c("SignReward"),
      _c("PersonalEnter"),
      _c("TaskPopUp"),
      _c("CheerPopUp"),
      _c("BetTaskPopUp"),
      _c("GameRechargeActivity"),
      _vm.mask
        ? _c(
            "div",
            {
              staticClass: "innerMask login-mask no-padding",
              on: {
                touchmove: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [_vm.maskContent === "login" ? _c("LoginPages") : _vm._e()],
            1
          )
        : _vm._e(),
      _c(
        "van-popup",
        {
          staticClass: "menu-style",
          attrs: { position: "left", "z-index": "4" },
          model: {
            value: _vm.menuShow,
            callback: function ($$v) {
              _vm.menuShow = $$v
            },
            expression: "menuShow",
          },
        },
        [_c("Menu")],
        1
      ),
      _c("SiteMessage"),
      _c("payforPage"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sk-chase" }, [
      _c("div", { staticClass: "sk-chase-dot" }),
      _c("div", { staticClass: "sk-chase-dot" }),
      _c("div", { staticClass: "sk-chase-dot" }),
      _c("div", { staticClass: "sk-chase-dot" }),
      _c("div", { staticClass: "sk-chase-dot" }),
      _c("div", { staticClass: "sk-chase-dot" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }