var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "game-card" }, [
    _vm.json.id
      ? _c("div", [
          _vm.isHot
            ? _c("img", {
                staticClass: "game-hot-tag",
                attrs: {
                  src: require("../../public/image/common/hot-tag.png"),
                  alt: "",
                },
              })
            : _vm._e(),
          _c("img", {
            class:
              _vm.isReadyPlay || _vm.requestGamePending ? "image-filter" : "",
            attrs: { src: _vm.SplitGamePosterUrl(_vm.json), alt: "" },
            on: { click: _vm.readyPlay },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.json.active_status === 1,
                  expression: "json.active_status === 1",
                },
              ],
              staticClass: "game-maintenance",
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../public/image/common/game-maintenance.png"),
                  alt: "",
                },
              }),
              _c("span", [_vm._v("Em manutenção")]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.requestGamePending,
                  expression: "requestGamePending",
                },
              ],
              staticClass: "game-play-loading",
            },
            [_c("van-loading")],
            1
          ),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isReadyPlay,
                expression: "isReadyPlay",
              },
            ],
            staticClass: "play-btn",
            attrs: {
              src: require("../../public/image/common/playBtn.png"),
              alt: "",
            },
            on: { click: _vm.doPlay },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isReadyPlay,
                  expression: "isReadyPlay",
                },
              ],
              staticClass: "top_name",
            },
            [_vm._v(_vm._s(_vm.json.name))]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isReadyPlay,
                  expression: "isReadyPlay",
                },
              ],
              staticClass: "bottom_types",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.json.channel === 1
                      ? "PragmaticPlay"
                      : _vm.json.channel === 2
                      ? "Pocket Games"
                      : ""
                  ) +
                  " "
              ),
            ]
          ),
        ])
      : _vm._e(),
    !_vm.json.id
      ? _c("img", {
          attrs: {
            src: require("../../public/image/home/viewAllGame.png"),
            alt: "",
          },
          on: {
            click: function ($event) {
              return _vm.$router.push("/gameShop/" + _vm.json.channel)
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }