var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      staticClass: "personal-context",
      attrs: { position: "right", "z-index": "3" },
      model: {
        value: _vm.personalShow,
        callback: function ($$v) {
          _vm.personalShow = $$v
        },
        expression: "personalShow",
      },
    },
    [
      _c(
        "div",
        { staticClass: "personal_box" },
        [
          _c("div", { staticClass: "dataCards" }, [
            _c(
              "div",
              {
                staticClass: "top",
                style: { borderBottom: _vm.chooseAvatars ? "" : "" },
              },
              [
                _c("div", { staticClass: "avatarBox" }, [
                  _c(
                    "div",
                    {
                      staticClass: "avatar",
                      on: {
                        click: function ($event) {
                          _vm.chooseAvatars = !_vm.chooseAvatars
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.IMAGE_URL +
                            "header/" +
                            _vm.userData.avatar +
                            ".jpg",
                          alt: "",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "avatar-edit-icon" },
                        [
                          _c("van-icon", {
                            attrs: { name: "edit", size: "16" },
                          }),
                        ],
                        1
                      ),
                      _vm.avatarLoading
                        ? _c(
                            "div",
                            { staticClass: "change-avatar" },
                            [_c("van-loading")],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "InfoBox" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.getUserData ? _vm.getUserData.name : "")),
                  ]),
                  _c("div", { staticClass: "userId" }, [
                    _c("span", [_vm._v("User ID")]),
                    _c("div", { staticClass: "rightPart" }, [
                      _c("input", {
                        ref: "uid",
                        staticClass: "userIdText",
                        attrs: { type: "text", readOnly: "" },
                        domProps: {
                          value: _vm.getUserData ? _vm.getUserData.uid : "",
                        },
                      }),
                      _c("img", {
                        staticClass: "copy_icon",
                        attrs: {
                          src: require("../../../public/image/personal/file.png"),
                          alt: "",
                        },
                        on: { click: _vm.copyUid },
                      }),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm.chooseAvatars
              ? _c(
                  "div",
                  { staticClass: "avatar-list" },
                  _vm._l(10, function (item) {
                    return _c("img", {
                      attrs: {
                        src: _vm.IMAGE_URL + "header/" + (item - 1) + ".jpg",
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeAvatar(item - 1)
                        },
                      },
                    })
                  }),
                  0
                )
              : _vm._e(),
            _c("div", { staticClass: "moneyOut" }, [
              _c("div", { staticClass: "VipCards" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "right" }, [
                    _c("div", { staticClass: "retairAble" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("Valor sacavel"),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "num",
                          staticStyle: { color: "#54B949" },
                        },
                        [_vm._v("R$ " + _vm._s(_vm.userData.withdraw))]
                      ),
                    ]),
                    _vm.drawProgress
                      ? _c("div", { staticClass: "vip" }, [
                          _c("div", { staticClass: "txt" }, [
                            _c("span", { staticClass: "showGrade" }, [
                              _vm._v("Precisa Aposta"),
                            ]),
                            _c("span", { staticClass: "showGrade" }, [
                              _vm._v(
                                _vm._s(_vm.drawProgress.valid_bets) +
                                  " / " +
                                  _vm._s(_vm.drawProgress.need_bets)
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "progress_bar_block" }, [
                            _c("div", { staticClass: "progress_bar" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "progress_back",
                                  style: {
                                    width:
                                      (_vm.drawProgress.valid_bets /
                                        _vm.drawProgress.need_bets) *
                                        100 +
                                      "%",
                                  },
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      " R$" +
                                        _vm._s(_vm.drawProgress.valid_bets) +
                                        " / R$" +
                                        _vm._s(_vm.drawProgress.need_bets) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _c("div", { staticClass: "vip" }, [
                          _c("div", { staticClass: "txt" }, [
                            _c("span", { staticClass: "showGrade" }, [
                              _vm._v("Precisa Aposta"),
                            ]),
                            _c("span", { staticClass: "showGrade" }, [
                              _vm._v("0 / 0"),
                            ]),
                          ]),
                          _c("div", { staticClass: "progress_bar_block" }, [
                            _c("div", { staticClass: "progress_bar" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "progress_back",
                                  staticStyle: { width: "100%" },
                                },
                                [_c("p", [_vm._v(" 0 / 0 ")])]
                              ),
                            ]),
                          ]),
                        ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "Zerar" }, [
                _c("div", [
                  _c("span", [_vm._v("Zerar")]),
                  _c("img", {
                    attrs: {
                      src: require("../../../public/image/personal/question.png"),
                      alt: "",
                    },
                    on: { click: _vm.toSolve },
                  }),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showSolvePop,
                      expression: "showSolvePop",
                    },
                  ],
                  staticClass: "solvePop",
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(" Descrição de Zerar "),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      " Quando o seu saldo estiver abaixo de R$0.2, a sua barra de apostas ou 'Rollover' será reiniciada.Isso significa que você não precisará fazer apostas em quantias elevadas para efetuar um saque. "
                    ),
                  ]),
                ]
              ),
            ]),
            !_vm.channel.banned_user
              ? _c("div", { staticClass: "btn" }, [
                  _c("button", { on: { click: _vm.toWallet } }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../public/image/personal/bag.png"),
                        alt: "",
                      },
                    }),
                    _c("span", [_vm._v("Detalhes")]),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "notice", on: { click: _vm.openShowMessage } },
            [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: require("../../../public/image/personal/icon1.png"),
                    alt: "",
                  },
                }),
                _c("span", [_vm._v("Notificações")]),
              ]),
              _c("span", { staticClass: "nums" }, [
                _vm._v(_vm._s(_vm.messageNumber)),
              ]),
            ]
          ),
          _c("div", { staticClass: "btnList" }, [
            _c(
              "div",
              { staticClass: "oddBtn", on: { click: _vm.toPersonal } },
              [
                _c("div", [
                  _c("img", {
                    attrs: {
                      src: require("../../../public/image/personal/icon2.png"),
                      alt: "",
                    },
                  }),
                  _c("span", [_vm._v("Centro Pessoal")]),
                ]),
                _c("img", {
                  staticClass: "listArrow",
                  attrs: {
                    src: require("../../../public/image/personal/listArrow.png"),
                    alt: "",
                  },
                }),
              ]
            ),
            _c("div", { staticClass: "oddBtn", on: { click: _vm.to_vip } }, [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: require("../../../public/image/personal/icon3.png"),
                    alt: "",
                  },
                }),
                _c("span", [_vm._v("Meu nível vip")]),
              ]),
              _c("img", {
                staticClass: "listArrow",
                attrs: {
                  src: require("../../../public/image/personal/listArrow.png"),
                  alt: "",
                },
              }),
            ]),
            _c("div", { staticClass: "oddBtn", on: { click: _vm.to_bonus } }, [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: require("../../../public/image/personal/icon4.png"),
                    alt: "",
                  },
                }),
                _c("span", [_vm._v("My Bonus")]),
              ]),
              _c("img", {
                staticClass: "listArrow",
                attrs: {
                  src: require("../../../public/image/personal/listArrow.png"),
                  alt: "",
                },
              }),
            ]),
            _c("div", { staticClass: "oddBtn", on: { click: _vm.to_share } }, [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: require("../../../public/image/personal/icon5.png"),
                    alt: "",
                  },
                }),
                _vm._v("Convite"),
              ]),
              _c("img", {
                staticClass: "listArrow",
                attrs: {
                  src: require("../../../public/image/personal/listArrow.png"),
                  alt: "",
                },
              }),
            ]),
            _c("div", { staticClass: "oddBtn" }, [
              _c("div", { on: { click: _vm.openService } }, [
                _c("img", {
                  attrs: {
                    src: require("../../../public/image/personal/icon6.png"),
                    alt: "",
                  },
                }),
                _c("span", [_vm._v("Contate-nos")]),
              ]),
              _c("img", {
                staticClass: "listArrow",
                attrs: {
                  src: require("../../../public/image/personal/listArrow.png"),
                  alt: "",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "log_out", on: { click: _vm.log_out } }, [
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("../../../public/image/personal/logOut.png"),
                  alt: "",
                },
              }),
              _c("span", [_vm._v("Sair")]),
            ]),
          ]),
          _c("FAQ", {
            attrs: { showState: _vm.isShowFAQ },
            on: { closeFAQ: () => (_vm.isShowFAQ = false) },
          }),
          _c(
            "Dialog",
            {
              attrs: { show: _vm.showCopyDialog, confirmText: `confirm` },
              on: {
                confirm: function ($event) {
                  _vm.showCopyDialog = false
                },
              },
            },
            [_c("div", [_vm._v("Copiado com sucesso")])]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }