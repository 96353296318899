<template>
  <div class="menu-container">
    <div class="menu-context">
      <div class="adsArray">
        <div class="ads1" @click="$router.push('/bonus')">
          <span>CENTRO DE BÔNUS</span>
        </div>
        <div class="ads2" @click="$store.commit('setWheelShow', true)">
          <span>REISJOGO WHEEL</span>
          <img src="../../public/image/menu/ads2_wheel.png" alt="" class="ads2_wheel">
        </div>
        <div class="ads3" @click="$router.push('/vip')">
          <span>CASH BACK</span>
        </div>
        <div class="ads4" @click="$router.push('/share')">
          <span>CONVITE</span>
        </div>
        <div class="ads5" @click="$router.push('/tournament')">
          <span>TORNEIO</span>
        </div>
      </div>
      <div class="deliver"></div>
      <div class="otherGame">
        <div v-for="(val, k) in topTitle">
          <div class="topItems" v-if="!val.hasChildren" @click="clickItem(0)">{{ val.name }}</div>
          <div v-else class="listContent">
            <div class="topItems" @click="clickItem(1)">{{ val.name }}</div>
            <van-collapse v-model="activeNames">
              <van-collapse-item v-for="(item, index) in dataList" :key="index" :name="index">
                <template #title>
                  <div class="items"><img :src="item.titleImg" alt="" class="titleImg"><span>{{ item.title }}</span>
                  </div>
                </template>
                <ul class="menuListUl">
                  <li v-for="(c_item, c_index) in item.subList" :key="c_index">
                    <div class="items" @click="toPage(c_item.name)" style="margin-top: 10px;">
                      <img :src="c_item.icon" alt="" class="titleIcon"><span>{{ c_item.name }}</span>
                    </div>
                  </li>
                </ul>
              </van-collapse-item>
            </van-collapse>
          </div>

        </div>

      </div>
      <div class="menu-item" v-for="item in menuList" @click="jumpUrl(item)">
        <img :src="require('../../public/image/menupop/' + item.path)" alt="" />
        <span>{{ item.name }}</span>
      </div>
      <div class="deliver"></div>
      <div class="download" @click="downLoadApk" v-if="client.indexOf('ios') === -1 && client.indexOf('Android') === -1">
        <img src="../../public/image/menu/downImg.png" alt="">
        <span>Baixar o APP</span>
      </div>
      <div style="display: flex;justify-content: center;align-items: center;" v-if="client.indexOf('ios') === -1 && client.indexOf('Android') === -1">
        <p style="color: white; margin-top: 10px;font-size: 12px;">Baixe e receba R$5</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getUnFinishedOrders } from "@/api/api";
import downLoadAppTips from "@/components/downLoadAppTips.vue";

export default {
  components: {},
  data() {
    return {
      menuList: [
        {
          name: 'Centro de bonus',
          path: 'bonus.png',
          url: '/bonus'
        },
        {
          name: 'Passe especial',
          path: 'SpecialPass.png',
          url: '/seasonPass'
        },
        {
          name: 'Indique e Ganhe',
          path: 'enter&win.png',
          url: '/share'
        },
        {
          name: 'Clube VIP',
          path: 'vip.png',
          url: '/vip'
        }
      ],
      activeNames: ['1'],
      topTitle: [
        {
          name: 'Jogar BIGWIN',
          hasChildren: true
        },
        {
          name: 'Outra',
          path: '/bonus',
          hasChildren: false
        }
      ],
      dataList: [
        {
          title: 'SLOTS',
          titleImg: require('../../public/image/menu/menuList/slotsTitle.png'),
          subList: [{
            name: 'PG',
            icon: require('../../public/image/menu/menuList/pg.png')
          },
          {
            name: 'PP',
            icon: require('../../public/image/menu/menuList/pp.png')
          },
          {
            name: 'JILI',
            icon: require('../../public/image/menu/menuList/jili.png')
          },
          {
            name: 'CQ9',
            icon: require('../../public/image/menu/menuList/cq9.png')
          }
          ]
        },
        {
          title: 'IN-HOUSE',
          titleImg: require('../../public/image/menu/menuList/houseTitle.png'),
          subList: [{
            name: 'Design JiLi',
            icon: require('../../public/image/menu/menuList/designJili.png')
          },
          {
            name: 'House',
            icon: require('../../public/image/menu/menuList/house.png')
          },
          {
            name: 'LIVE',
            icon: require('../../public/image/menu/menuList/liveTitle.png')
          },
          {
            name: 'TABLE',
            icon: require('../../public/image/menu/menuList/tableTitle.png')
          },
          ]
        },
      ]
    };
  },
  computed: {
    ...mapState(['taskNumber', "token", 'userData', 'client'])
  },
  created() {

  },
  mounted() {

  },
  methods: {
    callRegister() {
      this.$store.commit('setMenuBlockShow', false);
      this.$store.commit('IN_OR_UP', 'up');
      this.$store.commit('MASKCONTENT', 'login');
      this.$store.commit('MASK', true)
    },
    callLogin() {
      this.$store.commit('setMenuBlockShow', false);
      this.$store.commit('IN_OR_UP', 'in');
      this.$store.commit('MASKCONTENT', 'login');
      this.$store.commit('MASK', true)
    },
    jumpUrl(item) {
      if (item.url) {
        this.$store.commit('setMenuBlockShow', false);
        this.$router.push(item.url)
      }
    },
    depositMenu() {
      this.$store.commit('setMenuBlockShow', false);
      this.$router.push('/recharge');
    },
    downLoadApk() {
      window.open(this.APK_URL)
    },
    toPage(item) {
      switch (item) {
        case 'PG':
          this.$router.push('/gameList/pgGame')
          break;
        case 'PP':
          this.$router.push('/gameList/slots')
          break;

        case 'JILI':
          this.$router.push('/gameList/JiLi')
          break;

        case 'CQ9':
          this.$router.push('/gameList/Cq9')
          break;

        case 'Design JiLi':
          this.$router.push('/gameList/JiLiDesign')
          break;

        case 'House':
          this.$router.push('/gameList/internal')
          break;

        case 'LIVE':
          this.$router.push('/gameList/ppl')
          break;
        case 'TABLE':
          this.$router.push('/gameList/card')
          break;
      }
    },
    clickItem(val, path) {
      if (val == 0 && path) {
        return
      } else {
        this.toggleShow = !this.toggleShow
      }
    }
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/style.less";

.menu-container {
  overflow: hidden;
  border: 0;
}

.menu-logo {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;

  img {
    width: 260px;
  }
}

.menu-header {
  width: calc(100% + 2px);
  height: 220px;
  background-color: rgba(27, 28, 28);
}

.menu-context {
  background: #282A2D;
  height: calc(100vh - 106px);
  overflow-y: scroll;
  box-sizing: border-box;
  padding-top: 60px;
  padding-bottom: 120px;
  box-sizing: border-box;
}

.menu-item {
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 30px;

  img {
    width: 40px;
    margin-right: 47px;
  }

  span {
    color: #D1D1D9;
    font-size: 26px;
    font-weight: 600;
  }
}

.menu-header-operate {
  display: flex;
  flex-direction: row;
  justify-content: center;

  div {
    width: 250px;
    height: 60px;
    border-radius: 10px;
    font-size: 35px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  div:first-child {
    background-color: #D1D1D9;
    color: #525858;
    margin-right: 16px;
  }

  div:last-child {
    background-color: #F82E48;
    color: #FFFFFF;
  }
}

.adsArray {
  width: 520px;
  margin: 0 auto;
  color: #FFFFFF;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-size: 24px;

  .ads1 {
    width: 257px;
    height: 140px;
    background: url('@{IMAGE_CDN}menu/ads1.png') no-repeat;
    background-size: 100% 100%;
    display: flex;

    span {
      width: 162px;
      display: inline-block;
      margin-top: 5px;
      margin-left: 10px;
      text-shadow: 0 6px 10px #000000;
    }
  }

  .ads2 {
    width: 257px;
    height: 140px;
    background: url('@{IMAGE_CDN}menu/ads2.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    .ads2_wheel {
      width: 132px;
      position: absolute;
      bottom: -13px;
      right: 3px;
      animation: rotation 1.2s linear infinite;
    }

    span {
      width: 162px;
      display: inline-block;
      margin-left: 10px;
      margin-top: 5px;
      text-shadow: 0 6px 10px #000000;

    }
  }

  .ads3 {
    width: 169px;
    height: 136px;
    background: url('@{IMAGE_CDN}menu/ads3.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 13px;

    span {
      width: 100px;
      display: inline-block;
      margin-left: 10px;
      margin-top: 5px;
      text-shadow: 0 6px 10px #000000;
    }
  }

  .ads4 {
    width: 169px;
    height: 136px;
    background: url('@{IMAGE_CDN}menu/ads4.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 13px;

    span {
      width: 144px;
      display: inline-block;
      margin-left: 10px;
      margin-top: 5px;
      text-shadow: 0px 6px 10px #000000;
    }
  }

  .ads5 {
    width: 169px;
    height: 136px;
    background: url('@{IMAGE_CDN}menu/ads5.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 13px;

    span {
      width: 145px;
      display: inline-block;
      margin-left: 10px;
      margin-top: 5px;
      text-shadow: 0 6px 10px #000000;
    }
  }
}

.otherGame {
  width: 520px;
  margin: 0 auto;
  margin-top: 20px;

  .items {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-weight: bold;
  }
}

.van-collapse {}

::v-deep .van-cell {
  background: transparent !important;
  color: #fff !important;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.titleImg {
  width: 45px;
  margin-right: 39px;
}

.titleIcon {
  width: 45px;
  margin-right: 41px;
}

::v-deep .van-cell__title {
  div {
    display: flex !important;
    align-items: center !important;
  }
}

::v-deep .van-collapse-item__content {
  padding: 6px 10px;
  background: transparent;
  color: #D1D1D9;
}

::v-deep .van-collapse-item__title--expanded::after {
  display: none !important;
}

::v-deep [class*=van-hairline]::after {
  border: none !important;
}

::v-deep .van-cell::after {
  border: none !important;

}

::v-deep .van-collapse-item--border::after {
  border: none !important;
}

::v-deep .van-collapse-item {
  background: #343841;
  margin-top: 5px;
  border-radius: 5px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.topItems {
  color: #5B616D;
  font-size: 26px;
  margin: 20px 0;
}

.menuListUl {}

.deliver {
  width: 520px;
  height: 4px;
  background: #676783;
  margin: 21px auto 26px;
}

.download {
  width: 520px;
  height: 86px;
  margin: 0 auto;

  background: url('../../public/image/menu/downBg.png') no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 30px;

  img {
    width: 70px;
    margin-right: 18px;
  }
}
</style>