<script>
  import { defineComponent } from 'vue';
  import { mapState } from "vuex";
  import { SlotMachine } from 'lattice-lottery';
  import 'lattice-lottery/lib/lattice-lottery.css'
  import { doMarketRoulette, getUserMarketRoulette, rechargeRedBag, reqMarketMarquee } from "@/api/api";

  export default defineComponent({
    name: "ActivePopup",
    components: { SlotMachine },
    data() {
      return {
        slots: [{
          label: "0",
        }, {
          label: "1",
        }, {
          label: "2",
        }, {
          label: "3",
        }, {
          label: "4",
        }, {
          label: "5",
        }, {
          label: "6",
        }, {
          label: "7",
        }, {
          label: "8",
        }, {
          label: "9",
        }],
        isShow: false,
        name: '',
        money: 10,
        times: 0,
        marqueeList: [],
        marqueeIndex: 1,
        tigerOver: false,
        redBagId: 0,
        needRechargeMoney: 0,
        getExtraMoney: 0,
        submitLock: false,
        tigerResult: false,
        showPopover: false,
        showVipLimit: false,
        isDownFirstTimes: false
      }
    },
    computed: {
      ...mapState(['slotsTigerShow', 'token', "client", 'homePopOrder', 'userData', 'config'])
    },
    watch: {
      slotsTigerShow(val) {
        if (this.homePopOrder !== 1) this.isShow = val;
        if (val && this.token) {
          document.body.style.position = 'fixed';
          getUserMarketRoulette().then(res => {
            this.times = res.count;
            this.isDownFirstTimes = res.has_first;
            if (this.homePopOrder === 1 && res.count === 0) this.$store.commit('setSlotsTigerShow', false)
            else this.isShow = val;
          })
        } else {
          document.body.style.position = 'relative';
          this.$store.commit('setSlotsTigerShow', false);
          this.isShow = false;
        }
      },
    },
    created() {
      for (let i = 0; i < 10; i++) {
        this.slots.push({
          label: i.toString()
        })
      }
    },
    methods: {
      close() {
        this.$store.commit('setSlotsTigerShow', false);
        this.isShow = false;
      },
      slotFinish() {
        this.tigerResult = true;
        this.$store.dispatch('UserInfo');
        getUserMarketRoulette().then(res => {
          this.times = res.count;
          this.isDownFirstTimes = res.has_first;
        }).catch(() => { })
      },
      slotGo() {
        if (this.userData.vip_lv === 0 && this.isDownFirstTimes) {
          this.showVipLimit = true;
          return;
        }

        if (this.submitLock) return;
        this.submitLock = true;

        doMarketRoulette().then(res => {
          this.submitLock = false;
          this.times = res.count;
          if (res.count === 0) this.redBagId = res.rid;
          if (res.count === 0) this.needRechargeMoney = res.need_recharge;
          this.getExtraMoney = res.total;

          let amount = res.amount.split('');
          amount[0] = parseInt(amount[0]) + 10;
          amount[1] = parseInt(amount[1]) + 10;
          amount[2] = parseInt(amount[2]) + 10;

          this.$refs.machine.go(amount);
        }).catch(() => {
          this.submitLock = false;
        })
      },
      marqueeNext() {
        const marquee = this.marqueeList[this.marqueeIndex];
        this.name = marquee.nick_name;
        this.money = marquee.amount;
        this.marqueeIndex++;
      },
      recharge() {
        // let a = null;
        // if (this.client.indexOf('ios') !== -1) {
        //   a = window.open();
        // }

        let params = {
          rid: this.redBagId,
          channel: 'cwt',
        }
        let buryingParams = localStorage.getItem('BuryingParams');
        if (buryingParams && buryingParams !== 'null') {
          params.publish_channel = buryingParams.split('___')[0];
          params.pid = buryingParams.split('___')[1]
        }
        if (params.publish_channel) {
          params.click_id = localStorage.getItem('click_id')
        }
        rechargeRedBag(params).then(res => {

          // if (this.client.indexOf('ios') !== -1) {
          //   a.location = res;
          // } else {
          //   window.open(res)
          // }
          this.$store.commit('setPayforUrl', res)
          this.close()
        }).catch(() => {
          this.close()
        })
      }
    },
    mounted() {

    }
  })
</script>

<template>
  <div class="active-mask-block" v-show="isShow">
    <div class="operate-block">
      <van-icon name="clear" @click="close" style="margin-bottom: 22px" color="#FFF" />
      <van-popover v-model="showPopover" placement="left-start" style="z-index: 20" z-index="100" trigger="click">
        <p style="font-size: 16px;padding: 10px;z-index: 10">Torne-se VIP, ganhe rodadas grátis <br>diárias, o bônus
          requer apostas de 1x.</p>
        <template #reference>
          <van-icon name="question" color="#FFF" />
        </template>
      </van-popover>
    </div>

    <div class="active-block" v-show="!tigerOver">
      <div class="tiger-machine">
        <p class="tiger-time">Restam <span style="color: #2BB31B">{{times}}</span> tentativas de sorteio.</p>
        <img src="../../public/image/active/tiger-machine.png" alt="">
        <slot-machine class="slot-tiger-machine" :colCount="3" :moveTime="2" :list="slots" @onend="slotFinish"
          ref="machine" />
        <div class="tiger-machine-btn" @click="slotGo"></div>
      </div>
    </div>

    <div class="tiger-over-block" v-show="tigerOver">
      <div class="tiger-times-over">
        <img :src="IMAGE_URL + 'cashprize/tiger-title.png'" alt="">

        <div class="tiger-over-text">
          Parabéns!<br> Você recebeu uma oferta de recarga! Recarregue agora
          <span style="color: #2BB31B">R$ {{needRechargeMoney}}</span> e ganhe um bônus adicional de
          <span style="color: #2BB31B">R$ {{getExtraMoney}}</span>!
        </div>

        <div class="tiger-over-btn" @click="recharge">
          Recarregar
        </div>
      </div>
    </div>

    <van-popup v-model="tigerResult" class="confirm-block">
      <div class="game-prompt">
        <div class="game-prompt-title"></div>
        <van-icon @click="tigerResult = false" class="tips-close" name="cross" size="24" color="#fff" />
        <p>Parabéns, você ganhou</p>
        <p class="game-prompt-cash">R$ {{getExtraMoney}} !</p>
        <div class="game-prompt-ok" @click="tigerResult = false">ok</div>
      </div>
    </van-popup>

    <van-popup v-model="showVipLimit" class="confirm-block">
      <div class="game-prompt">
        <div class="game-prompt-title" style="margin: 0"></div>
        <van-icon @click="showVipLimit = false" class="tips-close" name="cross" size="24" color="#fff" />
        <p>Deposite</p>
        <p class="game-prompt-cash">R$ {{config.vip && config.vip[1]?.need_bets}}</p>
        <div class="vip-limit-text">
          <p>para se tornar <span style="font-size: 26px;color: red">VIP</span></p>
          <p>e receber um <span style="font-size: 26px;color: #FF8A00">Bônus</span></p>
        </div>
        <div class="vip-limit-btn">
          <div @click="showVipLimit = false">Cancelar</div>
          <div @click="$router.push('/recharge');showVipLimit = false;$store.commit('setSlotsTigerShow', false)">
            Tornar-se VIP</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<style scoped lang="less">
  @import "@/assets/style.less";

  .active-mask-block {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 13;
    color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    >div {
      text-align: center;
    }

    .active-block {
      position: relative;
      width: calc(100% - 80px);
      border-radius: 40px;
      animation: eject-pop 0.3s ease-in-out;
      background-size: 100%;
    }

    .tiger-over-block {
      position: relative;
      width: calc(100% - 80px);
      height: 700px;
      border-radius: 40px;
      animation: eject-pop 0.3s ease-in-out;
      background: url("@{IMAGE_CDN}cashprize/tiger-over-back.png") no-repeat;
      background-size: 100%;
    }
  }

  .operate-block {
    position: absolute;
    right: 44px;
    top: 28%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: 10;

    p {
      font-size: 40px;
    }
  }

  .tiger-machine {
    width: 100%;
    margin-top: -20px;

    img {
      width: 85%;
    }

    .tiger-machine-btn {
      position: absolute;
      bottom: 74px;
      left: 126px;
      background: url("../../public/image/active/tiger-solt-btn.png") no-repeat;
      background-size: 100%;
      width: 410px;
      height: 140px;
      font-size: 40px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      font-weight: 900;
    }

    .tiger-machine-btn:active {
      transform: scale(0.98);
    }
  }

  .tiger-times-over {
    width: 100%;
    margin-top: -20px;

    img {
      width: 72%;
      margin-top: -100px;
    }

    .tiger-over-btn {
      position: absolute;
      bottom: 90px;
      left: 128px;
      background: url("@{IMAGE_CDN}cashprize/tiger-spin.png") no-repeat;
      background-size: 100%;
      width: 410px;
      height: 140px;
      font-size: 40px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      font-weight: 900;
    }
  }

  .slot-tiger-machine {
    position: absolute;
    top: 172px;
    left: 136px;
  }

  .tiger-money {
    position: absolute;
    top: 340px;
    left: 80px;
    color: #FCD4FF;
    font-size: 28px;
    width: 460px;
    background: transparent;
  }

  .tiger-time {
    position: absolute;
    top: -100px;
    left: 110px;
    color: #FCD4FF;
    font-size: 28px;
    width: 460px;
    background: transparent;
  }

  .tiger-over-text {
    font-size: 30px;
    color: #FCD4FF;
    padding: 0 70px;
    text-align: left;
  }

  .confirm-block {
    width: 80%;
    height: 640px;
    background-color: #FFFFFF;
    border-radius: 20px;

    .tips-close {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  .game-prompt {
    width: 100%;

    p {
      font-size: 34px;
      width: 100%;
      text-align: center;
      font-weight: 600;
      margin: 30px 0;
      color: #000000;
    }

    .game-prompt-cash {
      font-size: 60px !important;
      color: #008600;
      margin: 20px 0;
    }

    .game-prompt-title {
      height: 90px;
      background-color: #F82E48;
      margin-bottom: 120px;
    }

    .game-prompt-ok {
      width: 500px;
      background-color: #F82E48;
      color: #FFFFFF;
      border-radius: 14px;
      padding: 16px;
      text-align: center;
      margin: 20px auto;
      margin-top: 150px;
      font-size: 48px;
    }

    .vip-limit-text {
      p {
        margin: 0;
      }
    }

    .vip-limit-btn {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 30px;

      div {
        width: 200px;
        font-size: 30px;
        padding: 26px;
        border-radius: 16px;
        margin: 20px;
      }

      div:first-child {
        background-color: #CBCBCB;
        color: #595959;
      }

      div:last-child {
        background-color: #F82E48;
        color: #FFFFFF;
      }
    }
  }
</style>
<style>
  .slotMachine {
    width: 400px;
  }

  .slotMachine__label {
    font-size: 100px !important;
    color: #FFE995 !important;
    font-weight: 900;
    text-shadow: 1px 1px #5C0BC2,
      2px 2px #5C0BC2,
      3px 3px #5C0BC2,
      4px 4px #5C0BC2,
      5px 5px #5C0BC2,
      6px 6px #5C0BC2;
    -webkit-text-stroke: 5px #5C0BC2;
    font-family: 'ArialBold', 'Arial';
  }

  .slotMachine__col {
    height: 100px;
  }

  .slotMachine__li {
    height: 100px;
  }

  .slotMachine__col {
    box-shadow: none;
  }
</style>