var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showMask
    ? _c("div", { staticClass: "active-mask-block" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "active-block",
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.closeIcon,
                    expression: "closeIcon",
                  },
                ],
                staticClass: "active-close-block",
              },
              [
                _c("img", {
                  attrs: {
                    src: require("../../public/image/purse/close.png"),
                    alt: "close",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.closePoster.apply(null, arguments)
                    },
                  },
                }),
              ]
            ),
            _c("div", { staticClass: "content" }, [_vm._t("content")], 2),
            _c("div", { staticClass: "btns" }, [_vm._t("btns")], 2),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }