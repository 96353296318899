var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defineComponent } from "vue";
import { mapState } from "vuex";
import ActivePopUp from "@/views/recharge/activePopUp.vue";
export default defineComponent({
    name: "HomePagePopUpLogic",
    data: function () {
        return {};
    },
    components: { ActivePopUp: ActivePopUp },
    computed: __assign({}, mapState([
        "readyPopUp",
        "homePopOrder",
        "signInByDay",
        "slotsTigerShow",
        "openCodingTask",
        "rechargeActiveShow",
        "userData",
    ])),
    watch: {
        homePopOrder: function (val) {
            console.log("homePopOrder: ", val);
            this.$store.commit("setReadyPopUp", true);
            if (this.isNeedRecharge(val))
                return;
            if (val === 1) {
                this.$store.commit("setSlotsTigerShow", true);
            }
            else if (val === 2) {
                this.$store.commit("setRechargeActiveShow", true);
            }
            else if (val === 3) {
                this.$store.commit("setSignInByDay", true);
            }
            else if (val === 4) {
                this.$store.commit("setOpenCodingTask", true);
            }
        },
        slotsTigerShow: function (val) {
            if (!val && this.readyPopUp)
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
        },
        signInByDay: function (val) {
            if (!val && this.readyPopUp)
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
        },
        openCodingTask: function (val) {
            if (!val && this.readyPopUp)
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
        },
        rechargeActiveShow: function (val) {
            if (!val && this.readyPopUp)
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
        },
        $route: function (to) {
            if (to.name === "index") {
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
            }
        },
        userData: function (val) {
            if (this.homePopOrder === 0)
                this.$store.commit("setHomePopOrder", 1);
        },
    },
    methods: {
        isNeedRecharge: function (val) {
            if ((val === 2 || val === 4) && !this.userData.has_recharge) {
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
                return true;
            }
            else if (val === 1 && this.userData.has_recharge) {
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
                return true;
            }
            else {
                return false;
            }
        },
    },
});
