import { render, staticRenderFns } from "./GameSelector.vue?vue&type=template&id=1db1c5a1&scoped=true"
import script from "./GameSelector.vue?vue&type=script&setup=true&lang=ts"
export * from "./GameSelector.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./GameSelector.vue?vue&type=style&index=0&id=1db1c5a1&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1db1c5a1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\Work\\cwt_vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1db1c5a1')) {
      api.createRecord('1db1c5a1', component.options)
    } else {
      api.reload('1db1c5a1', component.options)
    }
    module.hot.accept("./GameSelector.vue?vue&type=template&id=1db1c5a1&scoped=true", function () {
      api.rerender('1db1c5a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GameSelector.vue"
export default component.exports