var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "formArr" }, [
    _vm.inorup === "up"
      ? _c("div", { staticStyle: { "margin-bottom": "19px" } }, [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: {
              src: require("../../public/image/login/register-banner.png"),
              alt: "",
            },
          }),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "login-tabs" },
      _vm._l(_vm.loginChannel, function (item, index) {
        return _c(
          "span",
          {
            key: index,
            class: _vm.activeIndex === index ? "activeClass" : "defaultClass",
            on: {
              click: function ($event) {
                return _vm.change_tabs(item, index)
              },
            },
          },
          [_vm._v(" " + _vm._s(item.name))]
        )
      }),
      0
    ),
    _vm.activeIndex
      ? _c("div", { staticClass: "input-title" }, [_vm._v("Email")])
      : _c("div", { staticClass: "input-title" }, [_vm._v("Phone")]),
    _vm.activeIndex
      ? _c(
          "div",
          {
            staticClass: "email_box",
            class: _vm.errorList.includes("email") ? "errorStyle" : "",
          },
          [
            _c("van-icon", {
              staticClass: "pre_pics",
              attrs: { size: "20px", name: "contact" },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              attrs: {
                type: "email",
                placeholder: "Por favor, insira o seu e-mail",
              },
              domProps: { value: _vm.email },
              on: {
                focus: function ($event) {
                  return _vm.myFocus()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.email = $event.target.value
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    !_vm.activeIndex
      ? _c(
          "div",
          {
            staticClass: "email_box",
            class: _vm.errorList.includes("phone") ? "errorStyle" : "",
          },
          [
            _c("van-icon", {
              staticClass: "pre_pics",
              attrs: { size: "20px", name: "contact" },
            }),
            _c("div", { staticClass: "preNumber" }, [_vm._v(" +55 ")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.phone,
                  expression: "phone",
                },
              ],
              attrs: {
                type: "number",
                onkeypress:
                  "return(/[\\d]/.test(String.fromCharCode(event.keyCode)))",
                placeholder: "Digite o número de celular",
                oninput: "if (value.length > 11) value = value.slice(0,11)",
              },
              domProps: { value: _vm.phone },
              on: {
                focus: function ($event) {
                  return _vm.myFocus()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.phone = $event.target.value
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "input-title" }, [_vm._v("Password")]),
    _c(
      "div",
      {
        staticClass: "pwd_box",
        class: _vm.errorList.includes("psw") ? "errorStyle" : "",
      },
      [
        _c("van-icon", {
          staticClass: "pre_pics",
          attrs: { size: "20px", name: "lock" },
        }),
        _c("input", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isshowPwd,
              expression: "!isshowPwd",
            },
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          attrs: {
            type: "password",
            placeholder: "Introduza a sua palavra-passe",
          },
          domProps: { value: _vm.password },
          on: {
            focus: function ($event) {
              return _vm.myFocus()
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.password = $event.target.value
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isshowPwd,
              expression: "isshowPwd",
            },
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          attrs: { type: "text", placeholder: "Introduza a sua palavra-passe" },
          domProps: { value: _vm.password },
          on: {
            focus: function ($event) {
              return _vm.myFocus()
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.password = $event.target.value
            },
          },
        }),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isshowPwd,
              expression: "!isshowPwd",
            },
          ],
          staticClass: "after_pics",
          attrs: {
            src: require("../../public/image/login/hidden.png"),
            alt: "",
          },
          on: {
            click: function ($event) {
              return _vm.showPwd(false)
            },
          },
        }),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isshowPwd,
              expression: "isshowPwd",
            },
          ],
          staticClass: "after_pics",
          attrs: { src: require("../../public/image/login/show.png"), alt: "" },
          on: {
            click: function ($event) {
              return _vm.showPwd(true)
            },
          },
        }),
      ],
      1
    ),
    _vm.inorup === "in"
      ? _c(
          "button",
          {
            staticClass: "submit",
            on: {
              click: function ($event) {
                return _vm.clickSubmit(_vm.inorup)
              },
            },
          },
          [_vm._v("Autenticar-se")]
        )
      : _vm._e(),
    _vm.inorup === "up"
      ? _c(
          "button",
          {
            staticClass: "submit",
            on: {
              click: function ($event) {
                return _vm.clickSubmit(_vm.inorup)
              },
            },
          },
          [_vm._v("Criar conta")]
        )
      : _vm._e(),
    _vm._m(0),
    _vm.client !== "android_app"
      ? _c(
          "div",
          { staticClass: "pic_box", attrs: { id: "google_login_block" } },
          [
            _c("img", {
              staticClass: "pic1",
              attrs: {
                src: require("../../public/image/login/pic1.png"),
                alt: "",
              },
              on: { click: _vm.jumpGoogle },
            }),
          ]
        )
      : _vm._e(),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "directly-login" }, [
      _c("hr"),
      _c("div", { staticClass: "directly-login-text" }, [
        _vm._v("Or log in directly with"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "agree-text" }, [
      _c("img", {
        attrs: {
          src: require("../../public/image/login/agreeLogin.png"),
          alt: "",
        },
      }),
      _c("div", [
        _vm._v(
          " To visit this website,please ensure that you have reached the age of 18 and agree "
        ),
        _c("span", { staticStyle: { color: "#3B4AD6" } }, [
          _vm._v("Terms of Service"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }