import { generateUUID, HandlerUrlParams } from '@/utis/common';
import store from "@/store";
import { getConfig } from "@/api/api";
export var HandlerPremiseTask = function () {
    var token = HandlerUrlParams('token');
    var utmSource = HandlerUrlParams('utm_source');
    var pid = HandlerUrlParams('pid');
    var kwai_click_id = HandlerUrlParams('click_id');
    var tt_click_id = HandlerUrlParams('ttclid');
    var fb_click_id = HandlerUrlParams('fbclid');
    if (token)
        store.commit('setToken', token);
    if (utmSource && pid)
        localStorage.setItem('BuryingParams', utmSource + '___' + pid);
    if (kwai_click_id)
        localStorage.setItem('click_id', kwai_click_id);
    if (tt_click_id)
        localStorage.setItem('click_id', tt_click_id);
    if (fb_click_id)
        localStorage.setItem('click_id', fb_click_id);
    handlerUUID();
    store.commit('setClient', judgeClient());
    // 异步任务
    GetConfig();
};
var GetConfig = function () {
    getConfig().then(function (res) {
        store.commit('setConfig', res);
    });
};
var judgeClient = function () {
    var client = '';
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        if ((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) && navigator.vendor.indexOf('Apple') !== -1) {
            client = 'ios__safari';
        }
        else
            client = 'ios';
    }
    else if (/(Android)/i.test(navigator.userAgent)) {
        client = 'Android';
    }
    else {
        client = 'PC';
    }
    return client;
};
var handlerUUID = function () {
    var only_uuid = localStorage.getItem('only_uuid');
    if (!only_uuid) {
        var new_uuid = generateUUID();
        localStorage.setItem('only_uuid', new_uuid);
    }
};
// const getCurrentChannel = () => {
//     // 请求渠道
//     getChannel().then(res => {
//         if (res.data) store.commit('setChannel', res.data);
//         store.dispatch('onLoading', false);
//         document.getElementById('init-loading').style.display = 'none';
//         document.getElementById('app').style.display = 'block';
//     })
// }
