var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShow
    ? _c(
        "div",
        { staticClass: "messages loadIn" },
        [
          _c("div", { staticClass: "titlebox" }, [
            _c("div", { staticClass: "title" }, [_vm._v("Notifications")]),
            _c("img", {
              attrs: {
                src: require("../../public/image/message/close.png"),
                alt: "",
              },
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) return null
                  return _vm.toCloseAll.apply(null, arguments)
                },
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "tab" },
            _vm._l(_vm.btn, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "options1",
                  class: _vm.activeIndex === index ? "activeClass" : "",
                  on: {
                    click: function ($event) {
                      return _vm.change_status(item, index)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "optionItem" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("div", { staticClass: "num" }, [
                    _vm._v(
                      _vm._s(
                        index === 0
                          ? _vm.promoteList.length
                          : _vm.messageList.length
                      )
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._l(_vm.messageList, function (item) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeIndex === 1,
                    expression: "activeIndex===1",
                  },
                ],
                staticClass: "message-block",
                class: _vm.spliceIndex === item.id ? "loadOut" : "",
                style: { backgroundColor: item.just ? "#1EB83E20" : "#12212E" },
              },
              [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.readyDelIndex === item.id,
                      expression: "readyDelIndex === item.id",
                    },
                  ],
                  class: _vm.readyDelIndex === item.id ? "imageFilter" : "",
                }),
                _c("img", {
                  staticClass: "message-head",
                  attrs: { src: item.image, alt: "image" },
                }),
                _c("div", { staticClass: "message-context" }, [
                  _c("div", { staticClass: "message-context-title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c("div", {
                    staticClass: "message-context-text",
                    domProps: { innerHTML: _vm._s(item.content) },
                  }),
                  _c("div", { staticClass: "toMark" }, [
                    _c("div", { staticClass: "text" }, [_vm._v("Lido")]),
                    _c("div", {
                      staticClass: "choose",
                      class: item.status === 2 ? "choose_active" : "",
                      on: {
                        click: function ($event) {
                          return _vm.readMessage(item.id)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("van-loading", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.readyDelIndex === item.id,
                      expression: "readyDelIndex === item.id",
                    },
                  ],
                  staticClass: "splice-loading",
                  attrs: { color: "#1989fa" },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.readyDelIndex !== item.id,
                      expression: "readyDelIndex !== item.id",
                    },
                  ],
                  staticClass: "message-close",
                  attrs: {
                    src: require("../../public/image/message/close.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.closeMessage(item.id)
                    },
                  },
                }),
              ],
              1
            )
          }),
          _vm._l(_vm.promoteList, function (item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeIndex === 0,
                    expression: "activeIndex===0",
                  },
                ],
                staticClass: "platform_block",
              },
              [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "left" }, [
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.transformTime(item.create_at))),
                    ]),
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(item.title) + " "),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isToday(item.create_at),
                          expression: "isToday(item.create_at)",
                        },
                      ],
                      staticClass: "tips",
                    },
                    [_vm._v("NEW")]
                  ),
                ]),
                _c("div", { staticClass: "imgbox" }, [
                  _c("img", { attrs: { src: item.banner, alt: "" } }),
                ]),
                _c("div", { staticClass: "wrap" }, [
                  _c(
                    "div",
                    {
                      ref: "exp",
                      refInFor: true,
                      staticClass: "desc",
                      class: _vm.expandList[index] ? "showAll" : "",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.expandList[index],
                              expression: "!expandList[index]",
                            },
                          ],
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.showDesc(index)
                            },
                          },
                        },
                        [
                          _vm._v("Show all "),
                          _c("img", {
                            attrs: {
                              src: require("../../public/image/message/down.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.expandList[index],
                              expression: "expandList[index]",
                            },
                          ],
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.hideDesc(index)
                            },
                          },
                        },
                        [
                          _vm._v("Hide "),
                          _c("img", {
                            attrs: {
                              src: require("../../public/image/message/up.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" " + _vm._s(item.description) + " "),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "bar" }, [
                  _c(
                    "div",
                    {
                      staticClass: "left",
                      on: {
                        click: function ($event) {
                          return _vm.linkMore(item.id)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v("Saber mais")]),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: require("../../public/image/message/arrowto.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            )
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeIndex === 0 && _vm.promoteList.length === 0,
                  expression: "activeIndex === 0 && promoteList.length === 0",
                },
              ],
              staticClass: "defaultImg",
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../public/image/message/noContent.png"),
                  alt: "",
                },
              }),
              _c("p", [_vm._v("You don't have any platform notifications")]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeIndex === 1 && _vm.messageList.length === 0,
                  expression: "activeIndex === 1 && messageList.length === 0",
                },
              ],
              staticClass: "defaultImg",
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../public/image/message/noContent.png"),
                  alt: "",
                },
              }),
              _c("p", [_vm._v("You don't have any personal notifications")]),
            ]
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }