<template>
  <div class="container">
    <div style="background-color: rgb(11, 19, 29)">
      <div class="banner" v-if="channel.open_recharge">
        <div style="width: 100%">
          <van-swipe
            class="my-swipe"
            :show-indicators="true"
            :autoplay="3000"
            indicator-color="#717070"
          >
            <van-swipe-item
              v-for="(item, index) in banners"
              :key="item.index"
              @click="clickBanner(index)"
              :class="gameLoading ? 'imageFilter' : ''"
            >
              <img class="swipe-image" :src="item.img" alt="" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <BriefMenu />
      <GameModule />
      <!--      <Jopos/>-->
      <Ranking />
      <Domain />
      <HomePagePopUpLogic />
      <DownLoadAppTips />
    </div>
    <div class="hover-icon">
      <div class="webp-icon">
        <img
          @click="openDeposito"
          :src="IMAGE_URL + 'common/cheerGift.webp'"
          alt=""
        />
        <img :src="IMAGE_URL + 'common/kkli.png'" alt="" />
      </div>
      <img
        @click="openSignIn"
        :src="IMAGE_URL + 'common/hover-recharge.png'"
        alt=""
      />
      <img
        @click="openTigerSlot"
        :src="IMAGE_URL + 'common/hover-tiger-icon.png'"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import Ads from "../../components/Ads.vue";
import Jopos from "@/components/Jopos.vue";
import ReisFull from "../../components/Reis.vue";
import Ranking from "../../components/Ranking.vue";
import Copyright from "../../components/Copyright.vue";
import TaskPopUp from "@/components/TaskPopUp.vue";
import BriefMenu from "@/views/home/briefMenu.vue";
import Domain from "../../components/Domain.vue";

import { mapState } from "vuex";
import { buryingLog } from "@/api/PromotionInterFace";
import { userMonthCardInfo, getUserPack } from "@/api/api";
import DownLoadAppTips from "@/components/downLoadAppTips.vue";
import HomePagePopUpLogic from "@/components/HomePagePopUpLogic.vue";
import GameModule from "@/views/home/gameModule.vue";

export default {
  name: "home",
  components: {
    GameModule,
    HomePagePopUpLogic,
    DownLoadAppTips,
    TaskPopUp,
    Ads,
    Jopos,
    ReisFull,
    Ranking,
    Copyright,
    BriefMenu,
    Domain,
  },
  computed: {
    ...mapState([
      "userData",
      "channel",
      "isLoading",
      "token",
      "config",
      "innerMask",
    ]),
  },
  data() {
    return {
      banners: [],
      isReady: false,
      swipePopTips: 0,
      showSignReward: false,
      gameLoading: false,
      isShowPass: false,
      isCherrShow: false,
      isSpeedShow: false,
    };
  },
  created() {
    getUserPack()
      .then((res) => {
        if (res) {
          res.map((item) => {
            if (item.pack_type === 1) {
              this.isCherrShow = true;
              this.$store.commit("setCheerPopShow", true);
            }
            if (item.pack_type === 2) {
              this.isSpeedShow = true;
              this.$store.commit("setSpeedUpPopShow", true);
            }
          });
        }
      })
      .catch((err) => { });
  },
  methods: {
    clickBanner(index) {
      if (index === 0) {
        this.token ? this.$router.push("/recharge") : this.showLogin();
      } else if (index === 1) {
        this.token ? this.$router.push("/share") : this.showLogin();
      } else if (index === 2) {
        this.token ? this.$router.push("/vip") : this.showLogin();
      } else if (index === 3) {
        this.token ? this.$router.push("/bonus") : this.showLogin();
      } else if (index === 4) {
        this.token ? this.$router.push("/tournament") : this.showLogin();
      }
    },
    toRegister() {
      this.$store.commit("setMenuBlockShow", false);
      this.$store.commit("IN_OR_UP", "up");
      this.$store.commit("MASKCONTENT", "login");
      this.$store.commit("MASK", true);
      this.$emit("headerTop");
    },
    loadBanner() {
      this.banners = [
        { img: this.IMAGE_URL + "home/promo1.png" },
        { img: this.IMAGE_URL + "home/promo2.png" },
        { img: this.IMAGE_URL + "home/promo3.png" },
        { img: this.IMAGE_URL + "home/promo4.png" },
        { img: this.IMAGE_URL + "home/promo5.png" },
      ];
    },
    showLogin() {
      this.$store.commit("IN_OR_UP", "in");
      this.$store.commit("MASKCONTENT", "login");
      this.$store.commit("MASK", true);
    },
    openDeposito() {
      if (this.token) {
        this.$router.push('/recharge');
      } else {
        this.$store.commit("IN_OR_UP", "in");
        this.$store.commit("MASKCONTENT", "login");
        this.$store.commit("MASK", true);
      }
    },
    openSignIn() {
      if (this.token) {
        this.$store.commit("setSignInByDay", true);
      } else {
        this.$store.commit("IN_OR_UP", "in");
        this.$store.commit("MASKCONTENT", "login");
        this.$store.commit("MASK", true);
      }
    },
    openTigerSlot() {
      if (this.token) {
        this.$store.commit("setSlotsTigerShow", true);
      } else {
        this.$store.commit("IN_OR_UP", "in");
        this.$store.commit("MASKCONTENT", "login");
        this.$store.commit("MASK", true);
      }
    },
    opencheerPop() {
      if (this.token) {
        this.$store.commit("setCheerPopShow", true);
      } else {
        this.$store.commit("IN_OR_UP", "in");
        this.$store.commit("MASKCONTENT", "login");
        this.$store.commit("MASK", true);
      }
    },
    openspeedUpPop() {
      if (this.token) {
        this.$store.commit("setSpeedUpPopShow", true);
      } else {
        this.$store.commit("IN_OR_UP", "in");
        this.$store.commit("MASKCONTENT", "login");
        this.$store.commit("MASK", true);
      }
    },
  },
  mounted() {
    buryingLog({
      event_name: "EVENT_CONTENT_VIEW",
      properties: JSON.stringify({
        content_name: "index",
        content_type: "product",
        content_id: "index",
      }),
    });
    setTimeout(() => {
      this.loadBanner();
    }, 300);
    userMonthCardInfo()
      .then((res) => {
        if (res && res.length > 1) {
          this.isShowPass = false;
        } else if (res && res.length === 1 && res[0].unlock === true) {
          this.isShowPass = false;
        } else {
          this.isShowPass = true;
        }
      })
      .catch(() => { });
  },
};
</script>
<style lang="less" scoped>
.container {
}

.banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-swipe {
  height: 350px;
  background: rgb(11, 19, 29);
  position: relative;
}

.swipe-image {
  width: 100%;
  height: 313px;
}

.left {
  width: 50px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 30px;
  margin-left: -30px;
}

.right {
  width: 50px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 30px;
  margin-right: -30px;
}

::v-deep .van-swipe__indicators {
  bottom: 4px !important;
}

.imageFilter {
  filter: brightness(0.2);
}

.hover-icon {
  position: fixed;
  bottom: 200px;
  right: 50px;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: column;
  }

  div > img:first-child {
    margin-bottom: -2px;
  }

  img {
    width: 90px;
    margin-bottom: 20px;
  }
}
</style>