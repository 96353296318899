var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _setup.show,
          expression: "show",
        },
      ],
      staticClass: "toast",
    },
    [
      _c(
        "div",
        {
          staticClass: "toast-block",
          style: {
            backgroundColor: _setup.type === "failed" ? "#B54223" : "#47B053",
          },
        },
        [_vm._v(" " + _vm._s(_setup.text) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }