var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "van-tabs",
        {
          staticClass: "game-tabs",
          attrs: { "swipe-threshold": "1" },
          model: {
            value: _vm.tabActive,
            callback: function ($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive",
          },
        },
        [
          _vm._l(_vm.menuList, function (item, keys) {
            return _c("van-tab", {
              key: keys,
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("img", {
                          class: "img" + keys,
                          attrs: { src: item.img, alt: "" },
                        }),
                        _c("div", { staticClass: "itemName" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("van-icon", {
            staticClass: "game-tabs-search",
            attrs: { name: "search", color: "#fff", size: "26" },
            on: {
              click: function ($event) {
                _vm.showSearch = true
              },
            },
          }),
        ],
        2
      ),
      _vm.tabActive !== 0
        ? _c("div", { staticClass: "game-tabs-content" }, [
            _c("div", { staticClass: "content_top" }, [
              _c("div", { staticClass: "content_left" }, [
                _c("img", {
                  attrs: { src: _vm.menuList[_vm.tabActive].img, alt: "" },
                }),
                _c("span", [_vm._v(_vm._s(_vm.menuList[_vm.tabActive].name))]),
              ]),
              _c(
                "div",
                {
                  staticClass: "content-nav-block",
                  on: { click: function ($event) {} },
                },
                [_vm._v(" Ver mais  > ")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "game-tabs-swipe-block" },
              [
                _c("van-loading", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectGameList.length === 0,
                      expression: "selectGameList.length === 0",
                    },
                  ],
                  staticClass: "game-tabs-loading",
                  attrs: { type: "spinner" },
                }),
                _vm.selectGameList.length > 0
                  ? _c(
                      "van-swipe",
                      {
                        ref: "gameTableSwipe",
                        attrs: { loop: false, "show-indicators": false },
                      },
                      _vm._l(_vm.selectGameList, function (array, index) {
                        return _c("van-swipe-item", { key: index }, [
                          _c(
                            "div",
                            { staticClass: "game-grid-3-block" },
                            _vm._l(array, function (item) {
                              return _c(
                                "div",
                                [
                                  _c("GameCard", {
                                    attrs: { json: item, isHot: index <= 6 },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ])
                      }),
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("gameSearch", {
        attrs: { show: _vm.showSearch },
        on: {
          close: function ($event) {
            _vm.showSearch = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }