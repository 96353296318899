var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticStyle: { "background-color": "rgb(11, 19, 29)" } },
      [
        _vm.channel.open_recharge
          ? _c("div", { staticClass: "banner" }, [
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "van-swipe",
                    {
                      staticClass: "my-swipe",
                      attrs: {
                        "show-indicators": true,
                        autoplay: 3000,
                        "indicator-color": "#717070",
                      },
                    },
                    _vm._l(_vm.banners, function (item, index) {
                      return _c(
                        "van-swipe-item",
                        {
                          key: item.index,
                          class: _vm.gameLoading ? "imageFilter" : "",
                          on: {
                            click: function ($event) {
                              return _vm.clickBanner(index)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "swipe-image",
                            attrs: { src: item.img, alt: "" },
                          }),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("BriefMenu"),
        _c("GameModule"),
        _c("Ranking"),
        _c("Domain"),
        _c("HomePagePopUpLogic"),
        _c("DownLoadAppTips"),
      ],
      1
    ),
    _c("div", { staticClass: "hover-icon" }, [
      _c("div", { staticClass: "webp-icon" }, [
        _c("img", {
          attrs: { src: _vm.IMAGE_URL + "common/cheerGift.webp", alt: "" },
          on: { click: _vm.openDeposito },
        }),
        _c("img", {
          attrs: { src: _vm.IMAGE_URL + "common/kkli.png", alt: "" },
        }),
      ]),
      _c("img", {
        attrs: { src: _vm.IMAGE_URL + "common/hover-recharge.png", alt: "" },
        on: { click: _vm.openSignIn },
      }),
      _c("img", {
        attrs: { src: _vm.IMAGE_URL + "common/hover-tiger-icon.png", alt: "" },
        on: { click: _vm.openTigerSlot },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }