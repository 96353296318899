<script>
import { defineComponent } from "vue";
import { SplitGamePosterUrl } from "@/utis/common";
import { mapState } from "vuex";
import { getGamePlayUrl } from "@/api/api";

export default defineComponent({
  name: "GameCard",
  props: ["isHot", "json"],
  data() {
    return {
      isReadyPlay: false,
      requestGamePending: false,
      gameInfo: {},
    };
  },
  computed: {
    ...mapState(["playingGameName"]),
  },
  watch: {
    playingGameName(val) {
      this.isReadyPlay =
        val ===
        this.json.name + "_" + this.json.game_id + "_" + this.json.channel;
    },
  },
  created() {},
  methods: {
    SplitGamePosterUrl,
    readyPlay() {
      if (!localStorage.getItem("token")) {
        this.$store.commit("IN_OR_UP", "in");
        this.$store.commit("MASKCONTENT", "login");
        this.$store.commit("MASK", true);
        return;
      }
      if (this.json.active_status === 1) return;
      if (this.json.game_typ === 14 || this.json.game_typ === 15) {
        this.$store.dispatch("toast", {
          show: true,
          text: "ocê precisa alcançar o VIP8 para poder entrar no jogo.",
          type: "failed",
        });
        return;
      }

      this.$store.commit("setPlayingGameName", "");
      this.requestGamePending = true;
      getGamePlayUrl({
        gameId: this.json.game_id,
        isTail: false,
      })
        .then((res) => {
          if (res) {
            this.$store.commit(
              "setPlayingGameName",
              this.json.name + "_" + this.json.game_id + "_" + this.json.channel
            );

            this.requestGamePending = false;
            this.gameInfo = res;
            if (res.pack) {
              this.$store.commit("setGameRechargePopup", this.json.game_id);
            }
            if (res.valid) {
              this.$store.commit("setNotAllowGame", true);
              return;
            }
            if (res.cash > 0) {
              this.$store.commit(
                "setPartMoneyGame",
                this.json.game_id + "__" + res.cash + "__" + Math.random()
              );
            }
          }
        })
        .catch(() => {
          this.requestGamePending = false;
        });
    },
    doPlay() {
      if (this.gameInfo.valid) {
        this.$store.commit("setNotAllowGame", true);
        return;
      }
      if (this.gameInfo.blank) {
        this.$router.push({
          name: "play",
          params: { url: "pgGameId__" + this.gameInfo.game_id },
        });
      } else {
        if (this.gameInfo.cash > 0) {
          this.$store.commit(
            "setPartMoneyGame",
            this.gameInfo.game_id +
              "__" +
              this.gameInfo.cash +
              "__" +
              Math.random()
          );
        } else {
          this.$router.push({
            name: "play",
            params: { url: this.gameInfo.url },
          });
        }
      }
    },
  },
});
</script>

<template>
  <div class="game-card">
    <div v-if="json.id">
      <img
        v-if="isHot"
        class="game-hot-tag"
        src="../../public/image/common/hot-tag.png"
        alt=""
      />
      <img
        :class="isReadyPlay || requestGamePending ? 'image-filter' : ''"
        :src="SplitGamePosterUrl(json)"
        @click="readyPlay"
        alt=""
      />

      <div class="game-maintenance" v-show="json.active_status === 1">
        <img src="../../public/image/common/game-maintenance.png" alt="" />
        <span>Em manutenção</span>
      </div>

      <div class="game-play-loading" v-show="requestGamePending">
        <van-loading />
      </div>

      <img
        v-show="isReadyPlay"
        @click="doPlay"
        class="play-btn"
        src="../../public/image/common/playBtn.png"
        alt=""
      />

      <span v-show="isReadyPlay" class="top_name">{{ json.name }}</span>
      <span v-show="isReadyPlay" class="bottom_types">
        {{
          json.channel === 1
            ? "PragmaticPlay"
            : json.channel === 2
            ? "Pocket Games"
            : ""
        }}
      </span>
    </div>
    <img
      v-if="!json.id"
      @click="$router.push('/gameShop/' + json.channel)"
      src="../../public/image/home/viewAllGame.png"
      alt=""
    />
  </div>
</template>

<style scoped lang="less">
.game-card {
  > div {
    border-radius: 20px;
    position: relative;
    min-height: 260px;
    background-color: #213547;
    overflow: hidden;
  }
  @keyframes rolled {
    0% {
      left: -150px;
    }

    100% {
      left: 920px;
    }
  }
  .game-maintenance {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000bf;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0;
    img {
      width: 60px;
      margin-bottom: 20px;
    }
    span {
      font-size: 24px;
      color: #94bde3;
      font-weight: 700;
    }
  }
  img {
    width: 100%;
    border-radius: 20px;
    vertical-align: middle;
  }
}
.game-play-loading {
  position: absolute !important;
  top: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.game-hot-tag {
  position: absolute;
  top: -4px;
  left: -4px;
  width: 80px !important;
  height: auto !important;
}
.play-btn {
  width: 100px !important;
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  animation: play-scale 200ms ease-in;
}
.top_name {
  display: block;
  width: 110px !important;
  position: absolute;
  left: calc(50% - 55px);
  top: 10px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: #94bde3;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.bottom_types {
  display: block;
  width: 100% !important;
  position: absolute;
  left: 0;
  bottom: 10px;
  color: #94bde3;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}
.image-filter {
  filter: brightness(0.2);
}
</style>