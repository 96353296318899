var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        _vm.client.indexOf("ios") === -1 && _vm.client.indexOf("Android") === -1
          ? "copyright-nomargin"
          : "copyright",
    },
    [
      _c("div", { staticClass: "copyright_box" }, [
        _c("div", { staticClass: "options" }, [
          _c(
            "div",
            {
              staticClass: "option_list",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/gameList")
                },
              },
            },
            [_c("span", [_vm._v("JOGOS INTERNOS")])]
          ),
          _c("div", { staticClass: "option_list" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    _vm.openNext === 1 ? (_vm.openNext = 0) : (_vm.openNext = 1)
                  },
                },
              },
              [_vm._v("SLOTS")]
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.openNext === 1,
                  expression: "openNext === 1",
                },
              ],
              staticClass: "next-level",
            },
            [
              _c("div", { staticClass: "bg" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/gameList/slots")
                      },
                    },
                  },
                  [_vm._v("Pragmatic Play")]
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/gameList/pgGame")
                      },
                    },
                  },
                  [_vm._v("Pocket Games")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "option_list",
              on: {
                click: function ($event) {
                  return _vm.$router.replace("/gameList/ppl")
                },
              },
            },
            [_c("span", [_vm._v("LIVE CASINO")])]
          ),
          _c("div", { staticClass: "option_list" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    _vm.openNext === 2 ? (_vm.openNext = 0) : (_vm.openNext = 2)
                  },
                },
              },
              [_vm._v("ABOUT REIS JOGO")]
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.openNext === 2,
                  expression: "openNext === 2",
                },
              ],
              staticClass: "next-level",
            },
            [
              _c("div", { staticClass: "bg" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/privacyPolicy")
                      },
                    },
                  },
                  [_vm._v("Política de Privacidade")]
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/termsConditions")
                      },
                    },
                  },
                  [_vm._v("Termos e Condições")]
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "option_list" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    _vm.openNext === 3 ? (_vm.openNext = 0) : (_vm.openNext = 3)
                  },
                },
              },
              [_vm._v("VIP CENTER")]
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.openNext === 3,
                  expression: "openNext === 3",
                },
              ],
              staticClass: "next-level",
            },
            [
              _c("div", { staticClass: "bg" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/vip")
                      },
                    },
                  },
                  [_vm._v("My VIP")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "option_list",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/bonus")
                },
              },
            },
            [_c("span", [_vm._v("BONUS")])]
          ),
          _c(
            "div",
            {
              staticClass: "option_list",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/kyc")
                },
              },
            },
            [_c("span", [_vm._v("KYC POLICY")])]
          ),
          _c(
            "div",
            {
              staticClass: "option_list",
              on: {
                click: function ($event) {
                  _vm.isShowFAQ = true
                },
              },
            },
            [_c("span", [_vm._v("FAQ")])]
          ),
        ]),
        _vm._m(0),
        _c("h6", { staticStyle: { "font-size": "26px" } }, [_vm._v("LICENÇA")]),
        _c("p", { staticClass: "license_txt" }, [
          _vm._v(
            " rico13.com é operada pela PopBet LTD, número de registro da empresa 10127, com endereço registrado em Zuikertuintjeweg Z/N (Zuikertuin Tower) Curaçao e é licenciada e autorizada pelo governo de Curaçao. RICO13 opera sob a Master License of Gaming Services Provider, N.V. Número da Licença: GLH-OCCHKTW0701041288 O ganho final não é garantido. "
          ),
        ]),
        _vm._m(1),
        _c("div", { staticClass: "copyright_txt" }, [
          _vm._v("Copyright © 2019-2023 Reisjogo. All rights reserved."),
        ]),
      ]),
      _vm.isShowFAQ
        ? _c(
            "div",
            { staticClass: "rechargePopMask" },
            [
              _c("FAQ", {
                attrs: { showState: _vm.isShowFAQ },
                on: { closeFAQ: () => (_vm.isShowFAQ = false) },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "details" }, [
      _c("img", {
        staticClass: "pic1",
        attrs: { src: require("../../public/image/home/pic_1.png"), alt: "" },
      }),
      _c("img", {
        staticClass: "pic2",
        attrs: { src: require("../../public/image/home/pic_2.png"), alt: "" },
      }),
      _c("img", {
        staticClass: "pic3",
        attrs: { src: require("../../public/image/home/pic_3.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "details_txt" }, [
      _vm._v(" Este site oferece uma experiência de jogo com riscos."),
      _c("br"),
      _vm._v(
        " Para ser um usuário do nosso site,você deve ter mais de 18 anos."
      ),
      _c("br"),
      _vm._v(
        " Não nos responsabilizamos pela violação das leis locais relacionadas a jogos."
      ),
      _c("br"),
      _vm._v(" Jogue de forma responsável e divirta-se no Reisjogo. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }