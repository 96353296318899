<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";
import {getGamePlayUrl} from "@/api/api";

export default defineComponent({
  name: "GeneralPromptTem1",
  data() {
    return {
      gameId: '',
      cash: '',
      show: false,
    }
  },
  computed: {
    ...mapState(['notAllowGame'])
  },
  watch: {
    notAllowGame(val) {
      if (val) {
        this.show = true;
      }
    },
    show(val) {
      if (!val) {
        this.$store.commit('setNotAllowGame', val);
      }
    }
  },
  methods: {
    queryGameUrl() {
      getGamePlayUrl({
        gameId: this.gameId,
        isTail: false
      }).then(res => {
        this.gameUrl = res.url;
      }).catch(() => {
      })
    },
    doPlay() {
      if (!this.gameUrl) {
        this.show = false;
        return;
      }
      this.$router.push({ name: 'play', params: { url: this.gameUrl }});
      this.show = false;
    },
  }
})
</script>

<template>
  <div>
    <van-popup v-model="show" closeable class="confirm-block">
      <div class="game-prompt">
        <div class="game-prompt-title"></div>
        <p>Você precisa depositar pelo menos</p>
        <p class="game-prompt-cash">R$ 10 </p>
        <p>para acessar este jogo</p>
        <div class="game-prompt-ok" @click="show = false;$router.push('/recharge')">ok</div>
      </div>
    </van-popup>
  </div>
</template>

<style scoped lang="less">
.confirm-block {
  width: 80%;
  height: 660px;
  background-color: #FFFFFF;
  border-radius: 20px;
}
.game-prompt {
  width: 100%;

  p {
    font-size: 34px;
    text-align: center;
    font-weight: 600;
    margin: 40px 0;
    padding: 0 40px;
  }
}
.game-prompt-title {
  height: 90px;
  background-color: #F82E48;
}
.game-prompt-cash {
  font-size: 60px !important;
  color: #008600;
  margin: 20px 0;
}
.game-prompt-ok {
  position: absolute;
  bottom: 50px;
  left: 40px;
  width: 500px;
  background-color: #F82E48;
  color: #FFFFFF;
  border-radius: 14px;
  padding: 16px;
  text-align: center;
  font-size: 40px;
}
</style>