var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "brief-menu" }, [
      _c(
        "div",
        {
          staticClass: "bonus",
          on: {
            click: function ($event) {
              return _vm.$router.push("/bonus")
            },
          },
        },
        [
          _c("p", [_vm._v("BONUS")]),
          _c("img", {
            staticClass: "menuCoins1",
            attrs: {
              src: require("../../../public/image/home/menuCoins1.png"),
              alt: "",
            },
          }),
          _c("img", {
            staticClass: "menuCoins2",
            attrs: {
              src: require("../../../public/image/home/menuCoins2.png"),
              alt: "",
            },
          }),
          _c("img", {
            staticClass: "menuCoins3",
            attrs: {
              src: require("../../../public/image/home/menuCoins3.png"),
              alt: "",
            },
          }),
          _c("img", {
            staticClass: "menuCoins4",
            attrs: {
              src: require("../../../public/image/home/menuCoins4.png"),
              alt: "",
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "convite",
          on: {
            click: function ($event) {
              return _vm.$router.push("/share")
            },
          },
        },
        [
          _c("p", [_vm._v("CONVITE")]),
          _c("img", {
            staticClass: "gifts",
            attrs: {
              src: require("../../../public/image/home/gifts.png"),
              alt: "",
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }