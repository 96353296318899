var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-ads" }, [
    _c("div", { staticClass: "txt1" }, [
      _vm._v("Junte-se ao jogo reis , Para obter"),
    ]),
    _vm._m(0),
    _vm._m(1),
    _c("div", { staticClass: "btnBox" }, [
      _c("button", { on: { click: _vm.sign_up } }, [_vm._v("Criar conta")]),
      _vm._m(2),
      _c("img", {
        staticClass: "gifts",
        attrs: {
          src: require("../../public/image/home/titile_gifts_pic.png"),
          alt: "",
        },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "txt2" }, [
      _c("div", [_vm._v("R$388.888")]),
      _c("div", [_vm._v("grátis")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "txt3" }, [
      _vm._v(" Ao convidar um usuário válido, você pode ganhar pelo menos "),
      _c("span", { staticStyle: { color: "#FAD343" } }, [_vm._v("R$12")]),
      _c("br"),
      _vm._v(" CashBack de até "),
      _c("span", { staticStyle: { color: "gold" } }, [_vm._v("25%")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _vm._v("Receba um bônus de depósito de até "),
      _c("span", [_vm._v("200%")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }