var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSelf
    ? _c(
        "div",
        { staticClass: "dialog", style: { "z-index": _vm.zIndex } },
        [
          _c("div", {
            staticClass: "dialog-mark",
            style: { "z-index": _vm.zIndex + 1 },
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.closeMyself.apply(null, arguments)
              },
            },
          }),
          _c("transition", { attrs: { name: "dialog" } }, [
            _c(
              "div",
              {
                staticClass: "dialog-sprite",
                style: { "z-index": _vm.zIndex + 2 },
              },
              [
                _vm.title
                  ? _c(
                      "section",
                      {
                        staticClass: "header",
                        style: { "z-index": _vm.zIndex + 3 },
                      },
                      [_vm._v(_vm._s(_vm.title))]
                    )
                  : _vm._e(),
                _c(
                  "section",
                  {
                    staticClass: "dialog-body",
                    style: { "z-index": _vm.zIndex + 3 },
                  },
                  [_vm._t("default")],
                  2
                ),
                _c(
                  "section",
                  {
                    staticClass: "dialog-footer",
                    style: { "z-index": _vm.zIndex + 3 },
                  },
                  [
                    _vm.showCancel
                      ? _c(
                          "div",
                          {
                            staticClass: "btn btn-refuse",
                            on: { click: _vm.cancel },
                          },
                          [_vm._v(_vm._s(_vm.cancelText))]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "btn btn-confirm",
                        on: { click: _vm.confirm },
                      },
                      [_vm._v(_vm._s(_vm.confirmText))]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }