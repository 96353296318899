var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "selector",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _setup.penSelectBlock.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "input-block" }, [
          _c("div", { staticClass: "input-context" }, [
            _c("span", [
              _vm._v(
                _vm._s(_setup.getCurrentGame(_setup.currentGameType, "title"))
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "arrow",
              class: _setup.isOpen ? "arrow-down" : "arrow-up",
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../../public/image/personal/selectIcon.png"),
                  alt: "",
                },
              }),
            ]
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _setup.isOpen,
                expression: "isOpen",
              },
            ],
            staticClass: "option-block",
          },
          _vm._l(_setup.gameArray, function (item) {
            return _c(
              "div",
              {
                staticClass: "option-item",
                class:
                  _setup.getCurrentGame(_setup.currentGameType, "id") ===
                  item.id
                    ? "option-item-selected"
                    : "",
                on: {
                  click: function ($event) {
                    return _setup.selectGames(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "input-context" }, [
                  _c("span", [_vm._v(_vm._s(item.title))]),
                ]),
              ]
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }