var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showState
    ? _c("div", { staticClass: "faq" }, [
        _c("img", {
          staticClass: "faq-close",
          attrs: {
            src: require("../../public/image/message/close.png"),
            alt: "",
          },
          on: { click: _vm.closeFAQ },
        }),
        _c("div", { staticClass: "faq-context" }, [
          _c("div", { staticClass: "faq-title" }, [_vm._v("FAQ")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 0,
                  expression: "currentTab === 0",
                },
              ],
              staticClass: "faq-text",
            },
            [
              _c("p", [_vm._v(" Cada depósito requer uma aposta de 1x. ")]),
              _c("p", [
                _vm._v(
                  " e se você participar de uma promoção de depósito, será necessário cumprir requisitos adicionais de aposta para o bônus concedido. "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " Se você tiver várias tarefas de depósito, todas elas serão combinadas, e você deve completar as apostas exigidas antes de poder sacar. "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " Se seu saldo não for suficiente para continuar jogando, suas tarefas de aposta serão automaticamente canceladas. "
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 1,
                  expression: "currentTab === 1",
                },
              ],
              staticClass: "faq-text",
            },
            [
              _c("p", [
                _vm._v(
                  " Aqui estão incluídos os requisitos básicos de apostas de depósito e as tarefas de apostas de bônus em andamento (incluindo as concluídas). "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " As tarefas básicas de apostas de depósito só podem ser realizadas simultaneamente com a primeira tarefa de bônus acionada, não sendo possível realizar várias tarefas ao mesmo tempo. "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " Se a tarefa de apostas de bônus for concluída, a próxima tarefa será iniciada automaticamente. "
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "faq-title" }, [
            _vm._v("O Método de Cálculo"),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 0,
                  expression: "currentTab === 0",
                },
              ],
              staticClass: "faq-text",
            },
            [
              _vm._m(0),
              _vm._m(1),
              _vm._m(2),
              _vm._m(3),
              _vm._m(4),
              _vm._m(5),
              _vm._m(6),
              _vm._m(7),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 1,
                  expression: "currentTab === 1",
                },
              ],
              staticClass: "faq-text",
            },
            [
              _vm._m(8),
              _vm._m(9),
              _vm._m(10),
              _vm._m(11),
              _vm._m(12),
              _vm._m(13),
              _vm._m(14),
              _vm._m(15),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-head" }, [
      _c("div", [_vm._v("Apostas")]),
      _c("div", [_vm._v("Ganhou")]),
      _c("div", [_vm._v("Aposta")]),
      _c("div", [_vm._v("Descrição")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("40.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Derrotada")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("80.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Derrotada")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Empatadas")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("120.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Vitórias")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("160.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Vitórias")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("200.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Vitórias")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("80000.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Vitórias")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-head" }, [
      _c("div", [_vm._v("Apostas")]),
      _c("div", [_vm._v("Ganhou")]),
      _c("div", [_vm._v("Aposta válido")]),
      _c("div", [_vm._v("Descrição")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("40.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Derrotada")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("80.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Derrotada")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Empatadas")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("120.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Vitórias")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("160.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Vitórias")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("200.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Vitórias")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq-table-tr" }, [
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("80000.00")]),
      _c("div", [_vm._v("100.00")]),
      _c("div", [_vm._v("Vitórias")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }