var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          style: { height: "20%", background: "#1d1d1d" },
          attrs: { position: "top" },
          model: {
            value: _vm.isShow,
            callback: function ($$v) {
              _vm.isShow = $$v
            },
            expression: "isShow",
          },
        },
        [
          _c("div", { staticClass: "game-search" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.text,
                  expression: "text",
                },
              ],
              staticClass: "search-input",
              domProps: { value: _vm.text },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.text = $event.target.value
                },
              },
            }),
            _c(
              "div",
              { staticClass: "game-search-icon" },
              [
                _c("van-icon", {
                  attrs: { name: "search", size: "20", color: "#737373" },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "search-btn-block" }, [
            _c(
              "button",
              {
                staticClass: "close-btn",
                on: {
                  click: function ($event) {
                    _vm.isShow = false
                  },
                },
              },
              [_vm._v("Voltar")]
            ),
            _c(
              "button",
              {
                staticClass: "search-btn",
                class: _vm.text ? "" : "disabled",
                attrs: { disabled: !_vm.text },
                on: { click: _vm.searchGameHandler },
              },
              [_vm._v("Buscar ")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }