var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "selector",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _setup.penSelectBlock.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "input-block" }, [
          _c("div", { staticClass: "icon" }, [
            _c("img", {
              attrs: {
                src: _setup.getCurrentGame(_setup.currentGameType, "icon"),
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "input-context" }, [
            _c("span", [
              _vm._v(
                _vm._s(_setup.getCurrentGame(_setup.currentGameType, "name"))
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "arrow",
              class: _setup.isOpen ? "arrow-down" : "arrow-up",
            },
            [_c("van-icon", { attrs: { name: "arrow-down" } })],
            1
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _setup.isOpen,
                expression: "isOpen",
              },
            ],
            staticClass: "option-block",
          },
          _vm._l(_setup.gameArray, function (item) {
            return _c(
              "div",
              {
                staticClass: "option-item",
                class:
                  _setup.getCurrentGame(_setup.currentGameType, "id") ===
                  item.id
                    ? "option-item-selected"
                    : "",
                on: {
                  click: function ($event) {
                    return _setup.selectGames(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "icon" }, [
                  _c("img", {
                    class: "img" + item.id,
                    attrs: { src: item.icon, alt: "" },
                  }),
                ]),
                _c("div", { staticClass: "input-context" }, [
                  _c("span", [_vm._v(_vm._s(item.name))]),
                ]),
              ]
            )
          }),
          0
        ),
      ]
    ),
    _setup.props.search
      ? _c("div", { staticClass: "game-search" }, [
          _c(
            "div",
            { staticClass: "game-search-icon" },
            [_c("van-icon", { attrs: { name: "search", size: "20" } })],
            1
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _setup.searchText,
                expression: "searchText",
              },
            ],
            staticClass: "search",
            attrs: { placeholder: "Pesquisar o jogo" },
            domProps: { value: _setup.searchText },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _setup.searchText = $event.target.value
                },
                _setup.searchGameHandler,
              ],
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }