import store from "@/store/index";
import {getUserInfo} from "@/api/api";

const actions = {
    UserInfo() {
        const token = localStorage.getItem('token');
        if (token) {
            store.commit('setToken', token);
            getUserInfo({
                token: token,
                client: store.state.client
            }).then(res => {
                localStorage.setItem('userData', res ? JSON.stringify(res) : '')
                store.commit('USERDATA', res);
            })
        }
    },
    onLoading(context, isLoading) {
        context.commit('setLoading', isLoading);
    },
    toast(context, text) {
        let toastObj = {
            show: true,
            text: text
        };
        if (typeof text === 'string') {
            toastObj.type = 'success';
        } else {
            toastObj.show = text.show;
            toastObj.type = text.type;
            toastObj.text = text.text;
        }
        context.commit('setToast', toastObj);
    }
}
export default actions
