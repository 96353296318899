var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.redBagId !== 0
      ? _c("div", { staticClass: "mask" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOldShow,
                  expression: "isOldShow",
                },
              ],
              staticClass: "redback",
              class: _vm.isOldShow ? "" : "hidebag",
            },
            [
              _vm._m(0),
              _c("div", { staticClass: "btnbox" }, [
                _c("div", { staticClass: "btns", on: { click: _vm.openBag } }, [
                  _vm._v("Receber"),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.OpenBag === 1,
                  expression: "OpenBag === 1",
                },
              ],
              staticClass: "openRedBag1 showbag",
            },
            [
              _c("div", { staticClass: "lightbox" }, [
                _c("img", {
                  staticClass: "opentitle",
                  attrs: {
                    src: _vm.IMAGE_URL + "cashprize/redbag-title.png",
                    alt: "",
                  },
                }),
              ]),
              _c("div", { staticClass: "text" }, [
                _vm._v(" Parabéns!"),
                _c("br"),
                _vm._v(" Você ganhou um bônus de "),
                _c("span", [_vm._v("R$" + _vm._s(_vm.amount))]),
              ]),
              _c("div", { staticClass: "btnbox" }, [
                _c("div", { staticClass: "btns", on: { click: _vm.close } }, [
                  _vm._v("Receber"),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.OpenBag === 2,
                  expression: "OpenBag === 2",
                },
              ],
              staticClass: "openRedBag2",
            },
            [
              _c("img", {
                staticClass: "opentitle2",
                attrs: {
                  src: _vm.IMAGE_URL + "cashprize/redbag-title.png",
                  alt: "",
                },
              }),
              _c("div", { staticClass: "text" }, [
                _vm._v(" Parabéns pelo desconto na recarga!"),
                _c("br"),
                _vm._v(" Recarregue "),
                _c("span", [_vm._v("R$" + _vm._s(_vm.needRechargeMoney))]),
                _c("br"),
                _vm._v(" e ganhe "),
                _c("span", [_vm._v("R$" + _vm._s(_vm.getExtraMoney))]),
                _vm._v(" de bônus adicional. "),
              ]),
              _c("div", { staticClass: "btnbox" }, [
                _c(
                  "div",
                  { staticClass: "btns", on: { click: _vm.recharge } },
                  [_vm._v("Recarregar")]
                ),
              ]),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _vm._v(" Parabéns!"),
      _c("br"),
      _vm._v(" Você recebeu uma recompensa surpresa! "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }