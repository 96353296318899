var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isShow
    ? _c(
        "div",
        { staticClass: "active-mask-block" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow,
                  expression: "isShow",
                },
              ],
              staticClass: "active-block",
            },
            [
              _c("div", { staticClass: "active-close-block" }, [
                _c("img", {
                  attrs: {
                    src: require("../../public/image/login/close.png"),
                    alt: "close",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.closeSelf.apply(null, arguments)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "active-name-block" }, [
                _vm._v("Recompensa diária"),
              ]),
              _c(
                "div",
                { staticClass: "sign-context" },
                _vm._l(_vm.signConfig, function (item, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "sign-context-block",
                      class: {
                        "sign-unclaimed":
                          _vm.currentSign > index ||
                          (_vm.currentSign === index &&
                            _vm.isSignToday === false),
                        "sign-received": _vm.currentSign <= index,
                        "sign-active":
                          _vm.currentSign === index &&
                          _vm.isSignToday === false,
                        "sign-over": _vm.currentSign > index,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.reserved(index)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "sign-day" }, [
                        _vm._v(" DAY" + _vm._s(index + 1) + " "),
                      ]),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentSign <= index,
                            expression: "currentSign <= index",
                          },
                        ],
                        attrs: {
                          src: require("../../public/image/active/unclaimed-coin.png"),
                          alt: "",
                        },
                      }),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentSign > index,
                            expression: "currentSign > index",
                          },
                        ],
                        staticStyle: { margin: "6px" },
                        attrs: {
                          src: require("../../public/image/active/received-coin.png"),
                          alt: "",
                        },
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.pending && _vm.currentSign === index,
                              expression: "pending && currentSign === index",
                            },
                          ],
                          staticClass: "sign-loading",
                        },
                        [
                          _c("van-loading", {
                            attrs: {
                              type: "spinner",
                              size: "40",
                              color: "#000",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "sign-val" }, [
                        _vm._v("R$ " + _vm._s(item)),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticClass: "sign-context-block-7",
                  class: {
                    "sign-active":
                      _vm.currentSign === 6 && _vm.isSignToday === false,
                    "sign-unclaimed-7":
                      _vm.currentSign > 6 ||
                      (_vm.currentSign === 6 && _vm.isSignToday === false),
                    "sign-received": _vm.currentSign <= 6,
                    "sign-over": _vm.currentSign > 6,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.reserved(6)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "sign-day" }, [_vm._v(" DAY7 ")]),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentSign <= 6,
                        expression: "currentSign <= 6",
                      },
                    ],
                    attrs: {
                      src: require("../../public/image/active/unclaimed-coin.png"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentSign > 6,
                        expression: "currentSign > 6",
                      },
                    ],
                    staticStyle: { margin: "6px" },
                    attrs: {
                      src: require("../../public/image/active/received-coin.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "sign-val",
                      staticStyle: { "padding-top": "30px" },
                    },
                    [_vm._v("R$ " + _vm._s(_vm.signConfig7))]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.pending && _vm.currentSign === 6,
                          expression: "pending && currentSign === 6",
                        },
                      ],
                      staticClass: "sign-loading",
                    },
                    [
                      _c("van-loading", {
                        attrs: { type: "spinner", size: "40", color: "#000" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "active-text-block" }, [
                _c("p", [
                  _vm._v(
                    " Torne-se VIP, faça login diariamente para receber recompensas, o bônus requer " +
                      _vm._s(_vm.betMul) +
                      " vezes o valor em apostas antes de poder ser retirado. "
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "van-popup",
            {
              staticClass: "confirm-block",
              model: {
                value: _vm.showVipLimit,
                callback: function ($$v) {
                  _vm.showVipLimit = $$v
                },
                expression: "showVipLimit",
              },
            },
            [
              _c(
                "div",
                { staticClass: "game-prompt" },
                [
                  _c("div", {
                    staticClass: "game-prompt-title",
                    staticStyle: { margin: "0" },
                  }),
                  _c("van-icon", {
                    staticClass: "tips-close",
                    attrs: { name: "cross", size: "24", color: "#fff" },
                    on: {
                      click: function ($event) {
                        _vm.showVipLimit = false
                      },
                    },
                  }),
                  _c("p", [_vm._v("Deposite")]),
                  _c("p", { staticClass: "game-prompt-cash" }, [
                    _vm._v("R$ " + _vm._s(_vm.config.vip[1].need_bets)),
                  ]),
                  _c("div", { staticClass: "vip-limit-text" }, [
                    _c("p", [
                      _vm._v("para se tornar "),
                      _c(
                        "span",
                        { staticStyle: { "font-size": "26px", color: "red" } },
                        [_vm._v("VIP")]
                      ),
                    ]),
                    _c("p", [
                      _vm._v("e receber um "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "26px",
                            color: "#FF8A00",
                          },
                        },
                        [_vm._v("Bônus")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vip-limit-btn" }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            _vm.showVipLimit = false
                          },
                        },
                      },
                      [_vm._v("Cancelar")]
                    ),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            _vm.$router.push("/recharge")
                            _vm.showVipLimit = false
                            _vm.$store.commit("setSignInByDay", false)
                          },
                        },
                      },
                      [_vm._v("Tornar-se VIP")]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }