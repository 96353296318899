var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outBox" }, [
    _c("div", { staticClass: "hover-icon" }, [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.cheerIconShow,
            expression: "cheerIconShow",
          },
        ],
        attrs: {
          src: require("../../../public/image/common/cheerGift.webp"),
          alt: "",
        },
        on: { click: _vm.opencheerPop },
      }),
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.speedIconShow,
            expression: "speedIconShow",
          },
        ],
        attrs: {
          src: require("../../../public/image/common/speedUpGift.png"),
          alt: "",
        },
        on: { click: _vm.openspeedUpPop },
      }),
    ]),
    _c("div", { staticClass: "cheerBox" }, [
      _vm.isCheerShow
        ? _c("div", { staticClass: "active-mask-block" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCheerShow,
                    expression: "isCheerShow",
                  },
                ],
                staticClass: "active-block",
              },
              [
                _c("div", { staticClass: "active-close-block" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../public/image/recharge/close.png"),
                      alt: "close",
                    },
                    on: { click: _vm.closeCheerPop },
                  }),
                ]),
                _c("div", { staticClass: "active-context" }, [
                  _c("div", { staticClass: "active-img" }, [
                    _c("div", { staticClass: "superTxt" }, [
                      _vm._v("Super valor 100%"),
                    ]),
                    _c("div", { staticClass: "timebox" }, [
                      _c("p", { staticClass: "tmeName" }, [
                        _vm._v("Disponível em"),
                      ]),
                      _c(
                        "p",
                        { staticClass: "clock" },
                        [
                          _c("van-count-down", {
                            attrs: {
                              time:
                                _vm.cheerJson.expired_at - new Date().getTime(),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "btns",
                        on: {
                          click: function ($event) {
                            return _vm.toBuy(_vm.cheerJson.id)
                          },
                        },
                      },
                      [
                        _c("p", { staticClass: "need" }, [
                          _vm._v(
                            "Preço especial: R$ " +
                              _vm._s(_vm.cheerJson.need_recharge)
                          ),
                        ]),
                        _c("p", { staticClass: "receive" }, [
                          _vm._v(
                            "Preço original : R$ " +
                              _vm._s(
                                parseInt(_vm.cheerJson.need_recharge) +
                                  parseInt(_vm.cheerJson.bonus)
                              )
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "records" }, [
                    _c("div", { staticClass: "textBox" }, [
                      _c(
                        "div",
                        {
                          class:
                            _vm.nameArr.length > 2
                              ? "inner-container"
                              : "flowText",
                        },
                        _vm._l(_vm.nameArr, function (item, index) {
                          return _c("p", {
                            key: index,
                            staticClass: "text",
                            domProps: { innerHTML: _vm._s(item.txt1) },
                          })
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "speedBox" }, [
      _vm.isSpeedShow
        ? _c("div", { staticClass: "active-mask-block" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isSpeedShow,
                    expression: "isSpeedShow",
                  },
                ],
                staticClass: "active-block",
              },
              [
                _c("div", { staticClass: "active-close-block" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../public/image/recharge/close.png"),
                      alt: "close",
                    },
                    on: { click: _vm.closeSpeedUpPop },
                  }),
                ]),
                _c("div", { staticClass: "active-context" }, [
                  _c("img", {
                    staticClass: "active-title",
                    attrs: {
                      src: require("../../../public/image/recharge/title.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "imgBox" }, [
                    _c("div", { staticClass: "timebox" }, [
                      _c("p", { staticClass: "tmeName" }, [
                        _vm._v("TEMPO ESTIMADO PARA RETIRADA"),
                      ]),
                      _c(
                        "p",
                        { staticClass: "clock" },
                        [
                          _c("van-count-down", {
                            attrs: {
                              time:
                                _vm.speedJson.expired_at - new Date().getTime(),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "active-img" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btns",
                          on: {
                            click: function ($event) {
                              return _vm.toBuy(_vm.speedJson.id)
                            },
                          },
                        },
                        [
                          _c("p", [
                            _vm._v("Deposite "),
                            _c("span", { staticClass: "darkGreen" }, [
                              _vm._v(
                                "R$" + _vm._s(_vm.speedJson.need_recharge)
                              ),
                            ]),
                            _vm._v(" para acelerar imediatamente "),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "records" }, [
                      _c("div", { staticClass: "textBox" }, [
                        _c(
                          "div",
                          {
                            class:
                              _vm.nameArr.length > 2
                                ? "inner-container"
                                : "flowText",
                          },
                          _vm._l(_vm.nameArr, function (item, index) {
                            return _c("p", {
                              key: index,
                              staticClass: "text",
                              domProps: { innerHTML: _vm._s(item.txt2) },
                            })
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }