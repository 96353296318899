var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.client.indexOf("ios") === -1 &&
    _vm.client.indexOf("Android") === -1
    ? _c("div", [_vm._m(0), _vm._m(1)])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "domain-box" }, [
      _c("hr", { staticClass: "line1" }),
      _c("h4", { staticClass: "title" }, [_vm._v("OR LOGIN DIRECTLY WITH")]),
      _c("hr", { staticClass: "line2" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "otherApp" }, [
      _c("img", {
        staticClass: "appIcon",
        attrs: { src: require("../../public/image/home/fb.png"), alt: "" },
      }),
      _c("img", {
        staticClass: "appIcon",
        attrs: {
          src: require("../../public/image/home/whatsApp.png"),
          alt: "",
        },
      }),
      _c("img", {
        staticClass: "appIcon",
        attrs: { src: require("../../public/image/home/tg.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }