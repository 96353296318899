var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.downLoadTips
        ? _c(
            "div",
            {
              staticClass: "app-download-btn",
              attrs: { id: "application_download_button" },
            },
            [
              _c("van-icon", {
                staticClass: "download-close",
                attrs: { name: "close", size: "30", color: "#fff" },
                on: { click: _vm.closeDownAppTips },
              }),
              _c("img", {
                attrs: {
                  src: require("../../public/image/downapp/down-app-back.png"),
                  alt: "",
                },
                on: { click: _vm.downLoadAPP },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isShowDownLoadTips
        ? _c("div", { staticClass: "download-tips-mask" }, [
            _c("div", { staticClass: "download-tips-block" }, [
              _c("div", { staticClass: "rank-close" }, [
                _c("img", {
                  staticClass: "close_pics",
                  attrs: {
                    src: require("../../public/image/login/close.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.isShowDownLoadTips = false
                    },
                  },
                }),
              ]),
              _c("p", { staticClass: "title" }, [
                _vm._v(" Acesso rápido à ReisJogo em seu iPhone "),
              ]),
              _c("p", [
                _vm._v(
                  " Você pode adicionar um ícone à Tela de Início do iPhone para acessar ReisJogo. "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " No site, toque em botão Compartilhar e toque em “Adicionar à Tela de Início”. "
                ),
              ]),
              _c("img", {
                staticStyle: { width: "100%", "margin-top": "20px" },
                attrs: {
                  src: require("../../public/image/headerImg/download-ios.png"),
                  alt: "",
                },
              }),
              _c("p", [
                _vm._v(
                  " No site, toque em botão Compartilhar e toque em “Adicionar à Tela de Início”. "
                ),
              ]),
              _c(
                "div",
                { staticClass: "download-check" },
                [
                  _c(
                    "van-checkbox",
                    {
                      attrs: { shape: "square" },
                      on: { change: _vm.changeDownLoadTips },
                      model: {
                        value: _vm.downLoadTips,
                        callback: function ($$v) {
                          _vm.downLoadTips = $$v
                        },
                        expression: "downLoadTips",
                      },
                    },
                    [_vm._v("Nunca mais mostre")]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "van-popup",
        {
          staticClass: "close-ios-tips-block",
          attrs: { position: "bottom" },
          model: {
            value: _vm.closeIosTipsBlock,
            callback: function ($$v) {
              _vm.closeIosTipsBlock = $$v
            },
            expression: "closeIosTipsBlock",
          },
        },
        [
          _c("div", { staticClass: "download-ios-context" }, [
            _c("img", {
              attrs: {
                src: require("../../public/image/downapp/down-app-ios-icon.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "download-ios-text" }, [
              _c("p", [_vm._v("Adicionar à Tela Principal")]),
              _c("p", [
                _vm._v(
                  "Adicione nosso ícone na tela inicial para jogar rápido! Prêmios todos os dias! Dúvidas? Fale com o SAC."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "download-ios-btn-block" }, [
            _c(
              "div",
              {
                staticClass: "download-ios-btn",
                on: { click: _vm.closeDownAppIos },
              },
              [_vm._v("Cancelar")]
            ),
            _c(
              "div",
              { staticClass: "download-ios-btn", on: { click: _vm.iosTips } },
              [_vm._v("continuar")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }