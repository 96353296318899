var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          staticClass: "recharge-active",
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("van-icon", {
            staticClass: "recharge-active-close",
            attrs: { name: "cross", color: "#fff", size: "22" },
            on: { click: _vm.close },
          }),
          !_vm.token
            ? _c("div", {
                staticClass: "recharge-active-not-login",
                on: { click: _vm.toLogin },
              })
            : _vm._e(),
          _vm.token
            ? _c("div", {
                staticClass: "recharge-active",
                on: { click: _vm.toRecharge },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }