var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      staticClass: "active-mask-block",
    },
    [
      _c(
        "div",
        { staticClass: "operate-block" },
        [
          _c("van-icon", {
            staticStyle: { "margin-bottom": "22px" },
            attrs: { name: "clear", color: "#FFF" },
            on: { click: _vm.close },
          }),
          _c(
            "van-popover",
            {
              staticStyle: { "z-index": "20" },
              attrs: {
                placement: "left-start",
                "z-index": "100",
                trigger: "click",
              },
              scopedSlots: _vm._u([
                {
                  key: "reference",
                  fn: function () {
                    return [
                      _c("van-icon", {
                        attrs: { name: "question", color: "#FFF" },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.showPopover,
                callback: function ($$v) {
                  _vm.showPopover = $$v
                },
                expression: "showPopover",
              },
            },
            [
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "16px",
                    padding: "10px",
                    "z-index": "10",
                  },
                },
                [
                  _vm._v("Torne-se VIP, ganhe rodadas grátis "),
                  _c("br"),
                  _vm._v("diárias, o bônus requer apostas de 1x."),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.tigerOver,
              expression: "!tigerOver",
            },
          ],
          staticClass: "active-block",
        },
        [
          _c(
            "div",
            { staticClass: "tiger-machine" },
            [
              _c("p", { staticClass: "tiger-time" }, [
                _vm._v("Restam "),
                _c("span", { staticStyle: { color: "#2BB31B" } }, [
                  _vm._v(_vm._s(_vm.times)),
                ]),
                _vm._v(" tentativas de sorteio."),
              ]),
              _c("img", {
                attrs: {
                  src: require("../../public/image/active/tiger-machine.png"),
                  alt: "",
                },
              }),
              _c("slot-machine", {
                ref: "machine",
                staticClass: "slot-tiger-machine",
                attrs: { colCount: 3, moveTime: 2, list: _vm.slots },
                on: { onend: _vm.slotFinish },
              }),
              _c("div", {
                staticClass: "tiger-machine-btn",
                on: { click: _vm.slotGo },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tigerOver,
              expression: "tigerOver",
            },
          ],
          staticClass: "tiger-over-block",
        },
        [
          _c("div", { staticClass: "tiger-times-over" }, [
            _c("img", {
              attrs: {
                src: _vm.IMAGE_URL + "cashprize/tiger-title.png",
                alt: "",
              },
            }),
            _c("div", { staticClass: "tiger-over-text" }, [
              _vm._v(" Parabéns!"),
              _c("br"),
              _vm._v(" Você recebeu uma oferta de recarga! Recarregue agora "),
              _c("span", { staticStyle: { color: "#2BB31B" } }, [
                _vm._v("R$ " + _vm._s(_vm.needRechargeMoney)),
              ]),
              _vm._v(" e ganhe um bônus adicional de "),
              _c("span", { staticStyle: { color: "#2BB31B" } }, [
                _vm._v("R$ " + _vm._s(_vm.getExtraMoney)),
              ]),
              _vm._v("! "),
            ]),
            _c(
              "div",
              { staticClass: "tiger-over-btn", on: { click: _vm.recharge } },
              [_vm._v(" Recarregar ")]
            ),
          ]),
        ]
      ),
      _c(
        "van-popup",
        {
          staticClass: "confirm-block",
          model: {
            value: _vm.tigerResult,
            callback: function ($$v) {
              _vm.tigerResult = $$v
            },
            expression: "tigerResult",
          },
        },
        [
          _c(
            "div",
            { staticClass: "game-prompt" },
            [
              _c("div", { staticClass: "game-prompt-title" }),
              _c("van-icon", {
                staticClass: "tips-close",
                attrs: { name: "cross", size: "24", color: "#fff" },
                on: {
                  click: function ($event) {
                    _vm.tigerResult = false
                  },
                },
              }),
              _c("p", [_vm._v("Parabéns, você ganhou")]),
              _c("p", { staticClass: "game-prompt-cash" }, [
                _vm._v("R$ " + _vm._s(_vm.getExtraMoney) + " !"),
              ]),
              _c(
                "div",
                {
                  staticClass: "game-prompt-ok",
                  on: {
                    click: function ($event) {
                      _vm.tigerResult = false
                    },
                  },
                },
                [_vm._v("ok")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          staticClass: "confirm-block",
          model: {
            value: _vm.showVipLimit,
            callback: function ($$v) {
              _vm.showVipLimit = $$v
            },
            expression: "showVipLimit",
          },
        },
        [
          _c(
            "div",
            { staticClass: "game-prompt" },
            [
              _c("div", {
                staticClass: "game-prompt-title",
                staticStyle: { margin: "0" },
              }),
              _c("van-icon", {
                staticClass: "tips-close",
                attrs: { name: "cross", size: "24", color: "#fff" },
                on: {
                  click: function ($event) {
                    _vm.showVipLimit = false
                  },
                },
              }),
              _c("p", [_vm._v("Deposite")]),
              _c("p", { staticClass: "game-prompt-cash" }, [
                _vm._v(
                  "R$ " + _vm._s(_vm.config.vip && _vm.config.vip[1]?.need_bets)
                ),
              ]),
              _c("div", { staticClass: "vip-limit-text" }, [
                _c("p", [
                  _vm._v("para se tornar "),
                  _c(
                    "span",
                    { staticStyle: { "font-size": "26px", color: "red" } },
                    [_vm._v("VIP")]
                  ),
                ]),
                _c("p", [
                  _vm._v("e receber um "),
                  _c(
                    "span",
                    { staticStyle: { "font-size": "26px", color: "#FF8A00" } },
                    [_vm._v("Bônus")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "vip-limit-btn" }, [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        _vm.showVipLimit = false
                      },
                    },
                  },
                  [_vm._v("Cancelar")]
                ),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        _vm.$router.push("/recharge")
                        _vm.showVipLimit = false
                        _vm.$store.commit("setSlotsTigerShow", false)
                      },
                    },
                  },
                  [_vm._v(" Tornar-se VIP")]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }