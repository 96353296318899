import { render, staticRenderFns } from "./NetWork.vue?vue&type=template&id=260c294e&scoped=true"
import script from "./NetWork.vue?vue&type=script&setup=true&lang=ts"
export * from "./NetWork.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./NetWork.vue?vue&type=style&index=0&id=260c294e&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "260c294e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\Work\\cwt_vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('260c294e')) {
      api.createRecord('260c294e', component.options)
    } else {
      api.reload('260c294e', component.options)
    }
    module.hot.accept("./NetWork.vue?vue&type=template&id=260c294e&scoped=true", function () {
      api.rerender('260c294e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/NetWork.vue"
export default component.exports