var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "jopos" },
    [
      _c(
        "div",
        { staticClass: "menuBox" },
        [
          _c(
            "van-tabs",
            {
              attrs: { "swipe-threshold": "1" },
              model: {
                value: _vm.tabActive,
                callback: function ($$v) {
                  _vm.tabActive = $$v
                },
                expression: "tabActive",
              },
            },
            _vm._l(_vm.menuList, function (item, keys) {
              return _c(
                "van-tab",
                {
                  key: keys,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("img", {
                              class: "img" + keys,
                              attrs: {
                                src:
                                  _vm.tabActive === keys
                                    ? item.imgActive
                                    : item.img,
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "itemName" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm.tabActive === 0
                    ? _c(
                        "div",
                        _vm._l(_vm.menuList, function (item) {
                          return _vm.activity === 0
                            ? _c("div", { staticClass: "contenArry" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "contenArry_top",
                                    attrs: { id: item.domName },
                                  },
                                  [
                                    _c("div", { staticClass: "content_left" }, [
                                      _c("img", {
                                        staticClass: "hotImg",
                                        attrs: { src: item.img, alt: "" },
                                      }),
                                      _c("span", [_vm._v(_vm._s(item.name))]),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-nav-block",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickAll(2)
                                          },
                                        },
                                      },
                                      [_vm._v(" Ver mais  > ")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "game-grid-3-block" },
                                  _vm._l(item.gameList, function (json, index) {
                                    return _c(
                                      "div",
                                      { key: index },
                                      [
                                        _c("GameCard", {
                                          attrs: {
                                            json: json,
                                            isHot: index <= 6,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.tabActive === 1
                    ? _c("div", { staticClass: "contenArry" }, [
                        _c(
                          "div",
                          {
                            staticClass: "contenArry_top",
                            attrs: { id: "pgGame" },
                          },
                          [
                            _c("div", { staticClass: "content_left" }, [
                              _c("img", {
                                staticClass: "pgImg",
                                attrs: {
                                  src: require("../../public/image/game/pg.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v("PG")]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content-nav-block",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickAll(6)
                                  },
                                },
                              },
                              [_vm._v(" Ver mais  > ")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "game-list-block" },
                          [
                            _c(
                              "van-swipe",
                              {
                                ref: "pgGameSwipe",
                                refInFor: true,
                                staticClass: "my-swipe",
                                attrs: {
                                  loop: false,
                                  "show-indicators": false,
                                },
                              },
                              _vm._l(
                                _vm.pgGameArrayList,
                                function (array, index) {
                                  return _c("van-swipe-item", { key: index }, [
                                    _c(
                                      "div",
                                      { staticClass: "game-list-page-block" },
                                      _vm._l(array, function (item) {
                                        return _c("div", [
                                          !item.more
                                            ? _c("img", {
                                                class:
                                                  _vm.playIndex ===
                                                  item.game_id + "pgGame"
                                                    ? "imageFilter"
                                                    : "",
                                                attrs: {
                                                  src: _vm.SplitGamePosterUrl(
                                                    item
                                                  ),
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.readyPlay(
                                                      item,
                                                      "pgGame"
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playLoading &&
                                                    _vm.ensureGameId ===
                                                      item.game_id + "pgGame",
                                                  expression:
                                                    "playLoading && ensureGameId === item.game_id + 'pgGame'",
                                                },
                                              ],
                                              staticClass: "game-play-loading",
                                            },
                                            [_c("van-loading")],
                                            1
                                          ),
                                          !item.more
                                            ? _c("img", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.playIndex ===
                                                      item.game_id + "pgGame",
                                                    expression:
                                                      "playIndex === item.game_id + 'pgGame'",
                                                  },
                                                ],
                                                staticClass: "play-btn",
                                                attrs: {
                                                  src: require("../../public/image/common/playBtn.png"),
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.doPlay("pgGame")
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          item.more
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("../../public/image/home/viewAllGame.png"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickAll(6)
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playIndex ===
                                                    item.game_id + "pgGame",
                                                  expression:
                                                    "playIndex === item.game_id + 'pgGame'",
                                                },
                                              ],
                                              staticClass: "top_name",
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playIndex ===
                                                    item.game_id + "pgGame",
                                                  expression:
                                                    "playIndex === item.game_id + 'pgGame'",
                                                },
                                              ],
                                              staticClass: "bottom_types",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.channel === 1
                                                      ? "PragmaticPlay"
                                                      : item.channel === 2
                                                      ? "Pocket Games"
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                  ])
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.tabActive === 2
                    ? _c(
                        "div",
                        {
                          staticClass: "contenArry",
                          staticStyle: { "min-height": "auto" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "contenArry_top",
                              attrs: { id: "jiLiDesignGame" },
                            },
                            [
                              _c("div", { staticClass: "content_left" }, [
                                _c("img", {
                                  staticClass: "designImg",
                                  attrs: {
                                    src: require("../../public/image/game/designJili.png"),
                                    alt: "",
                                  },
                                }),
                                _c("span", [_vm._v("Design JiLi")]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "content-nav-block",
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickAll(9)
                                    },
                                  },
                                },
                                [_vm._v(" Ver mais  > ")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "game-list-block" },
                            [
                              _c(
                                "van-swipe",
                                {
                                  ref: "jiliDesignSwipe",
                                  refInFor: true,
                                  staticClass: "my-swipe",
                                  attrs: {
                                    loop: false,
                                    "show-indicators": false,
                                  },
                                },
                                _vm._l(
                                  _vm.jiLiDesignArrayList,
                                  function (array, index) {
                                    return _c(
                                      "van-swipe-item",
                                      { key: index },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "game-list-page-block",
                                          },
                                          _vm._l(array, function (item) {
                                            return _c("div", [
                                              !item.more
                                                ? _c("img", {
                                                    class:
                                                      _vm.playIndex ===
                                                        item.game_id +
                                                          "internal" ||
                                                      item.active_status === 1
                                                        ? "imageFilter"
                                                        : "",
                                                    attrs: {
                                                      src: _vm.SplitGamePosterUrl(
                                                        item
                                                      ),
                                                      alt: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.readyPlay(
                                                          item,
                                                          "internal"
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.active_status ===
                                                        1,
                                                      expression:
                                                        "item.active_status === 1",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "game-maintenance",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("../../public/image/common/game-maintenance.png"),
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c("span", [
                                                    _vm._v("Em manutenção"),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.playLoading &&
                                                        _vm.ensureGameId ===
                                                          item.game_id +
                                                            "internal",
                                                      expression:
                                                        "playLoading && ensureGameId === item.game_id + 'internal'",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "game-play-loading",
                                                },
                                                [_c("van-loading")],
                                                1
                                              ),
                                              !item.more
                                                ? _c("img", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.playIndex ===
                                                          item.game_id +
                                                            "internal",
                                                        expression:
                                                          "playIndex === item.game_id + 'internal'",
                                                      },
                                                    ],
                                                    staticClass: "play-btn",
                                                    attrs: {
                                                      src: require("../../public/image/common/playBtn.png"),
                                                      alt: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.doPlay(
                                                          "internal"
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              item.more
                                                ? _c("img", {
                                                    attrs: {
                                                      alt: "",
                                                      src: require("../../public/image/home/viewAllGame.png"),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.clickAll(9)
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.playIndex ===
                                                        item.game_id +
                                                          "internal",
                                                      expression:
                                                        "playIndex === item.game_id + 'internal'",
                                                    },
                                                  ],
                                                  staticClass: "top_name",
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.playIndex ===
                                                        item.game_id +
                                                          "internal",
                                                      expression:
                                                        "playIndex === item.game_id + 'internal'",
                                                    },
                                                  ],
                                                  staticClass: "bottom_types",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.channel === 1
                                                          ? "Pragmatic Play"
                                                          : item.channel === 2
                                                          ? "Pocket Games"
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          }),
                                          0
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.tabActive === 3
                    ? _c("div", { staticClass: "contenArry" }, [
                        _c(
                          "div",
                          {
                            staticClass: "contenArry_top",
                            attrs: { id: "ppGame" },
                          },
                          [
                            _c("div", { staticClass: "content_left" }, [
                              _c("img", {
                                staticClass: "ppImg",
                                attrs: {
                                  src: require("../../public/image/game/pp.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v("Pragmatic Play")]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content-nav-block",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickAll(3)
                                  },
                                },
                              },
                              [_vm._v(" Ver mais  > ")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "game-list-block" },
                          [
                            _c(
                              "van-swipe",
                              {
                                ref: "ppGameSwipe",
                                refInFor: true,
                                staticClass: "my-swipe",
                                attrs: {
                                  loop: false,
                                  "show-indicators": false,
                                },
                              },
                              _vm._l(
                                _vm.ppGameArrayList,
                                function (array, index) {
                                  return _c("van-swipe-item", { key: index }, [
                                    _c(
                                      "div",
                                      { staticClass: "game-list-page-block" },
                                      _vm._l(array, function (item) {
                                        return _c("div", [
                                          !item.more
                                            ? _c("img", {
                                                class:
                                                  _vm.playIndex ===
                                                    item.game_id + "ppGame" ||
                                                  item.active_status === 1
                                                    ? "imageFilter"
                                                    : "",
                                                attrs: {
                                                  src: _vm.SplitGamePosterUrl(
                                                    item
                                                  ),
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.readyPlay(
                                                      item,
                                                      "ppGame"
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.active_status === 1,
                                                  expression:
                                                    "item.active_status === 1",
                                                },
                                              ],
                                              staticClass: "game-maintenance",
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("../../public/image/common/game-maintenance.png"),
                                                  alt: "",
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v("Em manutenção"),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playLoading &&
                                                    _vm.ensureGameId ===
                                                      item.game_id + "ppGame",
                                                  expression:
                                                    "playLoading && ensureGameId === item.game_id + 'ppGame'",
                                                },
                                              ],
                                              staticClass: "game-play-loading",
                                            },
                                            [_c("van-loading")],
                                            1
                                          ),
                                          !item.more
                                            ? _c("img", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.playIndex ===
                                                      item.game_id + "ppGame",
                                                    expression:
                                                      "playIndex === item.game_id + 'ppGame'",
                                                  },
                                                ],
                                                staticClass: "play-btn",
                                                attrs: {
                                                  src: require("../../public/image/common/playBtn.png"),
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.doPlay("ppGame")
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          item.more
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("../../public/image/home/viewAllGame.png"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickAll(3)
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playIndex ===
                                                    item.game_id + "ppGame",
                                                  expression:
                                                    "playIndex === item.game_id + 'ppGame'",
                                                },
                                              ],
                                              staticClass: "top_name",
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playIndex ===
                                                    item.game_id + "ppGame",
                                                  expression:
                                                    "playIndex === item.game_id + 'ppGame'",
                                                },
                                              ],
                                              staticClass: "bottom_types",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.channel === 1
                                                      ? "PragmaticPlay"
                                                      : item.channel === 2
                                                      ? "Pocket Games"
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                  ])
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.tabActive === 4
                    ? _c("div", { staticClass: "contenArry" }, [
                        _c(
                          "div",
                          {
                            staticClass: "contenArry_top",
                            attrs: { id: "pplGame" },
                          },
                          [
                            _c("div", { staticClass: "content_left" }, [
                              _c("img", {
                                staticClass: "liveImg",
                                attrs: {
                                  src: require("../../public/image/game/live.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v("LIVE")]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content-nav-block",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickAll(4)
                                  },
                                },
                              },
                              [_vm._v(" Ver mais  > ")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "game-list-block" },
                          [
                            _c(
                              "van-swipe",
                              {
                                ref: "pplGameSwipe",
                                refInFor: true,
                                staticClass: "my-swipe",
                                attrs: {
                                  loop: false,
                                  "show-indicators": false,
                                },
                              },
                              _vm._l(
                                _vm.pplGameArrayList,
                                function (array, index) {
                                  return _c("van-swipe-item", { key: index }, [
                                    _c(
                                      "div",
                                      { staticClass: "game-list-page-block" },
                                      _vm._l(array, function (item) {
                                        return _c("div", [
                                          !item.more
                                            ? _c("img", {
                                                class:
                                                  _vm.playIndex ===
                                                    item.game_id + "pplGame" ||
                                                  item.active_status === 1
                                                    ? "imageFilter"
                                                    : "",
                                                attrs: {
                                                  src: _vm.SplitGamePosterUrl(
                                                    item
                                                  ),
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.readyPlay(
                                                      item,
                                                      "pplGame"
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.active_status === 1,
                                                  expression:
                                                    "item.active_status === 1",
                                                },
                                              ],
                                              staticClass: "game-maintenance",
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("../../public/image/common/game-maintenance.png"),
                                                  alt: "",
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v("Em manutenção"),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playLoading &&
                                                    _vm.ensureGameId ===
                                                      item.game_id + "pplGame",
                                                  expression:
                                                    "playLoading && ensureGameId === item.game_id+ 'pplGame'",
                                                },
                                              ],
                                              staticClass: "game-play-loading",
                                            },
                                            [_c("van-loading")],
                                            1
                                          ),
                                          !item.more
                                            ? _c("img", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.playIndex ===
                                                      item.game_id + "pplGame",
                                                    expression:
                                                      "playIndex === item.game_id + 'pplGame'",
                                                  },
                                                ],
                                                staticClass: "play-btn",
                                                attrs: {
                                                  src: require("../../public/image/common/playBtn.png"),
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.doPlay("pplGame")
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          item.more
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("../../public/image/home/viewAllGame.png"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickAll(4)
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playIndex ===
                                                    item.game_id + "pplGame",
                                                  expression:
                                                    "playIndex === item.game_id + 'pplGame'",
                                                },
                                              ],
                                              staticClass: "top_name",
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playIndex ===
                                                    item.game_id + "pplGame",
                                                  expression:
                                                    "playIndex === item.game_id + 'pplGame'",
                                                },
                                              ],
                                              staticClass: "bottom_types",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.channel === 1
                                                      ? "PragmaticPlay"
                                                      : item.channel === 2
                                                      ? "Pocket Games"
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                  ])
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.tabActive === 5
                    ? _c("div", { staticClass: "contenArry" }, [
                        _c(
                          "div",
                          {
                            staticClass: "contenArry_top",
                            attrs: { id: "card" },
                          },
                          [
                            _c("div", { staticClass: "content_left" }, [
                              _c("img", {
                                staticClass: "cardImg",
                                attrs: {
                                  src: require("../../public/image/game/table.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v("TABLE")]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content-nav-block",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickAll(5)
                                  },
                                },
                              },
                              [_vm._v(" Ver mais  > ")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "game-list-block" },
                          [
                            _c(
                              "van-swipe",
                              {
                                ref: "cardGameSwipe",
                                refInFor: true,
                                staticClass: "my-swipe",
                                attrs: {
                                  loop: false,
                                  "show-indicators": false,
                                },
                              },
                              _vm._l(
                                _vm.cardGameArrayList,
                                function (array, index) {
                                  return _c("van-swipe-item", { key: index }, [
                                    _c(
                                      "div",
                                      { staticClass: "game-list-page-block" },
                                      _vm._l(array, function (item) {
                                        return _c("div", [
                                          !item.more
                                            ? _c("img", {
                                                class:
                                                  _vm.playIndex ===
                                                    item.game_id + "cardGame" ||
                                                  item.active_status === 1
                                                    ? "imageFilter"
                                                    : "",
                                                attrs: {
                                                  src: _vm.SplitGamePosterUrl(
                                                    item
                                                  ),
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.readyPlay(
                                                      item,
                                                      "cardGame"
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.active_status === 1,
                                                  expression:
                                                    "item.active_status === 1",
                                                },
                                              ],
                                              staticClass: "game-maintenance",
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("../../public/image/common/game-maintenance.png"),
                                                  alt: "",
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v("Em manutenção"),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playLoading &&
                                                    _vm.ensureGameId ===
                                                      item.game_id + "cardGame",
                                                  expression:
                                                    "playLoading && ensureGameId === item.game_id + 'cardGame'",
                                                },
                                              ],
                                              staticClass: "game-play-loading",
                                            },
                                            [_c("van-loading")],
                                            1
                                          ),
                                          !item.more
                                            ? _c("img", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.playIndex ===
                                                      item.game_id + "cardGame",
                                                    expression:
                                                      "playIndex === item.game_id + 'cardGame'",
                                                  },
                                                ],
                                                staticClass: "play-btn",
                                                attrs: {
                                                  src: require("../../public/image/common/playBtn.png"),
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.doPlay(
                                                      "cardGame"
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          item.more
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("../../public/image/home/viewAllGame.png"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickAll(5)
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playIndex ===
                                                    item.game_id + "cardGame",
                                                  expression:
                                                    "playIndex === item.game_id + 'cardGame'",
                                                },
                                              ],
                                              staticClass: "top_name",
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playIndex ===
                                                    item.game_id + "cardGame",
                                                  expression:
                                                    "playIndex === item.game_id + 'cardGame'",
                                                },
                                              ],
                                              staticClass: "bottom_types",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.channel === 1
                                                      ? "PragmaticPlay"
                                                      : item.channel === 2
                                                      ? "Pocket Games"
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                  ])
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.tabActive === 6
                    ? _c(
                        "div",
                        {
                          staticClass: "contenArry",
                          staticStyle: { "min-height": "auto" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "contenArry_top",
                              attrs: { id: "internalGame" },
                            },
                            [
                              _c("div", { staticClass: "content_left" }, [
                                _c("img", {
                                  staticClass: "internoImg",
                                  attrs: {
                                    src: require("../../public/image/game/Internos.png"),
                                    alt: "",
                                  },
                                }),
                                _c("span", [_vm._v("House")]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "content-nav-block",
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickAll(1)
                                    },
                                  },
                                },
                                [_vm._v(" Ver mais  > ")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "game-list-block" },
                            [
                              _c(
                                "van-swipe",
                                {
                                  ref: "internoSwipe",
                                  refInFor: true,
                                  staticClass: "my-swipe",
                                  attrs: {
                                    loop: false,
                                    "show-indicators": false,
                                  },
                                },
                                _vm._l(
                                  _vm.internalGameList,
                                  function (array, index) {
                                    return _c(
                                      "van-swipe-item",
                                      { key: index },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "game-list-page-block",
                                          },
                                          _vm._l(array, function (item) {
                                            return _c("div", [
                                              !item.more
                                                ? _c("img", {
                                                    class:
                                                      _vm.playIndex ===
                                                        item.game_id +
                                                          "internal" ||
                                                      item.active_status === 1
                                                        ? "imageFilter"
                                                        : "",
                                                    attrs: {
                                                      src: _vm.SplitGamePosterUrl(
                                                        item
                                                      ),
                                                      alt: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.readyPlay(
                                                          item,
                                                          "internal"
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.active_status ===
                                                        1,
                                                      expression:
                                                        "item.active_status === 1",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "game-maintenance",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("../../public/image/common/game-maintenance.png"),
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c("span", [
                                                    _vm._v("Em manutenção"),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.playLoading &&
                                                        _vm.ensureGameId ===
                                                          item.game_id +
                                                            "internal",
                                                      expression:
                                                        "playLoading && ensureGameId === item.game_id + 'internal'",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "game-play-loading",
                                                },
                                                [_c("van-loading")],
                                                1
                                              ),
                                              !item.more
                                                ? _c("img", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.playIndex ===
                                                          item.game_id +
                                                            "internal",
                                                        expression:
                                                          "playIndex === item.game_id + 'internal'",
                                                      },
                                                    ],
                                                    staticClass: "play-btn",
                                                    attrs: {
                                                      src: require("../../public/image/common/playBtn.png"),
                                                      alt: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.doPlay(
                                                          "internal"
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              item.more
                                                ? _c("img", {
                                                    attrs: {
                                                      alt: "",
                                                      src: require("../../public/image/home/viewAllGame.png"),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.clickAll(1)
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.playIndex ===
                                                        item.game_id +
                                                          "internal",
                                                      expression:
                                                        "playIndex === item.game_id + 'internal'",
                                                    },
                                                  ],
                                                  staticClass: "top_name",
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.playIndex ===
                                                        item.game_id +
                                                          "internal",
                                                      expression:
                                                        "playIndex === item.game_id + 'internal'",
                                                    },
                                                  ],
                                                  staticClass: "bottom_types",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.channel === 1
                                                          ? "Pragmatic Play"
                                                          : item.channel === 2
                                                          ? "Pocket Games"
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          }),
                                          0
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.tabActive === 7
                    ? _c("div", { staticClass: "contenArry" }, [
                        _c(
                          "div",
                          {
                            staticClass: "contenArry_top",
                            attrs: { id: "jiLiGame" },
                          },
                          [
                            _c("div", { staticClass: "content_left" }, [
                              _c("img", {
                                staticClass: "jiliImg",
                                attrs: {
                                  src: require("../../public/image/game/jili.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v("JILI")]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content-nav-block",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickAll(7)
                                  },
                                },
                              },
                              [_vm._v(" Ver mais  > ")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "game-list-block" },
                          [
                            _c(
                              "van-swipe",
                              {
                                ref: "jiLiGameSwipe",
                                refInFor: true,
                                staticClass: "my-swipe",
                                attrs: {
                                  loop: false,
                                  "show-indicators": false,
                                },
                              },
                              _vm._l(
                                _vm.jiLiArrayList,
                                function (array, index) {
                                  return _c("van-swipe-item", { key: index }, [
                                    _c(
                                      "div",
                                      { staticClass: "game-list-page-block" },
                                      _vm._l(array, function (item) {
                                        return _c("div", [
                                          !item.more
                                            ? _c("img", {
                                                class:
                                                  _vm.playIndex ===
                                                    item.game_id + "jiLiGame" ||
                                                  item.active_status === 1
                                                    ? "imageFilter"
                                                    : "",
                                                attrs: {
                                                  src: _vm.SplitGamePosterUrl(
                                                    item
                                                  ),
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.readyPlay(
                                                      item,
                                                      "jiLiGame"
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.active_status === 1,
                                                  expression:
                                                    "item.active_status === 1",
                                                },
                                              ],
                                              staticClass: "game-maintenance",
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("../../public/image/common/game-maintenance.png"),
                                                  alt: "",
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v("Em manutenção"),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playLoading &&
                                                    _vm.ensureGameId ===
                                                      item.game_id + "jiLiGame",
                                                  expression:
                                                    "playLoading && ensureGameId === item.game_id + 'jiLiGame'",
                                                },
                                              ],
                                              staticClass: "game-play-loading",
                                            },
                                            [_c("van-loading")],
                                            1
                                          ),
                                          !item.more
                                            ? _c("img", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.playIndex ===
                                                      item.game_id + "jiLiGame",
                                                    expression:
                                                      "playIndex === item.game_id + 'jiLiGame'",
                                                  },
                                                ],
                                                staticClass: "play-btn",
                                                attrs: {
                                                  src: require("../../public/image/common/playBtn.png"),
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.doPlay(
                                                      "jiLiGame"
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          item.more
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("../../public/image/home/viewAllGame.png"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickAll(7)
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playIndex ===
                                                    item.game_id + "jiLiGame",
                                                  expression:
                                                    "playIndex === item.game_id + 'jiLiGame'",
                                                },
                                              ],
                                              staticClass: "top_name",
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playIndex ===
                                                    item.game_id + "jiLiGame",
                                                  expression:
                                                    "playIndex === item.game_id + 'jiLiGame'",
                                                },
                                              ],
                                              staticClass: "bottom_types",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.channel === 1
                                                      ? "PragmaticPlay"
                                                      : item.channel === 2
                                                      ? "Pocket Games"
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                  ])
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.tabActive === 8
                    ? _c("div", { staticClass: "contenArry" }, [
                        _c(
                          "div",
                          {
                            staticClass: "contenArry_top",
                            attrs: { id: "cq9Game" },
                          },
                          [
                            _c("div", { staticClass: "content_left" }, [
                              _c("img", {
                                staticClass: "cq9Img",
                                attrs: {
                                  src: require("../../public/image/game/cq9.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v("CQ9")]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content-nav-block",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickAll(8)
                                  },
                                },
                              },
                              [_vm._v(" Ver mais  > ")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "game-list-block" },
                          [
                            _c(
                              "van-swipe",
                              {
                                ref: "cq9GameSwipe",
                                refInFor: true,
                                staticClass: "my-swipe",
                                attrs: {
                                  loop: false,
                                  "show-indicators": false,
                                },
                              },
                              _vm._l(_vm.cq9ArrayList, function (array, index) {
                                return _c("van-swipe-item", { key: index }, [
                                  _c(
                                    "div",
                                    { staticClass: "game-list-page-block" },
                                    _vm._l(array, function (item) {
                                      return _c("div", [
                                        !item.more
                                          ? _c("img", {
                                              class:
                                                _vm.playIndex ===
                                                  item.game_id + "cq9Game" ||
                                                item.active_status === 1
                                                  ? "imageFilter"
                                                  : "",
                                              attrs: {
                                                src: _vm.SplitGamePosterUrl(
                                                  item
                                                ),
                                                alt: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.readyPlay(
                                                    item,
                                                    "cq9Game"
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.active_status === 1,
                                                expression:
                                                  "item.active_status === 1",
                                              },
                                            ],
                                            staticClass: "game-maintenance",
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("../../public/image/common/game-maintenance.png"),
                                                alt: "",
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v("Em manutenção"),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.playLoading &&
                                                  _vm.ensureGameId ===
                                                    item.game_id + "cq9Game",
                                                expression:
                                                  "playLoading && ensureGameId === item.game_id + 'cq9Game'",
                                              },
                                            ],
                                            staticClass: "game-play-loading",
                                          },
                                          [_c("van-loading")],
                                          1
                                        ),
                                        !item.more
                                          ? _c("img", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.playIndex ===
                                                    item.game_id + "cq9Game",
                                                  expression:
                                                    "playIndex === item.game_id + 'cq9Game'",
                                                },
                                              ],
                                              staticClass: "play-btn",
                                              attrs: {
                                                src: require("../../public/image/common/playBtn.png"),
                                                alt: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.doPlay("cq9Game")
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        item.more
                                          ? _c("img", {
                                              attrs: {
                                                src: require("../../public/image/home/viewAllGame.png"),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickAll(8)
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.playIndex ===
                                                  item.game_id + "cq9Game",
                                                expression:
                                                  "playIndex === item.game_id + 'cq9Game'",
                                              },
                                            ],
                                            staticClass: "top_name",
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.playIndex ===
                                                  item.game_id + "cq9Game",
                                                expression:
                                                  "playIndex === item.game_id + 'cq9Game'",
                                              },
                                            ],
                                            staticClass: "bottom_types",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.channel === 1
                                                    ? "PragmaticPlay"
                                                    : item.channel === 2
                                                    ? "Pocket Games"
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ])
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
          _c("div", { staticClass: "search" }, [
            _c("img", {
              attrs: {
                src: require("../../public/image/game/search.png"),
                alt: "",
              },
              on: {
                click: function ($event) {
                  _vm.searchShow = true
                },
              },
            }),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "btnsArr" }),
      _c(
        "van-popup",
        {
          style: { height: "20%" },
          attrs: { position: "top" },
          model: {
            value: _vm.searchShow,
            callback: function ($$v) {
              _vm.searchShow = $$v
            },
            expression: "searchShow",
          },
        },
        [
          _c("div", { staticClass: "game-search" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchText,
                  expression: "searchText",
                },
              ],
              staticClass: "search",
              domProps: { value: _vm.searchText },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchText = $event.target.value
                },
              },
            }),
            _c(
              "div",
              { staticClass: "game-search-icon" },
              [
                _c("van-icon", {
                  attrs: { name: "search", size: "20", color: "#737373" },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "endBtn" }, [
            _c(
              "button",
              {
                staticClass: "toClose",
                on: {
                  click: function ($event) {
                    _vm.searchShow = false
                  },
                },
              },
              [_vm._v("Voltar")]
            ),
            _c(
              "button",
              {
                staticClass: "toSearch",
                class: _vm.searchText ? "" : "disabledClass",
                attrs: { disabled: !_vm.searchText },
                on: { click: _vm.searchGameHandler },
              },
              [_vm._v("Buscar")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }