var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defineComponent } from "vue";
import { mapState } from "vuex";
export default defineComponent({
    name: "downLoadAppTips",
    data: function () {
        return {
            downLoadTips: true,
            isShowDownLoadTips: false,
            closeIosTipsBlock: false,
        };
    },
    computed: __assign({}, mapState(["client", "token"])),
    created: function () {
        this.downLoadTips = localStorage.getItem("download_app_tips") === "true";
    },
    methods: {
        changeDownLoadTips: function (isShow) {
            localStorage.setItem("download_app_tips", isShow ? "true" : "false");
        },
        closeDownAppTips: function () {
            if (this.client === "Android") {
                this.downLoadTips = true;
                localStorage.setItem("download_app_tips", "true");
            }
            else if (this.client.indexOf("ios") !== -1) {
                this.closeIosTipsBlock = true;
            }
        },
        closeDownAppIos: function () {
            this.closeIosTipsBlock = false;
            this.downLoadTips = true;
        },
        iosTips: function () {
            this.closeIosTipsBlock = false;
            this.isShowDownLoadTips = true;
            this.downLoadTips = true;
        },
        downLoadAPP: function () {
            if (this.client === "Android") {
                this.downLoadApk();
            }
            else if (this.client.indexOf("ios") !== -1) {
                this.isShowDownLoadTips = true;
            }
        },
        downLoadApk: function () {
            if (this.token) {
                window.open(this.APK_URL);
                this.downLoadTips = true;
                localStorage.setItem("download_app_tips", "true");
            }
            else {
                this.$store.commit("IN_OR_UP", "up");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
            }
        },
    },
});
