var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _setup.props.show,
          expression: "props.show",
        },
      ],
      staticClass: "gameMask",
    },
    [
      _c("div", { staticClass: "game-mask-block" }, [
        _c("div", { staticClass: "game-mask-close" }, [
          _c("img", {
            staticClass: "close_pics",
            attrs: {
              src: require("../../public/image/login/close.png"),
              alt: "",
            },
            on: { click: _setup.closeMask },
          }),
        ]),
        _vm._m(0),
        _c(
          "div",
          { staticClass: "bonus-game-list" },
          _vm._l(_setup.games.array, function (item) {
            return _c("div", { staticClass: "bonus-game-block" }, [
              _c("img", {
                class: _setup.playIndex === item.game_id ? "imageFilter" : "",
                attrs: { src: item.icon, alt: "" },
                on: {
                  click: function ($event) {
                    return _setup.readyPlay(item.game_id)
                  },
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _setup.playIndex === item.game_id,
                    expression: "playIndex === item.game_id",
                  },
                ],
                staticClass: "play-btn",
                attrs: {
                  src: require("../../public/image/common/playBtn.png"),
                  alt: "",
                },
              }),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "bonus-context" }, [
      _c("div", { staticClass: "bonus-context-title" }, [
        _vm._v("Escolha um jogo"),
      ]),
      _c("div", { staticClass: "bonus-context-info" }, [
        _vm._v(
          "Você pode escolher um jogo das opções abaixo para usar sua aposta grátis. Uma vez que você selecione um jogo, não será possível trocar."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }