var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu-container" }, [
    _c(
      "div",
      { staticClass: "menu-context" },
      [
        _c("div", { staticClass: "adsArray" }, [
          _c(
            "div",
            {
              staticClass: "ads1",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/bonus")
                },
              },
            },
            [_c("span", [_vm._v("CENTRO DE BÔNUS")])]
          ),
          _c(
            "div",
            {
              staticClass: "ads2",
              on: {
                click: function ($event) {
                  return _vm.$store.commit("setWheelShow", true)
                },
              },
            },
            [
              _c("span", [_vm._v("REISJOGO WHEEL")]),
              _c("img", {
                staticClass: "ads2_wheel",
                attrs: {
                  src: require("../../public/image/menu/ads2_wheel.png"),
                  alt: "",
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "ads3",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/vip")
                },
              },
            },
            [_c("span", [_vm._v("CASH BACK")])]
          ),
          _c(
            "div",
            {
              staticClass: "ads4",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/share")
                },
              },
            },
            [_c("span", [_vm._v("CONVITE")])]
          ),
          _c(
            "div",
            {
              staticClass: "ads5",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/tournament")
                },
              },
            },
            [_c("span", [_vm._v("TORNEIO")])]
          ),
        ]),
        _c("div", { staticClass: "deliver" }),
        _c(
          "div",
          { staticClass: "otherGame" },
          _vm._l(_vm.topTitle, function (val, k) {
            return _c("div", [
              !val.hasChildren
                ? _c(
                    "div",
                    {
                      staticClass: "topItems",
                      on: {
                        click: function ($event) {
                          return _vm.clickItem(0)
                        },
                      },
                    },
                    [_vm._v(_vm._s(val.name))]
                  )
                : _c(
                    "div",
                    { staticClass: "listContent" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "topItems",
                          on: {
                            click: function ($event) {
                              return _vm.clickItem(1)
                            },
                          },
                        },
                        [_vm._v(_vm._s(val.name))]
                      ),
                      _c(
                        "van-collapse",
                        {
                          model: {
                            value: _vm.activeNames,
                            callback: function ($$v) {
                              _vm.activeNames = $$v
                            },
                            expression: "activeNames",
                          },
                        },
                        _vm._l(_vm.dataList, function (item, index) {
                          return _c(
                            "van-collapse-item",
                            {
                              key: index,
                              attrs: { name: index },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _c("div", { staticClass: "items" }, [
                                          _c("img", {
                                            staticClass: "titleImg",
                                            attrs: {
                                              src: item.titleImg,
                                              alt: "",
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(item.title)),
                                          ]),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "menuListUl" },
                                _vm._l(
                                  item.subList,
                                  function (c_item, c_index) {
                                    return _c("li", { key: c_index }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "items",
                                          staticStyle: { "margin-top": "10px" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toPage(c_item.name)
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "titleIcon",
                                            attrs: {
                                              src: c_item.icon,
                                              alt: "",
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(c_item.name)),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
            ])
          }),
          0
        ),
        _vm._l(_vm.menuList, function (item) {
          return _c(
            "div",
            {
              staticClass: "menu-item",
              on: {
                click: function ($event) {
                  return _vm.jumpUrl(item)
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../public/image/menupop/" + item.path),
                  alt: "",
                },
              }),
              _c("span", [_vm._v(_vm._s(item.name))]),
            ]
          )
        }),
        _c("div", { staticClass: "deliver" }),
        _vm.client.indexOf("ios") === -1 && _vm.client.indexOf("Android") === -1
          ? _c(
              "div",
              { staticClass: "download", on: { click: _vm.downLoadApk } },
              [
                _c("img", {
                  attrs: {
                    src: require("../../public/image/menu/downImg.png"),
                    alt: "",
                  },
                }),
                _c("span", [_vm._v("Baixar o APP")]),
              ]
            )
          : _vm._e(),
        _vm.client.indexOf("ios") === -1 && _vm.client.indexOf("Android") === -1
          ? _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "align-items": "center",
                },
              },
              [
                _c(
                  "p",
                  {
                    staticStyle: {
                      color: "white",
                      "margin-top": "10px",
                      "font-size": "12px",
                    },
                  },
                  [_vm._v("Baixe e receba R$5")]
                ),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }