import store from "@/store";

/**
 * @param amount
 * @param digit
 * @returns {string}
 * @constructor
 */
export const AmountFixed = (amount, digit = 2) => {
    if (amount) {
        return (amount * 100 / 100).toLocaleString('pt-br');
    }
    else return '0,00'
}

export const TimeTurn = (time) => {
    let count = time - new Date().getTime()
    if (count <= 0) return '00 : 00 : 00 : 00';
    let days = parseInt(count / 1000 / 60 / 60 / 24, 10);
    let hours = parseInt(count / 1000 / 60 / 60 % 24, 10);
    let minutes = parseInt(count / 1000 / 60 % 60, 10);
    let seconds = parseInt(count / 1000 % 60, 10);

    return (days > 9 ? days : '0' + days) + ' : ' +
        (hours > 9 ? hours : '0' + hours) + ' : ' +
        (minutes > 9 ? minutes : '0' + minutes) + ' : ' +
        (seconds > 9 ? seconds : '0' + seconds)
}

export const SplitGamePosterUrl = (gameInfo) => {
    if (gameInfo.channel === 1) {
        return 'https://d25pkupzrfbq8i.cloudfront.net/image/gamelist/' + gameInfo.ex_game_id + '/images/297x365.png'
    } else if (gameInfo.channel === 2) {
        return 'https://d25pkupzrfbq8i.cloudfront.net/image/gamelist/' + gameInfo.desc + '/297x365.png'
    } else if (gameInfo.channel === 3) {
        return 'https://d25pkupzrfbq8i.cloudfront.net/image/gamelist/InternalGame/' + gameInfo.ex_game_id + '.png'
    } else if (gameInfo.channel === 4) {
        return 'https://d25pkupzrfbq8i.cloudfront.net/image/gamelist/tempicon/' + gameInfo.ex_game_id + '.jpg'
    } else if (gameInfo.channel === 5) {
        return 'https://d25pkupzrfbq8i.cloudfront.net/image/gamelist/tempicon/' + gameInfo.ex_game_id + '.jpg'
    } else if (gameInfo.channel === 6) {
        return 'https://d25pkupzrfbq8i.cloudfront.net/image/gamelist/InternalGame/' + gameInfo.ex_game_id + '.png'
    }
}

export const updateAmountByAnimation = (win, newAmount) => {
    if (win !== 0) store.commit('setWinMoney', win);
    setTimeout(() => {
        store.commit('setWinMoney', 0);
        store.commit('setAmountMoney', newAmount);
    }, 500)
}

export const generateUUID = () => {
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
        d += performance.now();
    }
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
};

export const parseBlob = (data) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader(), res = {};
        reader.readAsText(data, 'utf-8');
        reader.onload = function () {
            res = JSON.parse(reader.result);
            resolve(res);
        }
    });
}

export const HandlerUrlParams = (name) => {
    if (!window.location.search) return false;
    const deSearch = window.location.search.replace(/%20/g, "+").replace(/\?/, "").replace(/=/g, "\":\"").replace(/&/g, "\",\"");
    const reSearch = "{\"" + decodeURIComponent(deSearch) + "\"}"
    const reSearchObj = JSON.parse(reSearch)
    return reSearchObj[name] || false
}