<template>
  <div class="outBox">
    <div class="hover-icon">
      <img @click="opencheerPop" src="../../../public/image/common/cheerGift.webp" alt="" v-show="cheerIconShow">
      <img @click="openspeedUpPop" src="../../../public/image/common/speedUpGift.png" alt="" v-show="speedIconShow">
    </div>

    <div class="cheerBox">
      <div class="active-mask-block" v-if="isCheerShow">
        <div class="active-block" v-show="isCheerShow">
          <div class="active-close-block">
            <img src="../../../public/image/recharge/close.png" alt="close" @click="closeCheerPop">
          </div>
          <div class="active-context">
            <div class="active-img">
              <div class="superTxt">Super valor 100%</div>
              <div class="timebox">
                <p class="tmeName">Disponível em</p>
                <p class="clock">
                  <van-count-down :time="cheerJson.expired_at - (new Date().getTime())" />
                </p>
              </div>
              <div class="btns" @click="toBuy(cheerJson.id)">
                <p class="need">Preço especial: R$ {{cheerJson.need_recharge}}</p>
                <p class="receive">Preço original : R$ {{parseInt(cheerJson.need_recharge)+parseInt(cheerJson.bonus)
                  }}</p>
              </div>
            </div>
            <div class="records">
              <div class="textBox">
                <div :class="nameArr.length > 2?'inner-container':'flowText'">
                  <p class="text" v-for="(item,index) in nameArr" :key="index" v-html="item.txt1"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="speedBox">
      <div class="active-mask-block" v-if="isSpeedShow">
        <div class="active-block" v-show="isSpeedShow">
          <div class="active-close-block">
            <img src="../../../public/image/recharge/close.png" alt="close" @click="closeSpeedUpPop">
          </div>
          <div class="active-context">
            <img src="../../../public/image/recharge/title.png" alt="" class="active-title">
            <div class="imgBox">
              <div class="timebox">
                <p class="tmeName">TEMPO ESTIMADO PARA RETIRADA</p>
                <p class="clock"><van-count-down :time="speedJson.expired_at - (new Date().getTime())" /></p>
              </div>
              <div class=" active-img">
                <div class="btns" @click="toBuy(speedJson.id)">
                  <p>Deposite <span class="darkGreen">R${{speedJson.need_recharge}}</span> para acelerar
                    imediatamente
                  </p>
                </div>
              </div>
              <div class="records">
                <div class="textBox">
                  <div :class="nameArr.length > 2?'inner-container':'flowText'">
                    <p class="text" v-for="(item,index) in nameArr" :key="index" v-html="item.txt2"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import { getUserPack, userBuyPack } from "@/api/api";
  import WSRequest, { event, ReceiveMessage } from "@/api/WebSocketServer";
  export default {
    data() {
      return {
        isCheerShow: false,
        isSpeedShow: false,
        cheerIconShow: false,
        speedIconShow: false,
        nameArr: [],
        cheerJson: {},
        speedJson: {},
        timer: null
      }
    },
    created() {
      getUserPack().then(res => {
        if (res && res.length > 0) {
          res.map(item => {
            if (item.pack_type === 1) {
              this.cheerJson = item
              if (this.$route.path === '/' || this.$route.name === 'index') {
                this.cheerIconShow = true
              }
              this.$store.commit('setCheerPopShow', true);
            }
            if (item.pack_type === 2) {
              this.speedJson = item
              if (this.$route.path === '/' || this.$route.name === 'index') {
                this.speedIconShow = true
              }
              this.$store.commit('setSpeedUpPopShow', true);
            }
          })
        } else {
          this.cheerIconShow = false
          this.speedIconShow = false
          this.$store.commit('setCheerPopShow', false);
          this.$store.commit('setSpeedUpPopShow', false);
        }
      }).catch(err => { })
      this.eventPay();
      this.eventPay_GetUserPack()
    },
    computed: {
      ...mapState(['cheerPopShow', 'speedUpPopShow', 'token', 'config', 'client']),
    },
    watch: {
      cheerPopShow(val) {
        this.isCheerShow = val
        this.getStr()
      },
      speedUpPopShow(val) {
        this.isSpeedShow = val
        this.getStr()
      }
    },
    methods: {
      closeCheerPop() {
        this.isCheerShow = false;
        this.$store.commit('setCheerPopShow', false);
      },
      closeSpeedUpPop() {
        this.isSpeedShow = false;
        this.$store.commit('setSpeedUpPopShow', false);
      },
      getStr() {
        this.changeNameArrText()
      },
      changeNameArrText() {
        const nameStr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        const gameNameArr = ['PG', 'PP', 'JILI', 'CQ9', 'HOUSE', 'TABLE', 'LIVE']
        let res = "";
        for (let k = 0; k < 20; k++) {
          for (let i = 0; i < 9; i++) {
            let id = parseInt(Math.random() * 36);
            res += nameStr[id];
          }
          if (this.nameArr.length > 20) {
            this.nameArr.shift()
          }
          this.nameArr.push({
            txt1: `Parabéns a <span
                      style="color:green">U${res}</span> por ganhar um prêmio de <span style="color:gold">R$
                      ${Math.round(Math.random() * (20000 - 500)) + 500}</span> no
                    jogo ' ${gameNameArr[Math.round(Math.random() * 6)]} '`,
            txt2: `<span style="color:green">U${res}</span> retirou com
                      sucesso <span style="color:gold">R${Math.round(Math.random() * (10000 - 1000)) + 1000}</span>
                      .`
          })
          res = ''
        }
      },
      toBuy(id) {
        // let a = null;
        // if (this.client.indexOf('ios') !== -1) {
        //   a = window.open();
        // }
        let buryingParams = localStorage.getItem('BuryingParams'), params = {};
        if (buryingParams && buryingParams !== 'null') {
          params.publish_channel = buryingParams.split('___')[0];
          params.pid = buryingParams.split('___')[1]
        }
        if (params.publish_channel) {
          params.click_id = localStorage.getItem('click_id')
        }
        userBuyPack({
          'id': id,
          'channel': this.config.pays[0].channel,
          ...params
        }).then(res => {
          // if (this.client.indexOf('ios') !== -1) {
          //   a.location = res;
          // } else {
          //   window.open(res)
          // }
          this.$store.commit('setPayforUrl', res)
        }).catch(() => {
        })

      },
      eventPay() {
        // ReceiveMessage('RechargeSuc', (res) => {
        //   if (res && res.length > 0) {
        //     res.map(item => {
        //       if (item.pack_type === 1) {
        //         this.cheerJson = item
        //         if (this.$route.path === '/' || this.$route.name === 'index') {
        //           this.cheerIconShow = true
        //         }
        //         this.$store.commit('setCheerPopShow', true);
        //       }
        //       if (item.pack_type === 2) {
        //         this.speedJson = item
        //         if (this.$route.path === '/' || this.$route.name === 'index') {
        //           this.speedIconShow = true
        //         }
        //         this.$store.commit('setSpeedUpPopShow', true);
        //       }
        //     })
        //   } else {
        //     this.cheerIconShow = false
        //     this.speedIconShow = false
        //     this.$store.commit('setCheerPopShow', false);
        //     this.$store.commit('setSpeedUpPopShow', false);
        //   }
        // })
      },
      eventPay_GetUserPack() {
        ReceiveMessage('GetUserPack', (res) => {
          if (res && res.length > 0) {
            res.map(item => {
              if (item.pack_type === 1) {
                this.cheerJson = item
                if (this.$route.path === '/' || this.$route.name === 'index') {
                  this.cheerIconShow = true
                }
                this.$store.commit('setCheerPopShow', true);
              }
              if (item.pack_type === 2) {
                this.speedJson = item
                if (this.$route.path === '/' || this.$route.name === 'index') {
                  this.speedIconShow = true
                }
                this.$store.commit('setSpeedUpPopShow', true);
              }
            })
          } else {
            this.cheerIconShow = false
            this.speedIconShow = false
            this.$store.commit('setCheerPopShow', false);
            this.$store.commit('setSpeedUpPopShow', false);
          }
        })
      },
      opencheerPop() {
        if (this.token) {
          this.$store.commit('setCheerPopShow', true);
        } else {
          this.$store.commit('IN_OR_UP', 'in')
          this.$store.commit('MASKCONTENT', 'login')
          this.$store.commit('MASK', true)
        }
      },
      openspeedUpPop() {
        if (this.token) {
          this.$store.commit('setSpeedUpPopShow', true);
        } else {
          this.$store.commit('IN_OR_UP', 'in')
          this.$store.commit('MASKCONTENT', 'login')
          this.$store.commit('MASK', true)
        }
      },
      getTime(data) {
        var time;
        var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = (data % (1000 * 60)) / 1000;
        time = (hours < 10 ? ('0' + hours) : hours) + ':' + (minutes < 10 ? ('0' + minutes) : minutes) + ':' + (seconds < 10 ? ('0' + seconds) : seconds);
        return time;
      },
    },
    beforeDestroy() {
      clearInterval(this.timer)
    }
  }
</script>
<style lang="less" scoped>
  .cheerBox {
    .active-mask-block {
      width: 100%;
      height: 100vh;
      background: rgba(2, 2, 2, 0.8);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2010;
      color: #fff;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      >div {
        text-align: center;
      }

      .active-block {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        /* min-height: 600px; */
        /* height: 1334px; */
        /* background-color: #14202D;
      padding: 30px;
      border-radius: 40px; */
        background: url('../../../public/image/recharge/cheerBg.png') no-repeat;
        background-size: 100% 100%;
        animation: eject-pop 0.3s ease-in-out;
        padding: 80px 0px;
        box-sizing: border-box;
      }

      .active-close-block {
        position: absolute;
        top: 100px;
        right: 54px;
        z-index: 2;

        img {
          width: 30px;
        }
      }
    }

    .active-context {
      width: 750px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

    }

    .active-img {
      width: 100%;
      height: 772px;
      background: url('../../../public/image/recharge/cheerImg01.png') no-repeat;
      background-size: 100% 100%;
      position: relative;
      top: 10%;
      z-index: 1;
    }

    .superTxt {
      width: 390px;
      height: 40px;
      position: absolute;
      top: 110px;
      left: 180px;
      color: #FFD800;
      font-size: 38px;
      font-weight: 900;
    }

    .timebox {
      width: 200px;
      height: 60px;
      color: #fff;
      position: absolute;
      left: 270px;
      top: 170px;

      .tmeName {
        font-size: 20px;
      }

      .clock {
        font-size: 30px;
        font-weight: 900;
      }
    }

    .btns {
      width: 500px;
      height: 130px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 130px;
      bottom: 60px;
      z-index: 2;

      .need {
        font-size: 33px;
        font-weight: 900;
        color: #522E00;
      }

      .receive {
        font-size: 25px;
        font-weight: 900;
        text-decoration: line-through;
        color: #B76F1A;
        margin-top: 8px;
      }
    }

    .records {
      width: 663px;
      background: #1C1620;
      border-radius: 45px;
      padding: 40px 100px 50px;
      box-sizing: border-box;
      position: relative;
      top: -60px;
      z-index: 0;
      text-align: left;

      p {
        font-size: 23px;
        color: #fff;
        margin-top: 16px;
      }

      .green {
        color: #4ADC23;
      }

      .gold {
        color: #FFD200;
      }
    }

    .textBox {
      height: 120px;
      overflow: hidden;
    }

    ::v-deep .van-count-down {
      color: #fff !important;
    }

    .inner-container {
      height: 150px;
      animation: myMove 30s linear infinite;
      animation-fill-mode: forwards;
      font-size: 15px;
      color: #333;
      line-height: 35px;
    }

    .flowText {
      font-size: 15px;
      color: #333;
      line-height: 35px;
    }
  }

  .speedBox {
    .active-mask-block {
      width: 100%;
      height: 100vh;
      background: rgba(2, 2, 2, 0.8);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2010;
      color: #fff;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      >div {
        text-align: center;
      }

      .active-block {
        position: relative;
        width: 100%;
        background: url('../../../public/image/recharge/cheerBg.png') no-repeat;
        background-size: 100% 100%;
        animation: eject-pop 0.3s ease-in-out;
        padding: 0px 0px 80px;
        box-sizing: border-box;
      }

      .active-close-block {
        position: absolute;
        top: 70px;
        right: 12px;
        z-index: 2;

        img {
          width: 32px;
        }
      }
    }

    .active-context {
      width: 750px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .imgBox {
      width: 100%;
      position: relative;
      margin-top: 67px;
    }

    .active-title {
      width: 620px;
      height: 50px;
      text-align: center;
      margin-top: 130px;
    }

    .active-img {
      width: 100%;
      height: 821px;
      background: url('../../../public/image/recharge/speedImg01.png') no-repeat;
      background-size: 100% 100%;
      position: relative;
      top: 10%;
      z-index: 1;
    }

    .timebox {
      width: 312px;
      height: 129px;
      color: #fff;
      background: url('../../../public/image/recharge/timeBg.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 219px;
      right: 219px;
      top: -45px;

      .tmeName {
        width: 195px;
        line-height: 30px;
        font-size: 20px;
      }

      .clock {
        font-size: 30px;
        font-weight: 900;
      }
    }

    .btns {
      width: 562px;
      height: 157px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 95px;
      right: 95px;
      bottom: 60px;
      z-index: 2;
      background: url('../../../public/image/recharge/btnBg.png') no-repeat;
      background-size: 100% 100%;
      font-size: 30px;
      font-weight: bold;
      color: #522D00;
      line-height: 48px;

      .darkGreen {
        font-size: 48px;
        color: #298911;
      }
    }

    .records {
      width: 665px;
      height: 228px;
      background: url('../../../public/image/recharge/recordsBg.png') no-repeat;
      background-size: 100% 100%;
      padding: 100px 30px 40px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -145px;
      left: 43px;
      right: 42px;
      z-index: 0;
      overflow: hidden;
      text-align: left;

      p {
        font-size: 23px;
        color: #fff;
        margin-top: 16px;
      }

      .green {
        color: #4ADC23;
      }

      .gold {
        color: #FFD200;
      }
    }

    .textBox {
      height: 120px;
      overflow: hidden;
    }

    ::v-deep .van-count-down {
      color: #fff !important;
    }

    .inner-container {
      height: 150px;
      animation: myMove 30s linear infinite;
      animation-fill-mode: forwards;
      font-size: 15px;
      color: #333;
      line-height: 35px;
    }

    .flowText {
      font-size: 15px;
      color: #333;
      line-height: 35px;
    }
  }

  .hover-icon {
    position: fixed;
    bottom: 510px;
    right: 50px;
    display: flex;
    flex-direction: column;

    img {
      width: 90px;
      margin-bottom: 20px;
    }
  }

  @keyframes eject-pop {
    from {
      transform: scale(0.6)
    }

    to {
      transform: scale(1)
    }
  }

  @keyframes myMove {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-1500px);
    }
  }
</style>