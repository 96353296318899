var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          style: { height: "180px", backgroundColor: "#14202d" },
          attrs: {
            position: "top",
            round: "",
            "lock-scroll": true,
            "close-on-click-overlay": false,
          },
          model: {
            value: _setup.isShow,
            callback: function ($$v) {
              _setup.isShow = $$v
            },
            expression: "isShow",
          },
        },
        [
          _c("div", { staticClass: "network-context" }, [
            _c(
              "div",
              { staticClass: "title" },
              [
                _c("van-icon", { attrs: { name: "warning", color: "brown" } }),
                _vm._v(" Erro de ligação, por favor faça login novamente. "),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "network-btn", on: { click: _setup.reconnect } },
              [_vm._v(" Reconectar ")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }