var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ranking pdt70" }, [
    _c("div", { staticClass: "ranking_box" }, [
      _c("h3", [_vm._v("RANKING")]),
      _c(
        "div",
        { staticClass: "ranking-btn" },
        _vm._l(_vm.typeList, function (item, index) {
          return _c(
            "div",
            {
              class: _vm.currentType === index ? "active" : "",
              on: {
                click: function ($event) {
                  return _vm.selectType(index)
                },
              },
            },
            [_vm._v(" " + _vm._s(item) + " ")]
          )
        }),
        0
      ),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "list-title" }, [
          _c("div", { staticClass: "rank" }, [_vm._v("Classificação")]),
          _c("div", { staticClass: "name" }, [_vm._v("Jogadores")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentType == 0,
                  expression: "currentType == 0",
                },
              ],
              staticClass: "score",
            },
            [_vm._v("Multiplicador")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentType == 1,
                  expression: "currentType == 1",
                },
              ],
              staticClass: "score",
            },
            [_vm._v("Ganhos totais")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentType == 2,
                  expression: "currentType == 2",
                },
              ],
              staticClass: "score",
            },
            [_vm._v("Multiplicador")]
          ),
        ]),
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentType == 0,
                expression: "currentType == 0",
              },
            ],
            staticClass: "list-ul",
          },
          [
            _vm._l(_vm.cost_rankList, function (item, index) {
              return _c("li", { key: index }, [
                _c("div", { staticClass: "rank" }, [
                  index == 0
                    ? _c("div", [
                        _c("img", {
                          attrs: {
                            src: require("../../public/image/home/rank_1.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : index == 1
                    ? _c("div", [
                        _c("img", {
                          attrs: {
                            src: require("../../public/image/home/rank_2.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : index == 2
                    ? _c("div", [
                        _c("img", {
                          attrs: {
                            src: require("../../public/image/home/rank_3.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  index > 2 ? _c("div", [_vm._v(_vm._s(index + 1))]) : _vm._e(),
                ]),
                _c("div", { staticClass: "name" }, [
                  _c("div", { staticClass: "avatar" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.IMAGE_URL + "header/" + item.avatar + ".jpg",
                        alt: "",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "rank-name-text" }, [
                    _vm._v(_vm._s(item.uid)),
                  ]),
                ]),
                _c("div", { staticClass: "score" }, [
                  _c("div", { staticClass: "money" }, [
                    _c("span", [_vm._v("R$")]),
                    _vm._v(_vm._s(_vm.AmountFixed(parseInt(item.score)))),
                  ]),
                ]),
              ])
            }),
            _c("div", { staticClass: "progress_bar_block" }, [
              _c("div", { attrs: { id: "progress_bar" } }, [
                _c("div", {
                  style: {
                    width: _vm.cost_rankList
                      ? _vm.calcPercent(
                          _vm.cost_rankList.length,
                          _vm.cost_total
                        ) *
                          100 +
                        "%"
                      : 0,
                  },
                  attrs: { id: "progress_back" },
                }),
              ]),
            ]),
          ],
          2
        ),
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentType == 1,
                expression: "currentType == 1",
              },
            ],
            staticClass: "list-ul",
          },
          [
            _vm._l(_vm.back_rankList, function (item, index) {
              return _c("li", { key: index }, [
                _c("div", { staticClass: "rank" }, [
                  index == 0
                    ? _c("div", [
                        _c("img", {
                          attrs: {
                            src: require("../../public/image/home/rank_1.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : index == 1
                    ? _c("div", [
                        _c("img", {
                          attrs: {
                            src: require("../../public/image/home/rank_2.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : index == 2
                    ? _c("div", [
                        _c("img", {
                          attrs: {
                            src: require("../../public/image/home/rank_3.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  index > 2 ? _c("div", [_vm._v(_vm._s(index + 1))]) : _vm._e(),
                ]),
                _c("div", { staticClass: "name" }, [
                  _c("div", { staticClass: "avatar" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.IMAGE_URL + "header/" + item.avatar + ".jpg",
                        alt: "",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "rank-name-text" }, [
                    _vm._v(_vm._s(item.uid)),
                  ]),
                ]),
                _c("div", { staticClass: "score" }, [
                  _c("div", { staticClass: "money" }, [
                    _c("span", [_vm._v("R$")]),
                    _vm._v(_vm._s(_vm.AmountFixed(parseInt(item.score)))),
                  ]),
                ]),
              ])
            }),
            _c("div", { staticClass: "progress_bar_block" }, [
              _c("div", { attrs: { id: "progress_bar" } }, [
                _c("div", {
                  style: {
                    width: _vm.back_rankList
                      ? _vm.calcPercent(
                          _vm.back_rankList.length,
                          _vm.back_total
                        ) *
                          100 +
                        "%"
                      : 0,
                  },
                  attrs: { id: "progress_back" },
                }),
              ]),
            ]),
          ],
          2
        ),
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentType == 2,
                expression: "currentType == 2",
              },
            ],
            staticClass: "list-ul",
          },
          [
            _vm._l(_vm.rate_rankList, function (item, index) {
              return _c("li", { key: index }, [
                _c("div", { staticClass: "rank" }, [
                  index == 0
                    ? _c("div", [
                        _c("img", {
                          attrs: {
                            src: require("../../public/image/home/rank_1.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : index == 1
                    ? _c("div", [
                        _c("img", {
                          attrs: {
                            src: require("../../public/image/home/rank_2.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : index == 2
                    ? _c("div", [
                        _c("img", {
                          attrs: {
                            src: require("../../public/image/home/rank_3.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  index > 2 ? _c("div", [_vm._v(_vm._s(index + 1))]) : _vm._e(),
                ]),
                _c("div", { staticClass: "name" }, [
                  _c("div", { staticClass: "avatar" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.IMAGE_URL + "header/" + item.avatar + ".jpg",
                        alt: "",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "rank-name-text" }, [
                    _vm._v(_vm._s(item.uid)),
                  ]),
                ]),
                _c("div", { staticClass: "score" }, [
                  _c("div", { staticClass: "money money_y" }, [
                    _vm._v("x" + _vm._s(parseInt(item.score))),
                  ]),
                ]),
              ])
            }),
            _c("div", { staticClass: "progress_bar_block" }, [
              _c("div", { attrs: { id: "progress_bar" } }, [
                _c("div", {
                  style: {
                    width: _vm.rate_rankList
                      ? _vm.calcPercent(
                          _vm.rate_rankList.length,
                          _vm.rate_total
                        ) *
                          100 +
                        "%"
                      : 0,
                  },
                  attrs: { id: "progress_back" },
                }),
              ]),
            ]),
          ],
          2
        ),
      ]),
      _vm.currentType === 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.cost_rankList.length < 50,
                  expression: "cost_rankList.length < 50",
                },
              ],
              staticClass: "views-btn",
            },
            [
              _c(
                "button",
                { staticClass: "viewAll", on: { click: _vm.cost_nextPage } },
                [_vm._v("Ver tudo")]
              ),
            ]
          )
        : _vm._e(),
      _vm.currentType === 1
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.back_rankList.length < 50,
                  expression: "back_rankList.length < 50",
                },
              ],
              staticClass: "views-btn",
            },
            [
              _c(
                "button",
                { staticClass: "viewAll", on: { click: _vm.back_nextPage } },
                [_vm._v("Ver tudo")]
              ),
            ]
          )
        : _vm._e(),
      _vm.currentType === 2
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.rate_rankList.length < 50,
                  expression: "rate_rankList.length < 50",
                },
              ],
              staticClass: "views-btn",
            },
            [
              _c(
                "button",
                { staticClass: "viewAll", on: { click: _vm.rate_nextPage } },
                [_vm._v("Ver tudo")]
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }