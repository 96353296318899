// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/image/purse/purseImg.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/image/purse/btn.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".active-mask-block[data-v-c1ca12c2] {\n  width: 100%;\n  height: 100vh;\n  background: rgba(2, 2, 2, 0.8);\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 6;\n  color: #fff;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  align-content: center;\n  justify-content: center;\n  align-items: center;\n}\n.active-mask-block .active-block[data-v-c1ca12c2] {\n  width: 6.49333rem;\n  height: 9.17333rem;\n  position: relative;\n  animation: eject-pop-c1ca12c2 0.3s ease-in-out;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100% 100%;\n}\n.active-mask-block .monney[data-v-c1ca12c2] {\n  position: absolute;\n  bottom: 2.4rem;\n  left: 0.54667rem;\n  right: 0.52rem;\n  display: flex;\n}\n.active-mask-block .monney img[data-v-c1ca12c2] {\n  width: 1.10667rem;\n  height: 1.08rem;\n  margin-right: 0.33333rem;\n}\n.active-mask-block .monney span[data-v-c1ca12c2] {\n  color: #22D247;\n  font-weight: bold;\n  font-size: 1.10667rem;\n  text-shadow: 0 0.05333rem 0.2rem rgba(108, 43, 0, 0.56);\n}\n.active-mask-block .submit[data-v-c1ca12c2] {\n  width: 3.46667rem;\n  height: 1.33333rem;\n  position: absolute;\n  bottom: 0.66667rem;\n  left: 50%;\n  margin-left: -1.73333rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #FFFFFF;\n  font-weight: bold;\n  font-size: 0.41333rem;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-size: 100% 100%;\n}\n.active-close-block[data-v-c1ca12c2] {\n  position: absolute;\n  top: 1.73333rem;\n  right: 0.8rem;\n  z-index: 100;\n}\n.active-close-block img[data-v-c1ca12c2] {\n  width: 0.53333rem;\n}\n.purse-disabled[data-v-c1ca12c2] {\n  filter: grayscale(1);\n}\n@keyframes eject-pop-c1ca12c2 {\nfrom {\n    transform: scale(0.6);\n}\nto {\n    transform: scale(1);\n}\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
