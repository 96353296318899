var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "game-module" },
    [
      _c("gameTabs", {
        attrs: { menuList: _vm.menuList },
        on: { changeTab: _vm.changeTab },
      }),
      _vm.tabActive === 0
        ? _c(
            "div",
            _vm._l(_vm.menuList, function (item) {
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "content_top",
                    attrs: { id: "demonstrate-" + item.channel },
                  },
                  [
                    _c("div", { staticClass: "content_left" }, [
                      _c("img", {
                        staticClass: "hotImg",
                        attrs: { src: item.img, alt: "" },
                      }),
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "content-nav-block",
                        on: {
                          click: function ($event) {
                            return _vm.$router.push("/gameShop/" + item.channel)
                          },
                        },
                      },
                      [_vm._v(" Ver mais  > ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "game-grid-3-block" },
                  _vm._l(item.gameList, function (json, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c("GameCard", {
                          attrs: { json: json, isHot: index <= 5 },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }