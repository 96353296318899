<script>
  import { defineComponent } from 'vue'
  import { getSignConfig, doSignIn } from "@/api/api";
  import { mapState } from "vuex";
  export default defineComponent({
    name: "SignReward",
    data() {
      return {
        isShow: false,
        signConfig: [{}, {}, {}, {}, {}, {}],
        currentSign: 0,
        pending: false,
        signConfig7: {},
        isSignToday: false,
        betMul: '',
        showVipLimit: false
      }
    },
    created() {
      if (this.token) {
        this.querySignConfig();
      }
    },
    computed: {
      ...mapState(['token', 'signInByDay', 'homePopOrder', 'userData', 'config'])
    },
    watch: {
      signInByDay(val) {
        if (val && this.token) {
          if (this.homePopOrder === 3) {
            getSignConfig().then(res => {
              if (res.is_sign || this.userData.vip_lv === 0) {
                this.$store.commit('setSignInByDay', false);
              } else {
                this.isShow = val
              }
            }).catch(() => { })
          } else {
            this.isShow = true;
            this.querySignConfig();
          }
        } else {
          this.isShow = false;
          this.$store.commit('setSignInByDay', false);
        }
      },
      token(val) {
        if (val) this.querySignConfig();
      }
    },
    methods: {
      closeSelf() {
        this.isShow = false;
        this.$store.commit('setSignInByDay', false);
      },
      reserved(index) {
        if (this.userData.vip_lv === 0) {
          this.showVipLimit = true;
          return;
        }

        if (index === this.currentSign && this.isSignToday === false) {
          this.pending = true;
          doSignIn().then(res => {
            this.pending = false;
            this.$store.dispatch('UserInfo');
            setTimeout(() => {
              this.isShow = false;
              this.$store.commit('setSignInByDay', false);
            }, 500)
          }).catch(() => {
            this.pending = false;
          })
        }
      },
      querySignConfig() {
        getSignConfig().then(res => {
          this.isSignToday = res.is_sign;

          this.betMul = res.bet_mul;
          let list = res['rewards_list'];
          this.signConfig7 = list[6];
          list.splice(list.length - 1, 1);
          this.signConfig = list;

          this.currentSign = res.sign_days;
        }).catch(() => { })
      }
    }
  })
</script>

<template>
  <div class="active-mask-block" v-if="isShow">
    <div class="active-block" v-show="isShow">
      <div class="active-close-block">
        <img src="../../public/image/login/close.png" alt="close" @click.stop="closeSelf">
      </div>
      <div class="active-name-block">Recompensa diária</div>
      <div class="sign-context">
        <div class="sign-context-block" v-for="(item, index) in signConfig" :class="{'sign-unclaimed': currentSign > index || (currentSign === index && isSignToday === false),
          'sign-received': currentSign <= index,
          'sign-active': (currentSign === index && isSignToday === false),
          'sign-over': currentSign > index}" @click="reserved(index)">
          <div class="sign-day">
            DAY{{index + 1}}
          </div>
          <img v-show="currentSign <= index" src="../../public/image/active/unclaimed-coin.png" alt="">
          <img v-show="currentSign > index" style="margin: 6px;" src="../../public/image/active/received-coin.png"
            alt="">
          <div class="sign-loading" v-show="pending && currentSign === index">
            <van-loading type="spinner" size="40" color="#000" />
          </div>
          <div class="sign-val">R$ {{item}}</div>
        </div>
      </div>
      <div class="sign-context-block-7" :class="{'sign-active': currentSign === 6 && isSignToday === false,
              'sign-unclaimed-7': currentSign > 6 || (currentSign === 6 && isSignToday === false),
              'sign-received': currentSign <= 6,
              'sign-over': currentSign > 6}" @click="reserved(6)">
        <div class="sign-day">
          DAY7
        </div>
        <img v-show="currentSign <= 6" src="../../public/image/active/unclaimed-coin.png" alt="">
        <img v-show="currentSign > 6" style="margin: 6px;" src="../../public/image/active/received-coin.png" alt="">
        <div class="sign-val" style="padding-top: 30px">R$ {{signConfig7}}</div>
        <div class="sign-loading" v-show="pending && currentSign === 6">
          <van-loading type="spinner" size="40" color="#000" />
        </div>
      </div>

      <div class="active-text-block">
        <p>
          Torne-se VIP, faça login diariamente para receber recompensas,
          o bônus requer {{betMul}} vezes o valor em apostas antes de poder ser retirado.
        </p>
      </div>
    </div>

    <van-popup v-model="showVipLimit" class="confirm-block">
      <div class="game-prompt">
        <div class="game-prompt-title" style="margin: 0"></div>
        <van-icon @click="showVipLimit = false" class="tips-close" name="cross" size="24" color="#fff" />
        <p>Deposite</p>
        <p class="game-prompt-cash">R$ {{config.vip[1].need_bets}}</p>
        <div class="vip-limit-text">
          <p>para se tornar <span style="font-size: 26px;color: red">VIP</span></p>
          <p>e receber um <span style="font-size: 26px;color: #FF8A00">Bônus</span></p>
        </div>
        <div class="vip-limit-btn">
          <div @click="showVipLimit = false">Cancelar</div>
          <div @click="$router.push('/recharge');showVipLimit = false;$store.commit('setSignInByDay', false)">Tornar-se VIP</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<style scoped lang="less">
  .active-mask-block {
    width: 100%;
    height: 100vh;
    background: rgba(2, 2, 2, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2010;
    color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    >div {
      text-align: center;
    }

    .active-block {
      position: relative;
      width: calc(100% - 80px);
      min-height: 600px;
      background-color: #14202D;
      padding: 30px;
      border-radius: 40px;
      animation: eject-pop 0.3s ease-in-out;
    }

    .free-text {
      position: absolute;
      top: 58%;
      left: calc(50% - 200px);
      width: 400px;
      font-size: 32px;
      text-align: center;
      line-height: 40px;
    }

    .active-close-block {
      text-align: right;

      img {
        width: 40px;
      }
    }

    .active-swipe-block {
      min-height: 450px;

      img {
        width: 46%;
        margin: 20px 10px;
      }
    }

    .active-name-block {
      margin-top: -30px;
      font-size: 30px;
      font-weight: 900;
    }

    .active-text-block {
      padding: 30px 10px 30px 0;
      font-size: 28px;

      p {
        text-align: center;
        font-size: 24px;
        line-height: 40px;
        color: #FFFFFF;

      }
    }
  }

  .sign-context {
    display: grid;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(3, 34%);
    grid-gap: 20px 20px;
    margin: 40px 40px 0 40px;
  }

  .sign-context-block {
    width: 100%;
    height: 244px;
    border-radius: 20px;
    position: relative;

    img {
      width: 120px;
      margin: 20px 0 0 0;
    }
  }

  .sign-context-block-7 {
    width: calc(100% - 40px);
    height: 160px;
    border-radius: 20px;
    position: relative;
    margin: 20px;

    img {
      float: right;
      width: 150px;
      margin: 20px 60px;
    }
  }

  .sign-active:active {
    transform: scale(0.96);
  }

  .sign-over {
    filter: brightness(0.3);
  }
  .sign-unclaimed {
    background: url("../../public/image/active/sginBackGround.png") no-repeat;
    background-size: 100%;
  }
  .sign-unclaimed-7 {
    background: url("../../public/image/active/sginBackGround-7.png") no-repeat;
    background-size: 100%;
  }
  .sign-received {
    background-color: #3B4AD6;
  }
  .sign-day {
    float: left;
    background-color: #FFD200;
    width: 100px;
    border-radius: 20px 0;
    font-size: 26px;
    color: #13202D;
    padding: 8px;
    font-style: italic;
    font-weight: 800;
  }
  .sign-val {
    font-weight: 800;
    font-size: 44px;
    color: greenyellow;
  }
  .sign-loading {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    width: 100px;
  }

  .confirm-block {
    width: 80%;
    height: 640px;
    background-color: #FFFFFF;
    border-radius: 20px;

    .tips-close {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  .game-prompt {
    width: 100%;
    p {
      font-size: 34px;
      width: 100%;
      text-align: center;
      font-weight: 600;
      margin: 30px 0;
      color: #000000;
    }
    .game-prompt-cash {
      font-size: 60px !important;
      color: #008600;
      margin: 20px 0;
    }
    .game-prompt-title {
      height: 90px;
      background-color: #F82E48;
      margin-bottom: 120px;
    }
    .game-prompt-ok {
      width: 500px;
      background-color: #F82E48;
      color: #FFFFFF;
      border-radius: 14px;
      padding: 16px;
      text-align: center;
      margin: 20px auto;
      margin-top: 150px;
      font-size: 48px;
    }
    .vip-limit-text {
      p {
        margin: 0;
      }
    }
    .vip-limit-btn {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 30px;
      div {
        width: 200px;
        font-size: 30px;
        padding: 26px;
        border-radius: 16px;
        margin: 20px;
      }
      div:first-child {
        background-color: #CBCBCB;
        color: #595959;
      }
      div:last-child {
        background-color: #F82E48;
        color: #FFFFFF;
      }
    }
  }

  @keyframes eject-pop {
    from {
      transform: scale(0.6)
    }

    to {
      transform: scale(1)
    }
  }
</style>