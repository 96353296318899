var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          staticClass: "confirm-block",
          attrs: { closeable: "" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", { staticClass: "game-prompt" }, [
            _c("div", { staticClass: "game-prompt-title" }),
            _c("p", [
              _vm._v(
                " Devido às restrições do fabricante, você só pode levar "
              ),
            ]),
            _c("p", { staticClass: "game-prompt-cash" }, [
              _vm._v("R$ " + _vm._s(_vm.cash) + " "),
            ]),
            _c("p", [_vm._v(" para o jogo ")]),
            _c(
              "div",
              { staticClass: "game-prompt-ok", on: { click: _vm.doPlay } },
              [_vm._v("ok")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }