// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/image/purse/popupBg.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/image/purse/blueBtn.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".active-mask-block[data-v-0586f8be] {\n  width: 100%;\n  height: 100vh;\n  background: rgba(2, 2, 2, 0.8);\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 6;\n  color: #fff;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  align-content: center;\n  justify-content: center;\n  align-items: center;\n}\n.active-mask-block .active-block[data-v-0586f8be] {\n  position: relative;\n  width: 8.42667rem;\n  padding: 0 0.66667rem;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100% 100%;\n  box-sizing: border-box;\n  animation: eject-pop-0586f8be 0.3s ease-in-out;\n}\n.active-mask-block .active-close-block[data-v-0586f8be] {\n  position: absolute;\n  top: 0.13333rem;\n  right: 0.52rem;\n}\n.active-mask-block .active-close-block img[data-v-0586f8be] {\n  width: 0.4rem;\n}\n.active-mask-block .content[data-v-0586f8be] {\n  color: #FFFFFF;\n  font-weight: 300;\n  font-size: 0.28rem;\n  margin-top: 1.33333rem;\n  text-align: center;\n}\n.active-mask-block .btns[data-v-0586f8be] {\n  width: 5.56rem;\n  height: 0.89333rem;\n  font-size: 0.28rem;\n  color: #FFFFFF;\n  font-weight: 600;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-size: 100% 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 0.62667rem auto;\n}\n@keyframes eject-pop-0586f8be {\nfrom {\n    transform: scale(0.6);\n}\nto {\n    transform: scale(1);\n}\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
