<template>
  <div class="nav-btm">
    <div
      class="nav-btm-list"
      :class="[active === index ? 'isActive' : '']"
      v-for="(item, index) in navList"
      :key="index"
      @click="navClick(item, index)"
      v-show="isChannel"
    >
      <div :class="item.isRecharge ? 'redBgList' : ''">
        <img
          :src="active === index ? item.activeImg : item.img"
          alt=""
          class="barimg"
          :class="index === 1 ? 'resize' : ''"
        />
        <span>{{ item.title }}</span>
        <div class="share-message" v-show="index === 1">
          <!-- <img src="../../public/image/common/fire-icon.png" alt=""> -->
          R$100
        </div>
        <div class="tournament-message" v-show="index === 3">R$18.888</div>
      </div>
      <div
        class="conta-message"
        v-show="index === 4 && messageNumber > 0"
      ></div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      active: 0,
      isActive: true,
      navList: [],
      navDataList: [
        {
          title: "INíCIO",
          img: require("../../public/image/home/bar_home.png"),
          activeImg: require("../../public/image/home/bar_home_active.png"),
          path: "/",
          isRecharge: false,
        },
        {
          title: "CONVITE",
          img: require("../../public/image/home/bar_share.png"),
          activeImg: require("../../public/image/home/bar_share_active.png"),
          path: "/share",
          isRecharge: false,
        },
        {
          title: "Depósito",
          img: require("../../public/image/home/recharge.png"),
          activeImg: require("../../public/image/home/recharge.png"),
          path: "/recharge",
          isRecharge: true,
        },
        {
          title: "BONUS",
          img: require("../../public/image/home/bar_bonus.png"),
          activeImg: require("../../public/image/home/bar_bonus_active.png"),
          path: "/bonus",
          isRecharge: false,
        },
        {
          title: "Conta",
          img: require("../../public/image/home/conta.png"),
          activeImg: require("../../public/image/home/conta_active.png"),
          path: "/personCenter",
          isRecharge: false,
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "menuBlockShow",
      "taskNumber",
      "channel",
      "client",
      "token",
      "INNERMASK",
      "messageNumber",
    ]),
  },
  watch: {
    channel(val) {
      if (!val.open_recharge) {
        this.navDataList.splice(2, 2);
        this.navList = this.navDataList;
      } else this.navList = this.navDataList;
    },
    $route() {
      this.navDataList.map((item, index) => {
        if (item.path === this.$route.fullPath) {
          this.active = index;
        }
      });
    },
  },
  methods: {
    navClick(val, index) {
      if (!this.token && (index === 2 || index === 4)) {
        this.$store.commit("setMenuBlockShow", false);
        this.$store.commit("IN_OR_UP", "in");
        this.$store.commit("MASKCONTENT", "login");
        this.$store.commit("MASK", true);
        return;
      }
      if (val.path === "/menu") {
        this.$store.commit("setMenuBlockShow", !this.menuBlockShow);
        return;
      }
      if (val.path === "/personCenter") {
        if (this.token) {
          this.$store.commit("INNERMASK", !this.innerMask);
        } else {
          this.$store.commit("setMenuBlockShow", false);
          this.$store.commit("IN_OR_UP", "in");
          this.$store.commit("MASKCONTENT", "login");
          this.$store.commit("MASK", true);
        }
        return;
      }
      this.active = index;
      if (this.$route.path === val.path) return;
      this.$router.replace(val.path);
    },
    isChannel(index) {
      // console.log(this.channel)
    },
    downLoadAPP() {
      if (this.client === "Android") {
        this.downLoadApk();
      } else if (this.client.indexOf("ios") !== -1) {
        this.isShowDownLoadTips = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.nav-btm {
  z-index: 5;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #141f2d;
  font-size: 41px;
  color: #a1c5f3;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  box-shadow: 0 -4px 30px 3px rgba(0, 0, 0, 0.5);
  padding: 10px;
  box-sizing: border-box;
  height: 100px;

  .nav-btm-list {
    width: 120px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    box-sizing: border-box;
    position: relative;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .barimg {
      width: 43px;
      height: 43px;
      margin-bottom: 10px;
    }

    span {
      text-align: center;
      width: 120px;
      font-size: 20px;
      font-weight: bold;
      display: block;
    }

    .subscript {
      position: absolute;
      top: 0;
      right: 10px;
      text-align: center;
    }

    .icons {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 10px;
    }

    .nav-text {
      text-align: center;
      width: 120px;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .redBgList {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
    box-sizing: border-box;
    width: 170px;
    height: 170px;
    background: url("../../public/image/home/redBagBg.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: -50px;

    .barimg {
      width: 52px;
      height: 50px;
      margin-bottom: 10px;
      position: absolute;
      top: 30px;
    }

    span {
      color: #fff;
      position: absolute;
      bottom: 50px;
    }
  }
}

.task-message {
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: red;
  border-radius: 40px;
  position: absolute;
  left: 80px;
  top: -12px;
  animation: breathe 2s infinite ease-in-out;
}

/* .share-message {
    position: absolute;
    left: 65px;
    top: -14px;
    width: 36px;
    animation: breathe 2s infinite ease-in-out;

    img {
      width: 40px;
    }
  } */
.share-message {
  position: absolute;
  left: 15px;
  top: -30px;
  width: 90px;
  height: 30px;
  animation: breathe 2s infinite ease-in-out;
  background: #2a3d55;
  color: #54b949;
  font-size: 20px;
  font-weight: 900;
  border-radius: 20px;
  animation: 3s myBreath linear infinite;

  img {
    width: 100%;
  }
}

.tournament-message {
  position: absolute;
  left: 15px;
  top: -30px;
  width: 120px;
  height: 30px;
  background: #ffd201;
  color: #000;
  font-size: 20px;
  font-weight: 900;
  border-radius: 20px;
  animation: 3s myBreath linear infinite;

  img {
    width: 100%;
  }
}

.conta-message {
  position: absolute;
  right: 30px;
  top: 0px;
  width: 15px;
  height: 15px;
  background: red;
  border-radius: 50%;
}

@keyframes myBreath {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}
</style>