var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "reis pdt70" }, [
    _c("div", { staticClass: "reis_box" }, [
      _vm._m(0),
      _c("div", { staticClass: "reis_content" }, [
        _c("h1", [_vm._v("Garantia de jogo justo")]),
        _c("p", [
          _vm._v(
            " Nossos jogos são absolutamente justos, sem interferência nos resultados dos sorteios. A plataforma é supervisionada e regulamentada por alianças internacionais para garantir sua tranquilidade e justiça nos jogos. "
          ),
        ]),
        _c("img", {
          attrs: { src: _vm.IMAGE_URL + "home/balance_pic.png", alt: "" },
        }),
      ]),
      _c("div", { staticClass: "reis-content-r" }, [
        _c("h1", [_vm._v(" Pagamentos automáticos")]),
        _c("p", [
          _vm._v(
            " Colaboramos com bancos brasileiros para garantir 100% de segurança dos fundos dos usuários. Oferecemos saques rápidos 24/7, sem revisão manual, desde que você atenda aos requisitosbásicos de saque. "
          ),
        ]),
        _c("img", {
          attrs: { src: _vm.IMAGE_URL + "home/money_pic.png", alt: "" },
        }),
      ]),
      _c("div", { staticClass: "reis_content reis_content2" }, [
        _c("h1", [_vm._v("Alto valor de cashback")]),
        _c("p", [
          _vm._v(
            " Ao participar das apostas, você acumula progresso no VIP. O status VIP é válido permanentemente e cada nível VIP possui uma taxa de cashback diferente, podendo chegar a até 5%. O seu cashback será automaticamente creditado em sua conta no dia seguinte, e você pode retirá-lo diretamente ou continuar jogando. "
          ),
        ]),
        _c("img", {
          attrs: { src: _vm.IMAGE_URL + "home/vip_pic.png", alt: "" },
        }),
      ]),
      _c("div", { staticClass: "reis-content-r" }, [
        _c("h1", [_vm._v("Retorno generoso para agentes")]),
        _c("p", [
          _vm._v(
            " Por cada usuário válido que você convidar, você receberá uma comissão de R$12. Quanto mais convidar, maior será a recompensa. Além disso, você poderá obter até 1% de reembolso das apostas de cada usuário convidado. "
          ),
        ]),
        _c("img", {
          staticClass: "agen",
          attrs: { src: _vm.IMAGE_URL + "home/dice_pic.png", alt: "" },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "mg-auto" }, [
      _vm._v("Por que escolher o "),
      _c("span", { staticStyle: { color: "#53B949" } }, [_vm._v("jogo Reis")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }