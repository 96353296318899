const state = {
    inorup: '',//login/register
    mask: false,//is open mask
    Account_Operations: 'RECARGA',
    Authorization: '',//token
    innerMask: false,
    innerContent: '',
    maskContent: '',//App.vue mask
    choose_money: '',//recharge num
    messageNumber: 0,
    isMessageShow: false,
    winMoney: 0,
    amountMoney: 0.00,
    taskNumber: 0,
    eventBuryingClickId: '',

    WsConnect: true,
    isLoading: null,
    menuBlockShow: false,
    openRecharge: false,
    channel: {},
    token: '',
    userData: {},
    config: {},
    wheelShow: false,
    client: '',
    showSeasonPop1: false,
    toast: {},
    redBagId: 0,
    slotsTigerShow: false,
    purseShow: false,
    PursePopupShow: false,
    searchList: [],
    partMoneyGame: '',
    openCodingTask: false,
    openBetTaskList: false,
    notAllowGame: false,
    signInByDay: false,
    homePopOrder: 0,
    rechargeActiveShow: false,
    readyPopUp: false,
    cheerPopShow: false,
    speedUpPopShow: false,
    gameRechargePopup: false,
    payforUrl: '',
    playingGameName: '',
    paySuccess: ''
}
export default state
