<template>
  <div v-if="client.indexOf('ios') === -1 && client.indexOf('Android') === -1">
    <div class="domain-box">
      <hr class="line1" />
      <h4 class="title">OR LOGIN DIRECTLY WITH</h4>
      <hr class="line2" />
    </div>
    <div class="otherApp">
      <img src="../../public/image/home/fb.png" alt="" class="appIcon" />
      <img src="../../public/image/home/whatsApp.png" alt="" class="appIcon" />
      <img src="../../public/image/home/tg.png" alt="" class="appIcon" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
    }
  },
  watch: {},
  computed: {
    ...mapState(['client'])
  },
  methods: {},
  created() {
    
  },
  mounted() { }
}
</script>
<style lang="less" scoped>
.domain-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0 0px 0;
  .line1 {
    width: 100px;
    border: none;
    height: 1px;
    background-color: #14202e;
  }
  .line2 {
    width: 100px;
    border: none;
    height: 1px;
    background-color: #14202e;
  }

  h4 {
    font-size: 30px;
    color: white;
  }
}

.otherApp {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px;
  img.appIcon {
    width: 68px;
    height: 67px;
  }
}
</style>