<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";
import {getGamePlayUrl, getUserGreatePack, userBuyGreatePack} from "@/api/api";

export default defineComponent({
  name: "GameRechargeActivity",
  data() {
    return {
      gameId: '',
      show: false,
      showDetail: false,
      packJson: {},
      isPgGame: false,
      gameUrl: ''
    }
  },
  computed: {
    ...mapState(['gameRechargePopup', 'config', 'client'])
  },
  watch: {
    gameRechargePopup(val) {
      if (val) {
        this.show = true;
        this.gameId = val;
        this.getGameRechargeConfig()
        this.getGameUrl(val)
      }
    },
    show(val) {
      if (!val) {
        this.$store.commit('setGameRechargePopup', val);
        this.showDetail = false;

        if (this.isPgGame) {
          this.$router.push({ name: 'play', params: { url: 'pgGameId__' + this.gameId } })
        } else {
          console.log('notPgGame', this.gameUrl)
          this.$router.push({ name: 'play', params: { url: this.gameUrl } })
        }
      }
    }
  },
  created() {
    this.getGameRechargeConfig()
  },
  methods: {
    recharge(money) {
      if (this.submitLock) return;
      this.submitLock = true;

      let params = {
        id: this.packJson.id,
        money: money,
        channel: this.config.pays[0].channel
      };
      let buryingParams = localStorage.getItem('BuryingParams');

      if (buryingParams && buryingParams !== 'null') {
        params.publish_channel = buryingParams.split('___')[0];
        params.pid = buryingParams.split('___')[1]
      }
      if (params.publish_channel) {
        params.click_id = localStorage.getItem('click_id')
      }

      userBuyGreatePack(params).then(res => {
        this.submitLock = false;
        this.$store.commit('setPayforUrl', res)
      }).catch(() => {
        this.submitLock = false;
      })
    },
    getGameRechargeConfig() {
      getUserGreatePack().then(res => {
        if (res) {
          this.packJson = res;
        }
      }).catch(() => {})
    },
    getGameUrl(gameId) {
      getGamePlayUrl({
        gameId: gameId,
        isTail: false
      }).then((res) => {
        this.isPgGame = res.blank;
        this.gameUrl = res.url;
      }).catch(() => {})
    }
  }
})
</script>

<template>
  <div>
    <van-popup v-model="show" :closeable="!showDetail" class="confirm-block">
      <div class="game-recharge-block" v-show="!showDetail">
        <div class="game-recharge-back">
          <img src="../../../public/image/active/game-recharge-back.png" alt="">

          <div class="game-recharge-count-down">
            Disponível em :
            <van-count-down :time="packJson.expired_at - (new Date().getTime())" format="HH:mm:ss"/>
          </div>

          <div class="recharge-money-gear">
            <div> +{{packJson.second}}%<br>BÔNUS</div>
            <div>
              <span @click="recharge(packJson.recharge_arr[1])">R$ {{packJson.recharge_arr && packJson.recharge_arr[1]}}</span>
              <span @click="recharge(packJson.recharge_arr[2])">R$ {{packJson.recharge_arr && packJson.recharge_arr[2]}}</span>
              <span @click="recharge(packJson.recharge_arr[3])">R$ {{packJson.recharge_arr && packJson.recharge_arr[3]}}</span>
            </div>
          </div>

          <div class="change-top recharge-money-gear">
            <div> +R${{packJson.first}}<br>BÔNUS</div>
            <div>
              <span @click="recharge(packJson.recharge_arr[0])" style="width: 180px;padding-right: 20px">R$ {{packJson.recharge_arr && packJson.recharge_arr[0]}}</span>
            </div>
          </div>

          <div class="game-recharge-detail-btn" @click="showDetail = true">Detalhes ></div>
        </div>
      </div>

      <div class="game-recharge-detail" v-show="showDetail">
        <div class="detail-close-block">
          <van-icon @click="showDetail = false" name="cross" color="#fff" size="20"/>
        </div>
        <div class="title">Regras de Bônus</div>
        <p>
          Oferecemos a você um bônus especial
          correspondente ao depósito de bônus.
          Aproveite esta grande oportunidade para
          aumentar o seu saldo com bônus
          adicionais,
        </p>
        <div>
          <div class="game-recharge-detail-context">
            <img src="../../../public/image/active/game-recharge-detail-icon.png" alt="">
            <span>+{{packJson.second}}% BÔNUS</span>
          </div>
          <div class="game-recharge-detail-context">
            <img style="width: calc(26% - 6px)" src="../../../public/image/active/game-recharge-detail-icon1.png" alt="">
            <span>+R${{packJson.first}} BÔNUS</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<style scoped lang="less">
.confirm-block {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.game-recharge-block {
  width: 100%;
  height: 100%;
  position: relative;

  .game-recharge-back {
    position: absolute;
    top: 200px;
    width: 100%;
    img {
      width: 100%;
    }
  }

  .recharge-money-gear {
    position: absolute;
    top: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;

    div:first-child {
      color: #FFD800;
      font-size: 40px;
      font-weight: 900;
      margin: 0 0 40px 140px;
    }

    div:last-child {
      color: #651F00;
      font-size: 28px;
      font-weight: 900;
      width: 79%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-evenly;
      align-items: center;

      span{
        display: block;
        width: 160px;
        padding: 20px 0;
        text-align: center;
      }
    }
  }
  .change-top {
    top: 596px !important;
  }
}
.game-recharge-detail-btn {
  position: absolute;
  width: 100%;
  bottom: -40px;
  font-size: 30px;
  text-align: center;
  color: #FFFFFF;
  text-underline: #FFFFFF;
}

::v-deep .van-popup__close-icon--top-right {
  top: 100px !important;
  color: #FFFFFF;
}

.game-recharge-detail {
  position: absolute;
  top: 260px;
  width: 80%;
  left: 5%;
  height: 640px;
  border-radius: 40px;
  background-color: #0E121B;
  padding: 0 40px 40px 40px;

  .detail-close-block {
    text-align: right;
    height: 60px;
    padding-top: 20px;
  }

  .title {
    font-size: 30px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 800;
  }
  p {
    font-size: 26px;
    color: #556479;
    font-weight: 600;
    line-height: 30px;
    margin-top: 30px;
  }
  .game-recharge-detail-context {
    display: flex;
    padding: 20px 0 20px 0;

    img {
      width: 26%;
      margin-right: 40px;
    }
    span {
      color: #FFD800;
      font-size: 36px;
      font-family: Montserrat;
      display: flex;
      align-items: center;
    }
  }
}
.game-recharge-count-down {
  color: #FFFFFF;
  width: 100%;
  text-align: center;
  font-size: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 166px;
}

::v-deep .van-count-down {
  color: #FFFFFF;
}
</style>