var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.purseShow,
              expression: "purseShow",
            },
          ],
          staticClass: "active-mask-block",
        },
        [
          _c("div", { staticClass: "active-close-block" }, [
            _c("img", {
              attrs: {
                src: require("../../public/image/purse/close.png"),
                alt: "close",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.close.apply(null, arguments)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "active-block" }, [
            _c("div", { staticClass: "monney" }, [
              _c("img", {
                attrs: {
                  src: require("../../public/image/purse/coins.png"),
                  alt: "",
                },
              }),
              _c("span", [_vm._v(_vm._s(_vm.amount))]),
            ]),
            _c("div", { staticClass: "submit", on: { click: _vm.showPop } }, [
              _vm._v(" Saque "),
            ]),
          ]),
        ]
      ),
      _c("PursePopup", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.PursePopupShow,
            expression: "PursePopupShow",
          },
        ],
        attrs: { times: _vm.times, amount: _vm.amount, info: _vm.info },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }