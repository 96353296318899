var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-btm" },
    _vm._l(_vm.navList, function (item, index) {
      return _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isChannel,
              expression: "isChannel",
            },
          ],
          key: index,
          staticClass: "nav-btm-list",
          class: [_vm.active === index ? "isActive" : ""],
          on: {
            click: function ($event) {
              return _vm.navClick(item, index)
            },
          },
        },
        [
          _c("div", { class: item.isRecharge ? "redBgList" : "" }, [
            _c("img", {
              staticClass: "barimg",
              class: index === 1 ? "resize" : "",
              attrs: {
                src: _vm.active === index ? item.activeImg : item.img,
                alt: "",
              },
            }),
            _c("span", [_vm._v(_vm._s(item.title))]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: index === 1,
                    expression: "index === 1",
                  },
                ],
                staticClass: "share-message",
              },
              [_vm._v(" R$100 ")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: index === 3,
                    expression: "index === 3",
                  },
                ],
                staticClass: "tournament-message",
              },
              [_vm._v("R$18.888")]
            ),
          ]),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: index === 4 && _vm.messageNumber > 0,
                expression: "index === 4 && messageNumber > 0",
              },
            ],
            staticClass: "conta-message",
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }