var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          staticClass: "confirm-block",
          attrs: { closeable: "" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", { staticClass: "game-prompt" }, [
            _c("div", { staticClass: "game-prompt-title" }),
            _c("p", [_vm._v("Você precisa depositar pelo menos")]),
            _c("p", { staticClass: "game-prompt-cash" }, [_vm._v("R$ 10 ")]),
            _c("p", [_vm._v("para acessar este jogo")]),
            _c(
              "div",
              {
                staticClass: "game-prompt-ok",
                on: {
                  click: function ($event) {
                    _vm.show = false
                    _vm.$router.push("/recharge")
                  },
                },
              },
              [_vm._v("ok")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }