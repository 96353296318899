var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "task-context" },
    [
      _c(
        "van-popup",
        {
          staticClass: "task-block",
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            { staticClass: "task-prompt" },
            [
              _c("div", { staticClass: "task-prompt-title" }, [
                _vm._v(" Bônus de Apostas Acumulado "),
              ]),
              _c("van-icon", {
                staticClass: "task-prompt-close",
                attrs: { name: "cross", size: "26" },
                on: {
                  click: function ($event) {
                    _vm.show = false
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "task-list-block" },
                _vm._l(_vm.tasks, function (item) {
                  return _c("div", { staticClass: "task-item" }, [
                    _c("div", { staticClass: "task-item-row" }, [
                      _c("div", { staticClass: "task-item-dec" }, [
                        _vm._v(_vm._s(item.desc)),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "task-item-do",
                          class: item.status === 2 ? "" : "task-disable",
                          on: {
                            click: function ($event) {
                              return _vm.reward(item.id)
                            },
                          },
                        },
                        [_vm._v("Prosseguir")]
                      ),
                    ]),
                    _c("div", { staticClass: "task-item-row" }, [
                      _c("div", { staticClass: "progress-bar" }, [
                        _c("div", {
                          staticClass: "schedule",
                          style:
                            "width:" +
                            parseFloat(item.valid_bets) /
                              parseFloat(item.need_bets),
                        }),
                        _c("div", { staticClass: "progress-bar-text" }, [
                          _vm._v(
                            _vm._s(item.valid_bets + "/" + item.need_bets)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "task-reward" }, [
                        _c("img", {
                          attrs: {
                            src: require("../../../public/image/common/R$.png"),
                            alt: "",
                          },
                        }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.AmountFixed(item.reward))),
                        ]),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }