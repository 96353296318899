var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "active-mask-block" },
    [
      _c("div", { staticClass: "active-block" }, [
        _c("div", { staticClass: "active-close-block" }, [
          _c("img", {
            attrs: {
              src: require("../../public/image/purse/close.png"),
              alt: "close",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.toClosePopup.apply(null, arguments)
              },
            },
          }),
        ]),
        _c("div", { staticClass: "outbox" }, [
          _vm._m(0),
          _c("div", { staticClass: "point" }, [
            _c(
              "div",
              { staticClass: "text game-btn", on: { click: _vm.readyGo } },
              [_vm._v(_vm._s(_vm.residueTimes))]
            ),
          ]),
        ]),
        _c("div", { staticClass: "active-text-block" }, [
          _c("div", { staticClass: "top" }, [
            _vm._v("Bonus: "),
            _c("span", { staticClass: "yellowText" }, [
              _vm._v(_vm._s(_vm.residueAmount)),
            ]),
          ]),
          _c("div", { staticClass: "secondTitle" }, [
            _vm._v("Como obter mais chances de ganhar?"),
          ]),
          _vm._m(1),
          _vm._m(2),
          _c("div", { staticClass: "btnsbox" }, [
            _c(
              "div",
              {
                staticClass: "btn_red",
                on: {
                  click: function ($event) {
                    _vm.tipWheel = true
                  },
                },
              },
              [_vm._v("Saque")]
            ),
            _c(
              "div",
              { staticClass: "btn_green", on: { click: _vm.toBonus } },
              [_vm._v("Convidar")]
            ),
          ]),
        ]),
      ]),
      _c("wheelPopup", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show1,
            expression: "show1",
          },
        ],
        attrs: { "close-icon": true },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "content" }, [
                  _vm._v("Você não possui tentativas de sorteio disponíveis"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "btns",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "btns", on: { click: _vm.closeByTimesOver } },
                  [_vm._v("Receber entradas para o sorteio ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("wheelPopup", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show_result,
            expression: "show_result",
          },
        ],
        attrs: { "close-icon": true },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "content" }, [
                  _vm._v("Acumule "),
                  _c("span", { staticStyle: { color: "#FFD800" } }, [
                    _vm._v("R$" + _vm._s(_vm.residueAmount)),
                  ]),
                  _vm._v(" em bônus para poder retirar "),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "btns",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "btns", on: { click: _vm.cashPrize } },
                  [_vm._v("Obter um bônus ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("wheelPopup", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tipWheel,
            expression: "tipWheel",
          },
        ],
        attrs: { "close-icon": false },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "content" }, [
                  _vm._v("Acumule "),
                  _c("span", { staticStyle: { color: "#FFD800" } }, [
                    _vm._v("R$" + _vm._s(_vm.info ? _vm.info.need_amount : "")),
                  ]),
                  _vm._v(" em bônus para poder retirar "),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "btns",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "btns",
                    on: {
                      click: function ($event) {
                        _vm.tipWheel = false
                      },
                    },
                  },
                  [_vm._v("Obter um bônus ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wheel", attrs: { id: "wheel" } }, [
      _c("img", {
        staticClass: "win",
        attrs: { src: require("../../public/image/purse/win.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "smallTitle" }, [_vm._v(" Método 1: ")]),
      _c("p", [
        _vm._v(
          " A cada convite bem-sucedido de um usuário para registro e recarga, você receberá uma chance extra para a loteria. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "smallTitle" }, [_vm._v(" Método 2: ")]),
      _c("p", [
        _vm._v(
          " A cada recarga de 100 reais, você ganhará uma chance para a loteria. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }