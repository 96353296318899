var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShow
    ? _c("div", { staticClass: "messages loadIn" }, [
        _c("div", { staticClass: "out_block" }, [
          _c("div", { staticClass: "titlebox" }, [
            _c("img", {
              attrs: {
                src: require("../../../public/image/message/close.png"),
                alt: "",
              },
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) return null
                  return _vm.toCloseAll.apply(null, arguments)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              " Abra o app com sua chave PIX cadastrada, escolha Pagar com Pix e escaneie o QR Codeou copie e cole o código. "
            ),
          ]),
          _c("div", { staticClass: "coins" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.coinType === 1,
                    expression: "coinType === 1",
                  },
                ],
              },
              [
                _c("img", {
                  staticClass: "preimg",
                  attrs: {
                    src: require("../../../public/image/recharge/paymentType.png"),
                    alt: "",
                  },
                }),
                _c("span", [_vm._v("pix")]),
              ]
            ),
            _c("img", {
              staticClass: "coin-type-icon",
              attrs: {
                src: require("../../../public/image/recharge/arrow.png"),
                alt: "",
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "qrcode" },
            [_c("vueQr", { attrs: { text: _vm.newArr.qrcode } })],
            1
          ),
          _c("div", { staticClass: "amount" }, [
            _vm._v(" Valor " + _vm._s(_vm.newArr.amount) + " "),
          ]),
          _c("div", { staticClass: "copyBtn", on: { click: _vm.toCopy } }, [
            _c("img", {
              attrs: {
                src: require("../../../public/image/recharge/copyIcon.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v("Copiar")]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }