var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "jopos" }, [
    _c("div", { staticClass: "card_top" }, [
      _c("h3", [_vm._v("Pragmatic Play")]),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            "margin-top": "20px",
            "background-color": "#11212D",
            padding: "20px 10px",
            "border-radius": "10px",
          },
        },
        [
          _c("GameSelector", {
            attrs: { id: "3", search: "true" },
            on: { searchEvent: _vm.searchGameHandler },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "contenArry", staticStyle: { "margin-top": "30px" } },
      [
        _c(
          "div",
          { staticClass: "content_list" },
          [
            _vm._l(_vm.gameList, function (item, index) {
              return _c("div", { staticClass: "listItem" }, [
                _c("img", {
                  class: _vm.playIndex === item.game_id ? "imageFilter" : "",
                  attrs: { src: _vm.SplitGamePosterUrl(item), alt: "" },
                  on: {
                    click: function ($event) {
                      return _vm.readyPlay(item.game_id)
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.active_status === 1,
                        expression: "item.active_status === 1",
                      },
                    ],
                    staticClass: "game-maintenance",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../../public/image/common/game-maintenance.png"),
                        alt: "",
                      },
                    }),
                    _c("span", [_vm._v("Em manutenção")]),
                  ]
                ),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.playIndex === item.game_id,
                      expression: "playIndex === item.game_id",
                    },
                  ],
                  staticClass: "play-btn",
                  attrs: {
                    src: require("../../../../public/image/common/playBtn.png"),
                    alt: "",
                  },
                  on: { click: _vm.doPlay },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.playIndex === item.game_id,
                        expression: "playIndex === item.game_id",
                      },
                    ],
                    staticClass: "top_name",
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.playIndex === item.game_id,
                        expression: "playIndex === item.game_id",
                      },
                    ],
                    staticClass: "bottom_types",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.channel === 1
                            ? "PragmaticPlay"
                            : item.channel === 2
                            ? "Pocket Games"
                            : ""
                        ) +
                        " "
                    ),
                  ]
                ),
              ])
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isSearch,
                    expression: "!isSearch",
                  },
                ],
                staticClass: "load-btn-block",
              },
              [
                _c("div", { staticClass: "search_progress" }, [
                  _c("div", { staticClass: "progress_bar_block" }, [
                    _c("div", { attrs: { id: "progress_bar" } }, [
                      _c("div", {
                        style: {
                          width: _vm.gameList
                            ? _vm.calcPercent(_vm.gameList.length, _vm.total) *
                                100 +
                              "%"
                            : 0,
                        },
                        attrs: { id: "progress_back" },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "words" }, [
                    _vm._v(
                      " Exposição " +
                        _vm._s(_vm.gameList.length) +
                        " /" +
                        _vm._s(_vm.total) +
                        " Registro "
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.haveLoadPage,
                        expression: "haveLoadPage",
                      },
                    ],
                    staticClass: "load-more",
                    class: _vm.isLoadingMore ? "loadBtnClass" : "",
                    on: { click: _vm.loadMorePgGames },
                  },
                  [
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLoadingMore,
                          expression: "isLoadingMore",
                        },
                      ],
                      staticClass: "bonus-loading",
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoadingMore,
                            expression: "!isLoadingMore",
                          },
                        ],
                      },
                      [_vm._v("Carregue mais")]
                    ),
                  ]
                ),
              ]
            ),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }