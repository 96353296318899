<template>
    <div>
        <div class="mask" v-if="redBagId !== 0">
            <div class="redback" v-show="isOldShow" :class="isOldShow?'':'hidebag'">
                <div class="text">
                    Parabéns!<br /> Você recebeu uma recompensa surpresa!
                </div>
                <div class="btnbox">
                    <div class="btns" @click="openBag">Receber</div>
                </div>
            </div>

            <div class="openRedBag1 showbag" v-show="OpenBag === 1">
                <div class="lightbox">
                    <img :src="IMAGE_URL + 'cashprize/redbag-title.png'" alt="" class="opentitle">
                </div>

                <div class="text">
                    Parabéns!<br />
                    Você ganhou um
                    bônus de <span>R${{amount}}</span>
                </div>

                <div class="btnbox">
                    <div class="btns" @click="close">Receber</div>
                </div>
            </div>

            <div class="openRedBag2" v-show="OpenBag === 2">
                <img :src="IMAGE_URL + 'cashprize/redbag-title.png'" alt="" class="opentitle2">
                <div class="text">
                    Parabéns pelo desconto na recarga!<br />
                    Recarregue <span>R${{needRechargeMoney}}</span><br />
                    e ganhe <span>R${{getExtraMoney}}</span> de
                    bônus
                    adicional.
                </div>

                <div class="btnbox">
                    <div class="btns" @click="recharge">Recarregar</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import { openRedBag, rechargeRedBag } from "@/api/api";
    export default {
        data() {
            return {
                isOldShow: true,
                OpenBag: 0,
                amount: 0,
                needRechargeMoney: 0,
                getExtraMoney: 0
            }
        },
        watch: {
            redBagId(val) {
                if (val) {
                    this.isOldShow = true;
                    // getUserRedBagInfo().then(res => {
                    //
                    // })
                }
            }
        },
        methods: {
            openBag() {
                openRedBag({
                    rid: this.redBagId
                }).then(res => {
                    this.isOldShow = false;
                    if (res.typ === 1) {
                        this.OpenBag = 1;
                        this.amount = res.amount;
                        this.$store.dispatch('UserInfo');
                    } else if (res.typ === 6) {
                        this.OpenBag = 2;
                        this.needRechargeMoney = res.recharge;
                        this.getExtraMoney = res.amount;
                    }
                })

            },
            close() {
                this.isOldShow = false
                this.OpenBag = 0
                this.$store.commit('setRedBagId', 0)
            },
            recharge() {
                // let a = null;
                // if (this.client.indexOf('ios') !== -1) {
                //     a = window.open();
                // }

                let params = {
                    rid: this.redBagId,
                    channel: 'cwt',
                }
                let buryingParams = localStorage.getItem('BuryingParams');
                if (buryingParams && buryingParams !== 'null') {
                    params.publish_channel = buryingParams.split('___')[0];
                    params.pid = buryingParams.split('___')[1]
                }
                if (params.publish_channel) {
                    params.click_id = localStorage.getItem('click_id')
                }
                rechargeRedBag(params).then(res => {
                    this.$store.commit('setPayforUrl', res)
                    // if (this.client.indexOf('ios') !== -1) {
                    //     a.location = res;
                    // } else {
                    //     window.open(res)
                    // }
                    this.close()
                }).catch(() => {
                    this.close()
                })
            }
        },
        computed: {
            ...mapState(['redBagId', 'client']),
        },
    }
</script>
<style lang="less">
    @import "@/assets/style.less";

    .redback {
        width: 577px;
        height: 758px;
        background: url('@{IMAGE_CDN}menu/redbag_bg.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
        display: flex;
        justify-content: center;

        .text {
            width: 90%;
            height: 140px;
            font-weight: bold;
            font-size: 30px;
            position: absolute;
            bottom: 200px;
            margin: 0 auto;
            text-align: center;
        }

        .btnbox {
            width: 100%;
            height: 115px;
            position: absolute;
            bottom: 39px;
        }

        .btns {
            width: 362px;
            height: 115px;
            background: url('@{IMAGE_CDN}menu/redbag_btn.png') no-repeat;
            background-size: 100% 100%;
            font-weight: 900;
            color: #FFFFFF;
            font-size: 40px;
            text-shadow: 0 0 5px #522500;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }
    }

    .openRedBag1 {
        width: 696px;
        height: 702px;
        background: url('@{IMAGE_CDN}menu/openbg.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
        display: flex;
        justify-content: center;

        .lightbox {
            width: 535px;
            height: 530px;
            background: url('@{IMAGE_CDN}menu/light.png') no-repeat;
            background-size: 100% 100%;
            padding-top: 56px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
        }

        .opentitle {
            width: 360px;
            text-align: center;
            position: absolute;
            top: 80px;
        }

        .text {
            width: 60%;
            height: 140px;
            position: absolute;
            top: 345px;
            margin: 0 auto;
            text-align: center;
            color: #383838;
            font-size: 36px;
            font-weight: bold;

            span {
                color: #4FCF23;
            }
        }

        .btnbox {
            width: 100%;
            height: 115px;
            position: absolute;
            bottom: 39px;
        }

        .btns {
            width: 362px;
            height: 115px;
            background: url('@{IMAGE_CDN}menu/redbag_btn.png') no-repeat;
            background-size: 100% 100%;
            font-weight: 900;
            color: #FFFFFF;
            font-size: 40px;
            text-shadow: 0 0 5px #522500;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }
    }

    .openRedBag2 {
        width: 696px;
        height: 702px;
        background: url('@{IMAGE_CDN}menu/openbg2.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
        display: flex;
        justify-content: center;

        .lightbox {
            width: 535px;
            height: 530px;
            background: url('@{IMAGE_CDN}menu/light.png') no-repeat;
            background-size: 100% 100%;
            padding-top: 56px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
        }

        .opentitle2 {
            width: 312px;
            text-align: center;
            position: absolute;
            top: 80px;
        }

        .text {
            width: 70%;
            height: 140px;
            font-weight: bold;
            position: absolute;
            top: 270px;
            margin: 0 auto;
            text-align: center;
            color: #383838;
            font-size: 36px;

            span {
                color: #4FCF23;
            }
        }

        .btnbox {
            width: 100%;
            height: 115px;
            position: absolute;
            bottom: 39px;
        }

        .btns {
            width: 362px;
            height: 115px;
            background: url('@{IMAGE_CDN}menu/redbag_btn.png') no-repeat;
            background-size: 100% 100%;
            font-weight: 900;
            color: #FFFFFF;
            font-size: 40px;
            text-shadow: 0 0 5px #522500;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }
    }

    .hidebag {
        animation: tohide 1s;
    }

    .showbag {
        animation: toOpen 1s;
    }

    @keyframes tohide {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes toOpen {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
</style>