var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          staticClass: "confirm-block",
          attrs: { closeable: !_vm.showDetail },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showDetail,
                  expression: "!showDetail",
                },
              ],
              staticClass: "game-recharge-block",
            },
            [
              _c("div", { staticClass: "game-recharge-back" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../public/image/active/game-recharge-back.png"),
                    alt: "",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "game-recharge-count-down" },
                  [
                    _vm._v(" Disponível em : "),
                    _c("van-count-down", {
                      attrs: {
                        time: _vm.packJson.expired_at - new Date().getTime(),
                        format: "HH:mm:ss",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "recharge-money-gear" }, [
                  _c("div", [
                    _vm._v(" +" + _vm._s(_vm.packJson.second) + "%"),
                    _c("br"),
                    _vm._v("BÔNUS"),
                  ]),
                  _c("div", [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.recharge(_vm.packJson.recharge_arr[1])
                          },
                        },
                      },
                      [
                        _vm._v(
                          "R$ " +
                            _vm._s(
                              _vm.packJson.recharge_arr &&
                                _vm.packJson.recharge_arr[1]
                            )
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.recharge(_vm.packJson.recharge_arr[2])
                          },
                        },
                      },
                      [
                        _vm._v(
                          "R$ " +
                            _vm._s(
                              _vm.packJson.recharge_arr &&
                                _vm.packJson.recharge_arr[2]
                            )
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.recharge(_vm.packJson.recharge_arr[3])
                          },
                        },
                      },
                      [
                        _vm._v(
                          "R$ " +
                            _vm._s(
                              _vm.packJson.recharge_arr &&
                                _vm.packJson.recharge_arr[3]
                            )
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "change-top recharge-money-gear" }, [
                  _c("div", [
                    _vm._v(" +R$" + _vm._s(_vm.packJson.first)),
                    _c("br"),
                    _vm._v("BÔNUS"),
                  ]),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          width: "180px",
                          "padding-right": "20px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.recharge(_vm.packJson.recharge_arr[0])
                          },
                        },
                      },
                      [
                        _vm._v(
                          "R$ " +
                            _vm._s(
                              _vm.packJson.recharge_arr &&
                                _vm.packJson.recharge_arr[0]
                            )
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "game-recharge-detail-btn",
                    on: {
                      click: function ($event) {
                        _vm.showDetail = true
                      },
                    },
                  },
                  [_vm._v("Detalhes >")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDetail,
                  expression: "showDetail",
                },
              ],
              staticClass: "game-recharge-detail",
            },
            [
              _c(
                "div",
                { staticClass: "detail-close-block" },
                [
                  _c("van-icon", {
                    attrs: { name: "cross", color: "#fff", size: "20" },
                    on: {
                      click: function ($event) {
                        _vm.showDetail = false
                      },
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "title" }, [_vm._v("Regras de Bônus")]),
              _c("p", [
                _vm._v(
                  " Oferecemos a você um bônus especial correspondente ao depósito de bônus. Aproveite esta grande oportunidade para aumentar o seu saldo com bônus adicionais, "
                ),
              ]),
              _c("div", [
                _c("div", { staticClass: "game-recharge-detail-context" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../public/image/active/game-recharge-detail-icon.png"),
                      alt: "",
                    },
                  }),
                  _c("span", [
                    _vm._v("+" + _vm._s(_vm.packJson.second) + "% BÔNUS"),
                  ]),
                ]),
                _c("div", { staticClass: "game-recharge-detail-context" }, [
                  _c("img", {
                    staticStyle: { width: "calc(26% - 6px)" },
                    attrs: {
                      src: require("../../../public/image/active/game-recharge-detail-icon1.png"),
                      alt: "",
                    },
                  }),
                  _c("span", [
                    _vm._v("+R$" + _vm._s(_vm.packJson.first) + " BÔNUS"),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }