var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login" }, [
    _c(
      "div",
      { staticClass: "login-title" },
      [
        _vm._l(_vm.btn, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              class: _vm.activeIndex === index ? "activeClass" : "defaultClass",
              on: {
                click: function ($event) {
                  return _vm.change_status(item, index)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.name) + " ")]
          )
        }),
        _c("van-icon", {
          attrs: { size: "30", name: "cross" },
          on: { click: _vm.closeMask },
        }),
      ],
      2
    ),
    _c("div", { staticClass: "login_box" }, [_c("CommonLogin")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }