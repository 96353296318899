var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.store.state.wheelShow
    ? _c("div", { staticClass: "wheel-mask" }, [
        _c("div", { staticClass: "wheel-block" }, [
          _c(
            "div",
            { staticClass: "wheel-close" },
            [
              _c("van-icon", {
                attrs: { name: "cross", color: "#93ACD3", size: "24" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _setup.closeSelf.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _setup.wheelLoading,
                  expression: "wheelLoading",
                },
              ],
              staticClass: "wheel-load",
            },
            [_c("van-loading")],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_setup.wheelLoading,
                  expression: "!wheelLoading",
                },
              ],
            },
            [
              _setup.backgroundStart
                ? _c(
                    "div",
                    {
                      staticClass: "wheel-background",
                      class: _setup.wheelStart ? "start-wheel-back" : "",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../public/image/active/wheel-decor.webp"),
                          alt: "background",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "wheel-self",
                  class: _setup.wheelStart ? "start-wheel" : "",
                },
                [
                  _c("img", {
                    attrs: {
                      id: "wheel",
                      src: require("../../public/image/active/wheel-context.png"),
                      alt: "background",
                    },
                    on: { load: _setup.imageLoaded },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../public/image/active/wheel_1.webp"),
                      alt: "1",
                    },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../public/image/active/wheel_2.webp"),
                      alt: "2",
                    },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../public/image/active/wheel_3.webp"),
                      alt: "3",
                    },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../public/image/active/wheel_4.webp"),
                      alt: "4",
                    },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../public/image/active/wheel_5.webp"),
                      alt: "5",
                    },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../public/image/active/wheel_6.webp"),
                      alt: "6",
                    },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../public/image/active/wheel_7.webp"),
                      alt: "7",
                    },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../public/image/active/wheel_8.webp"),
                      alt: "8",
                    },
                  }),
                  _c("img", {
                    staticClass: "wheel-arrow",
                    attrs: {
                      src: require("../../public/image/active/wheel_arrow.webp"),
                      alt: "arrow",
                    },
                  }),
                  _c("img", {
                    staticClass: "wheel-start",
                    attrs: {
                      src: require("../../public/image/active/wheel-start-btn.png"),
                      alt: "start",
                    },
                  }),
                  _c("span", { staticClass: "wheel-count" }, [
                    _vm._v(_vm._s(_setup.userWheelInfo.info.count)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _setup.blockShow,
                      expression: "blockShow",
                    },
                  ],
                  staticClass: "wheel-title",
                  class: _setup.wheelStart ? "disappear-down" : "",
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../public/image/active/wheel-title.png"),
                      alt: "title",
                    },
                    on: { load: _setup.imageLoaded },
                  }),
                  _setup.userWheelInfo.info.count > 0 ||
                  !_setup.store.state.token
                    ? _c(
                        "div",
                        {
                          staticClass: "wheel-btn",
                          on: { click: _setup.startWheel },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _setup.store.state.token
                                  ? "Spin and win"
                                  : "Faça login para girar"
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _setup.userWheelInfo.info.count === 0
                    ? _c("div", { staticClass: "wheel-count-down" }, [
                        _vm._m(0),
                        _c(
                          "div",
                          {
                            staticClass: "recharge-btn",
                            on: { click: _setup.recharge },
                          },
                          [_vm._v("Aposta")]
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "wheel-history-btn",
                      on: {
                        click: function ($event) {
                          _setup.isShowHistory = true
                          _setup.historyFirstRender = true
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v("Ganhos Recentes"),
                      ]),
                      _c("van-icon", {
                        attrs: { name: "arrow", color: "#1A71D9" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _setup.historyFirstRender
            ? _c(
                "div",
                {
                  staticClass: "wheel-history-block",
                  class: _setup.isShowHistory
                    ? "wheel-history-in"
                    : "wheel-history-out",
                },
                [
                  _c("div", { staticClass: "wheel-history-title" }, [
                    _c(
                      "div",
                      {
                        staticClass: "back-btn",
                        on: {
                          click: function ($event) {
                            _setup.isShowHistory = false
                            _setup.closeHistory()
                          },
                        },
                      },
                      [
                        _c("van-icon", {
                          attrs: {
                            name: "arrow-left",
                            size: "16",
                            color: "#93acd3",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v("Ganhos Recentes")]),
                  ]),
                  _c("div", { staticClass: "wheel-history-table" }, [
                    _c(
                      "table",
                      [
                        _vm._m(1),
                        _vm._l(_setup.wheelRank.rank, function (item) {
                          return _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  color: "#7A8FB1",
                                  "font-weight": "bold",
                                },
                              },
                              [_vm._v(_vm._s(item.spin_at))]
                            ),
                            _c("td", [
                              _c("div", { staticClass: "table-cell" }, [
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.IMAGE_URL +
                                      "header/" +
                                      item.avatar +
                                      ".jpg",
                                    alt: "avatar",
                                  },
                                }),
                                _vm._v(" " + _vm._s(item.name) + " "),
                              ]),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "table-cell" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../public/image/headerImg/R$_pics.png"),
                                    alt: "blr",
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_setup.AmountFixed(item.score)) +
                                    " "
                                ),
                              ]),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _setup.wheelWinReward,
                  expression: "wheelWinReward",
                },
              ],
              staticClass: "wheel-win",
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../public/image/active/wheel-win-background.png"),
                  alt: "win",
                },
              }),
              _c("div", { staticClass: "wheel-win-info" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _setup.wheelWinInfo.info.brl,
                        expression: "wheelWinInfo.info.brl",
                      },
                    ],
                    staticClass: "wheel-win-title",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../public/image/headerImg/R$_pics.png"),
                        alt: "blr",
                      },
                    }),
                    _c("div", [
                      _vm._v(" " + _vm._s(_setup.wheelWinInfo.info.brl) + " "),
                    ]),
                    _vm._v(" BRL "),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _setup.wheelWinInfo.info.free_coin,
                        expression: "wheelWinInfo.info.free_coin",
                      },
                    ],
                    staticClass: "wheel-win-title",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../public/image/headerImg/free-coin.png"),
                        alt: "free",
                      },
                    }),
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_setup.wheelWinInfo.info.free_coin) + " "
                      ),
                    ]),
                    _vm._v(" ReisCoin "),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "20px", "margin-top": "60px" },
                  },
                  [
                    _vm._v(" Você recebeu "),
                    _c("span", { staticStyle: { color: "#FFB636" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _setup.wheelWinInfo.info.brl
                              ? "R$" + _setup.wheelWinInfo.info.brl
                              : "F$" + _setup.wheelWinInfo.info.free_coin
                          ) +
                          " "
                      ),
                    ]),
                    _vm._v(" , "),
                    _c("br"),
                    _c("br"),
                    _vm._v("aproveite o jogo e boa sorte. "),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _setup.wheelWinInfo.info.count !== 0,
                      expression: "wheelWinInfo.info.count !== 0",
                    },
                  ],
                  staticClass: "wheel-win-btn",
                  on: { click: _setup.wheelReStart },
                },
                [_vm._v("continuar")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _setup.wheelWinInfo.info.count === 0,
                      expression: "wheelWinInfo.info.count === 0",
                    },
                  ],
                  staticClass: "wheel-win-btn",
                  on: { click: _setup.closeSelf },
                },
                [_vm._v("Fechar")]
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "wheel-next-text" }, [
      _vm._v(" A cada "),
      _c("span", { staticStyle: { color: "#2BB31B" } }, [_vm._v("R$200")]),
      _vm._v(" apostados, você pode girar a roda uma vez. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("th", [_vm._v("Tempo")]),
      _c("th", [_vm._v("User")]),
      _c("th", [_vm._v("Bónus")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }