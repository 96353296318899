var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      !_vm.token
        ? _c("div", { staticClass: "container-top" }, [
            _c(
              "div",
              { staticClass: "menu-icon", on: { click: _vm.openMenu } },
              [
                _c("van-icon", {
                  attrs: { name: "bars", size: "30", color: "#fff" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "logo", on: { click: _vm.toHome } }, [
              _c("img", {
                attrs: {
                  src: require("../../public/image/home/logo.png"),
                  alt: "",
                },
              }),
            ]),
            _c("div", { staticClass: "btn_box" }, [
              _c(
                "button",
                { staticClass: "singIn", on: { click: _vm.signIn } },
                [_vm._v("ENTRAR")]
              ),
              _c(
                "button",
                { staticClass: "singUp", on: { click: _vm.signUp } },
                [_vm._v("REGISTRAR")]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.token
        ? _c("div", { staticClass: "container_top_logined" }, [
            _c(
              "div",
              { staticClass: "menu-icon", on: { click: _vm.openMenu } },
              [
                _c("van-icon", {
                  attrs: { name: "bars", size: "30", color: "#fff" },
                }),
              ],
              1
            ),
            _c("img", {
              staticClass: "logo",
              attrs: {
                src: require("../../public/image/home/logo.png"),
                alt: "",
              },
              on: { click: _vm.toHome },
            }),
            _c("div", { staticClass: "balance" }, [
              _c(
                "div",
                { staticClass: "coins", on: { click: _vm.changeCoinType } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.coinType === 1,
                          expression: "coinType === 1",
                        },
                      ],
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#2bb31b",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v("BRL")]
                      ),
                      _c("span", [
                        _vm._v(_vm._s(_vm.AmountFixed(_vm.amountMoney))),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.coinType === 2,
                          expression: "coinType === 2",
                        },
                      ],
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#fff", "font-weight": "bold" },
                        },
                        [_vm._v("Rico Coin")]
                      ),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.AmountFixed(
                              _vm.userData ? _vm.userData.free_coin : ""
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c("van-icon", {
                    staticClass: "coin-type-icon",
                    attrs: {
                      name: _vm.ShowCoinTypeBlock ? "arrow-up" : "arrow-down",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.coinType === -1,
                          expression: "coinType === -1",
                        },
                      ],
                      staticClass: "coin-type-load",
                    },
                    [
                      _c("van-loading", {
                        attrs: {
                          type: "spinner",
                          size: "20",
                          color: "#1989fa",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.winMoney,
                      expression: "winMoney",
                    },
                  ],
                  staticClass: "win-money",
                },
                [_vm._v("+ " + _vm._s(_vm.winMoney))]
              ),
            ]),
            _c(
              "div",
              { staticClass: "rechargeBtn", on: { click: _vm.toRecharge } },
              [_vm._v("Depósito")]
            ),
          ])
        : _vm._e(),
      _vm.paySuccess
        ? _c("div", { staticClass: "pay-success-block" }, [
            _c("div", { staticClass: "pay-close-block" }, [
              _c("img", {
                attrs: {
                  src: require("../../public/image/common/close.png"),
                  alt: "close",
                },
                on: {
                  click: function ($event) {
                    _vm.paySuccess = false
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "pay-success" }, [
              _vm._v("Sucesso no processamento"),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.ShowCoinTypeBlock,
              expression: "ShowCoinTypeBlock",
            },
          ],
          staticClass: "coin-mask-block",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              _vm.ShowCoinTypeBlock = false
            },
          },
        },
        [
          _c("div", { staticClass: "coin-block" }, [
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.selectCoinType(1)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticStyle: { color: "#2bb31b", "font-weight": "bold" } },
                  [_vm._v("BRL")]
                ),
                _c("span", [_vm._v(_vm._s(_vm.AmountFixed(_vm.amountMoney)))]),
              ]
            ),
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.selectCoinType(2)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticStyle: { color: "#fff", "font-weight": "bold" } },
                  [_vm._v("Rico Coin")]
                ),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.AmountFixed(
                        _vm.userData ? _vm.userData.free_coin : ""
                      )
                    )
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.ShowFreeCoinIntroduce,
              expression: "ShowFreeCoinIntroduce",
            },
          ],
          staticClass: "pay-success-block",
        },
        [
          _c("div", { staticClass: "introduce-block" }, [
            _c("div", { staticClass: "introduce-block-title" }, [
              _vm._v("Sobre a Free Coin"),
            ]),
            _c("div", { staticClass: "introduce-block-context" }, [
              _c("div", { staticClass: "introduce-preface" }, [
                _vm._v(
                  " FreeCoin (F$) é a moeda exclusiva da Jogos, F$ pode ser obtido através de recargas e ganhos em jogos, e F$ pode ser convertido em BRL "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " 1.Após cada recarga bem-sucedida, você receberá uma quantidade de Free Coin equivalente ao valor que você recarregou."
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  " 2.Free Coin só pode ser usada em 'jogos internos' e em 'Design JILI'."
                ),
                _c("br"),
                _c("br"),
                _c(
                  "span",
                  { staticStyle: { "font-weight": "700", color: "#2bb31b" } },
                  [
                    _vm._v(
                      " 3.A cada " +
                        _vm._s(_vm.userData.FLR) +
                        " Free Coin, você pode trocar por 1 BRL. O valor total acumulado em BRL não pode exceder o valor total das suas recargas. "
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "introduce-check" },
                [
                  _c(
                    "van-checkbox",
                    {
                      attrs: { shape: "square" },
                      model: {
                        value: _vm.isReadFreeCoinIntroduce,
                        callback: function ($$v) {
                          _vm.isReadFreeCoinIntroduce = $$v
                        },
                        expression: "isReadFreeCoinIntroduce",
                      },
                    },
                    [_vm._v("Não me mostre hoje")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "introduce-btn",
                  on: { click: _vm.closeIntroduce },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../public/image/bonus/freeEndBtn.png"),
                      alt: "",
                    },
                  }),
                  _c("span", [_vm._v("Entendi")]),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c("SiteMessage", {
        attrs: { showState: _vm.isShowMessage },
        on: { closeAll: _vm.toCloseAll },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }